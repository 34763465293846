import React from "react";
import { Route, Routes } from "react-router-dom";
import { Spin } from "antd";

import {
  AboutUs,
  AddYourWorkspace,
  Workspaces,
  WorkSpace,
  VerifyEmail,
  ResetPassword,
  BookForTeams,
  PrivacyPolicy,
  TermsAndConditions,
  HostRegisterForm,
  HomePage,
} from "@/pages";
import HostForm from "../pages/Host/HostForm";
import {
  ABOUT_US,
  ADD_SPACE_DETAILS,
  ADD_SPACE,
  ADD_YOUR_SPACE,
  ANY_ROUTE,
  BOOK_FOR_TEAMS,
  FAQ,
  HOST_PROFILE,
  VERIFY_EMAIL,
  CUSTOMER_PROFILE,
  LOGIN,
  RESET_PASSWORD,
  PREVIEW_HOST_WORKSPACE,
  HOST_HOME,
  BOOKING_DETAILS,
  PRIVACY_POLICY,
  TERMS_AND_CONDITIONS,
  LISTING,
  SPACE_DESCRIPTION,
  PREVIEW_DETAILS,
  REGISTER,
  MY_LISTING,
  SINGLE_WORKSPACE,
  WORKSPACES,
  EARNINGS_PAYMENT,
  SINGLE_BOOKING,
  SUBSCRIPTIONS,
  SUBSCRIPTIONS_PLAN,
  ADMIN_LISTINGS,
  NEW_LISTING,
  MANAGE_SUBSCRIPTION,
  // SUBSCRIPTIONS,
} from "./routes";

import { ProtectedRoute } from "../utils/ProtectedRoutes";
import Login from "../components/a/Forms/LoginForm";
import PreviewWorkspace from "../pages/Host/Profile/components/PreviewWorkspace/previewWorkspace";
import Profile from "../pages/Dashboard/dashboard";
import PricingFAQ from "../pages/BookForTeams/pricingFAQ";
import HomeHost from "@/pages/Host/Profile/components/HomeHost";
import BookingDetails from "@/pages/Dashboard/Bookings/BookingDetails/BookingDetails";
import Bookings from "@/pages/Dashboard/Bookings";
import AccountSettings from "@/pages/Dashboard/Account";
import HostWorkSpaces from "../pages/Host/Profile/components/MyWorkspaces";
import VerifyHostWorkspace from "../pages/Host/Profile/components/VerifyWorkspace";
import TransactionsPayments from "../pages/Dashboard/Transactions";
import HomeCustomer from "@/pages/Dashboard/HomeCustomer/HomeCustomer";
import HostBookingDetails from "@/pages/Dashboard/Bookings/HostBookingDetails";
import ProfileSettings from "@/pages/Dashboard/Account/ProfileSettings";
import Verificaion from "@/pages/Dashboard/Account/Verification";
import Verified from "@/pages/Dashboard/Account/Verified";
import Listing from "@/pages/Listing";
import ViewSpaces from "@/pages/SpaceOwner/ViewSpaces";
import SpaceDescription from "@/pages/NewListingPage/SpaceDescription";
import SubscriptionsPage from "@/pages/SubscriptionsPage";
import AddWorkSpace from "@/pages/Host/AddWorkSpace";
import BookingPage from "@/pages/BookingPage";
import PreviewDetails from "@/pages/Host/PreviewDetails";
import RentersMessages from "@/pages/Messages/RentersMessages/Messages";
import HostMessages from "@/pages/Messages/HostMessages";
import Messages from "@/pages/Messages";
import WalletPage from "@/pages/SpaceOwner/WalletPage";
import Layout from "@/layout/layout";
import WorkspacePage from "@/pages/Workspace";
import RentersBookingHistory from "@/pages/RentersBookingHistory";
import { useAuth } from "@/components/auth/use-auth";
import SubscriptionDetails from "@/pages/SubscriptionDetails";
import { useMe } from "@/data/user";
import SuperAdmin from "@/pages/SuperAdmin";
import SpaceRules from "@/pages/SpaceRules/SpaceRules";
import RentalPSaceOwner from "@/pages/RentalSpaceOwner/RentalPSaceOwner";
import NewListingPage from "@/pages/NewListingPage";
import PreviewSpaceDetails from "@/pages/NewListingPage/PreviewDetailsPage";
import Amenities from "@/pages/SpaceRules/Amenities";
import UpdateListingPage from "@/pages/NewListingPage/UpdateListing";
import ManageSubscriptionPage from "@/pages/ManageSubscription";
import HostRoutes from "./space-owner.route";

export function OtherRoutes() {
  const { loading } = useAuth();
  const { me: user } = useMe();

  if (loading) {
    return (
      <div className="spinner">
        <Spin size="large" />
      </div>
    );
  }
  return (
    <Routes>
      <Route element={<Layout />}>
        {/* Super Admin Routes */}

        {user?.role_type === "SuperAdmin" && (
          <Route
            element={
              <ProtectedRoute
                allowedRoles={["SuperAdmin"]}
                fallback={<HomePage />}
              />
            }
          >
            <Route
              path={"/my-listing/preview/space/:id"}
              element={<WorkspacePage />}
            />
            <Route path={ADMIN_LISTINGS} element={<SuperAdmin />} />
          </Route>
        )}

        <Route
          element={
            <ProtectedRoute
              allowedRoles={["Host"]}
              fallback={<div>Not Authorized</div>}
            />
          }
        >
          {/* Add host path that requires login   */}
          <Route path="/*" element={<HostRoutes />} />
        </Route>

        <Route
          element={
            <ProtectedRoute
              allowedRoles={["Customer"]}
              fallback={<HomePage />}
            />
          }
        >
          {/* add customer path that requires login */}
          <Route path={`${CUSTOMER_PROFILE}/:tab`} element={<Profile />} />
          <Route path={CUSTOMER_PROFILE} element={<Profile />}>
            <Route
              path={`${CUSTOMER_PROFILE}/customer_dashboard`}
              element={<HomeCustomer />}
            />
            <Route
              path={`${CUSTOMER_PROFILE}/booking_history`}
              element={<RentersBookingHistory />}
            />
            <Route
              path={`${CUSTOMER_PROFILE}/booking_history/booking_details/:id`}
              element={<BookingDetails />}
            />
            <Route
              path={`${CUSTOMER_PROFILE}/account_settings`}
              element={<AccountSettings />}
            />
            <Route
              path={`${CUSTOMER_PROFILE}/account_settings/profile_settings`}
              element={<ProfileSettings />}
            />
            <Route
              path={`${CUSTOMER_PROFILE}/account_settings/verification`}
              element={<Verificaion />}
            />
            <Route
              path={`${CUSTOMER_PROFILE}/account_settings/verification/verified`}
              element={<Verified />}
            />
          </Route>
          <Route path="/become-space-owner" element={<RentalPSaceOwner />} />
          {/* <Route path={`/${CUSTOMER_PROFILE}/:tab`} element={<Profile />} /> */}
        </Route>

        {/*  Add paths that don't require login */}
        <Route path={"/my-listings/:id"} element={<UpdateListingPage />} />
        <Route
          path={"/my-listings/preview/:id"}
          element={<PreviewSpaceDetails />}
        />
        <Route path={LISTING} element={<Listing />} />
        <Route path={ABOUT_US} element={<AboutUs />} />
        <Route path={SINGLE_BOOKING} element={<BookingPage />} />
        <Route path={PRIVACY_POLICY} element={<PrivacyPolicy />} />
        <Route path={TERMS_AND_CONDITIONS} element={<TermsAndConditions />} />
        <Route path={WORKSPACES} element={<Workspaces />} />
        <Route path={SINGLE_WORKSPACE} element={<WorkspacePage />} />
        <Route path={`messages`} element={<Messages />} />
        <Route path={`messages/hostMessages/test`} element={<HostMessages />} />
        <Route path={`messages/messages/:id`} element={<RentersMessages />} />
        <Route path={MY_LISTING} element={<ViewSpaces />} />
        <Route path={ADD_YOUR_SPACE} element={<AddYourWorkspace />} />
        <Route path={REGISTER} element={<HostRegisterForm />} />
        <Route path={BOOK_FOR_TEAMS} element={<BookForTeams />} />
        <Route path={FAQ} element={<PricingFAQ />} />
        <Route path={VERIFY_EMAIL} element={<VerifyEmail />} />
        <Route path={RESET_PASSWORD} element={<ResetPassword />} />
        <Route path={LOGIN} element={<Login />} />
        <Route path={ANY_ROUTE} element={<div>NOT FOUND PAGE</div>} />
      </Route>
    </Routes>
  );
}
