export const CREATE_USER = "users/createUserAccount";
export const CREATE_WORKSPACE = "workspace/create_workspace";
export const LIST_WORKSPACE = "workspace?workspace_id=";
export const LIST_WORKSPACES = "workspace/listAllWorkspace";
export const UPDATE_WORKSPACE = "workspace/updateWorkspace?workspace_id=";
export const CANCELLATION_POLICY = "cancelpolicy?ccp_id=";
export const FORGOT_PASSWORD = "users/forgotPassword";
export const RESET_PASSWORD = "users/resetPassword";
export const GET_USER = "users/listUser";
export const UPDATE_USER = "users/updateUserAccount";
export const VERIFICATION_EMAIL = "users/verifyEmail";
export const CREATE_REVIEW = "reviews/createReview";
export const LIST_REVIEW = "reviews/listAllReviews?workspace_id=";
export const LIST_SPACE_REVIEWS = "reviews/";
export const CREATE_BOOKING = "booking/createBooking";
export const ONE_OFF_BOOKING = "booking/oneOffcreateBooking";
export const CANCEL_BOOKING = "booking/cancelBooking";
export const LIST_BOOKING = "booking/listBooking";
export const GET_ALL_BOOKING = "booking/listAllBooking/";
export const GET_SINGLE_BOOKING = "booking/listBooking";
export const ADD_DATE_TO_BOOKING = "booking/confirmBooking";
export const CONFIRM_BOOKING_AVAILABILITY =
  "booking/confirmBookingAvailability";
export const GET_LIST_OF_BANKS = "paystack/getBanks";
export const CONFIRM_BANK_ACCOUNT = "paystack/confirmBankAccount";
export const WITHDRAW_FUNDS = "wallet/withdraw-funds";
export const CREATE_TRANSACTION = "booking/pay";
export const KYC_VERIFICATION = "users/kycverification";
export const BOOKING_PAYMENT = "paystack/intiatePayment";
