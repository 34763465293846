import { NavIcon } from "@/components/SVGs";
import Payment from "@/components/subscriptions/Payment";
import { useLocation, useNavigate, useParams } from "react-router-dom";

export default function SubscriptionDetails() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const params = useParams<{ id: "starter" | "basic" | "premium" }>();
  return (
    <div>
      <div className="grid gap-8 m-8">
        <div className="flex flex-start gap-2 text-sm items-center">
          <button className="text-textGrey" onClick={() => navigate(-2)}>
            Bookings
          </button>
          <NavIcon />
          <button className="text-textGrey" onClick={() => navigate(-1)}>
            Subscriptions
          </button>
          <NavIcon />
          <span className="capitalize">{params.id}</span>
        </div>

        <Payment planId={params.id!} frequency={"monthly"} />
      </div>
    </div>
  );
}
