import React from "react";

export const BadgeIcon: React.FC<React.SVGAttributes<{}>> = (props) => {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M20.0076 9.4925L18.6476 7.9125C18.3876 7.6125 18.1776 7.0525 18.1776 6.6525V4.9525C18.1776 3.8925 17.3076 3.0225 16.2476 3.0225H14.5476C14.1576 3.0225 13.5876 2.8125 13.2876 2.5525L11.7076 1.1925C11.0176 0.6025 9.88764 0.6025 9.18764 1.1925L7.61764 2.5625C7.31764 2.8125 6.74764 3.0225 6.35764 3.0225H4.62764C3.56764 3.0225 2.69764 3.8925 2.69764 4.9525V6.6625C2.69764 7.0525 2.48764 7.6125 2.23764 7.9125L0.887637 9.5025C0.307637 10.1925 0.307637 11.3125 0.887637 12.0025L2.23764 13.5925C2.48764 13.8925 2.69764 14.4525 2.69764 14.8425V16.5525C2.69764 17.6125 3.56764 18.4825 4.62764 18.4825H6.35764C6.74764 18.4825 7.31764 18.6925 7.61764 18.9525L9.19764 20.3125C9.88764 20.9025 11.0176 20.9025 11.7176 20.3125L13.2976 18.9525C13.5976 18.6925 14.1576 18.4825 14.5576 18.4825H16.2576C17.3176 18.4825 18.1876 17.6125 18.1876 16.5525V14.8525C18.1876 14.4625 18.3976 13.8925 18.6576 13.5925L20.0176 12.0125C20.5976 11.3225 20.5976 10.1825 20.0076 9.4925ZM14.6076 8.8625L9.77764 13.6925C9.63764 13.8325 9.44764 13.9125 9.24764 13.9125C9.04764 13.9125 8.85764 13.8325 8.71764 13.6925L6.29764 11.2725C6.00764 10.9825 6.00764 10.5025 6.29764 10.2125C6.58764 9.9225 7.06764 9.9225 7.35764 10.2125L9.24764 12.1025L13.5476 7.8025C13.8376 7.5125 14.3176 7.5125 14.6076 7.8025C14.8976 8.0925 14.8976 8.5725 14.6076 8.8625Z"
        fill="currentColor"
      />
    </svg>
  );
};
