// export const HOME_URL = process.env.NODE_ENV === 'development' ? 'http://localhost:3000' : 'https://cospiredev.web.app';
export const HOME_URL = window.location.hostname.includes("localhost")
  ? "http://localhost:3000"
  : (import.meta.env.VITE_URL as string);
export const SPACE_DESCRIPTION = "/space-description";
export const PREVIEW_DETAILS = "/preview-details";
export const ADD_YOUR_SPACE = "/add-your-workspace";
export const ADD_SPACE = "/add-space";
export const BOOK_FOR_TEAMS = "/book-for-teams";
export const FAQ = "/faq";
export const HOST_DASHBOARD = "/host-dashboard";
export const ADD_SPACE_DETAILS = "/host-add-details";
export const HOST_PROFILE = "/host-profile";
export const PREVIEW_HOST_WORKSPACE = "/host-preview-workspace";
export const VERIFY_EMAIL = "/verifyemail";
export const HOST_HOME = "host/*";
export const HOST_BOOKING = "/host-profile/bookings";
export const LOGIN = "/login";
export const HOME = "/";
export const REGISTER = "/register";
export const CUSTOMER_PROFILE = "/customer-profile";
export const FORGOT_PASSWORD = "/forgot-password";
export const RESET_PASSWORD = "/reset-password";
export const ABOUT_US = "/about-us";
export const PRIVACY_POLICY = "/privacy-policy";
export const TERMS_AND_CONDITIONS = "/terms-and-conditions";
export const BOOKING_DETAILS = "/booking-details";
export const ANY_ROUTE = "*";
export const LISTING = "/listing";
export const MY_LISTING = "/my-listing";

//***WORKSPACE ROUTES***
export const WORKSPACES = "/workspaces";
export const SINGLE_WORKSPACE = "/workspace/:id";
export const WORKSPACE_URL = (id: string) => `/workspace/${id}`;
export const NEW_LISTING = "/add-new-space";

//***WALLET***
export const EARNINGS_PAYMENT = "/earnings-payments";

//***BOOKING***
export const SINGLE_BOOKING = "/book_now/:id";
export const BOOKING_URL = (id: string) => `/book_now/${id}`;

// #############################SPACE OWNERS######################################
// ***ACCOUNT SETTINGS***
export const ACCOUNT_SETTINGS = "/account-settings";

// ***SUBSCRIPTIONS***
export const SUBSCRIPTIONS = "/subscriptions";
export const SUBSCRIPTIONS_PLAN = "/subscriptions/:id";
export const SUBSCRIPTIONS_PLAN_URL = (id: string) => `/subscriptions/${id}`;
export const MANAGE_SUBSCRIPTION = "/manage-subscription";

// ***SUPER_ADMIN***
export const ADMIN_LISTINGS = "/admin/listings";
