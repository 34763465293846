import React, { useEffect } from "react";
import { StepperComponent } from "../stepper/_StepperComponent";
import { SpaceValues, formSchema } from "@/components/space-listing/schema";
import "./update-space-form.scss";
import { cn } from "@/lib/utils";
import { Form, Formik, FormikValues } from "formik";
import { AddressForm } from "./steps/address-form";
import { BasicDetailsForm } from "./steps/basic-details-form";
import { OthersForm } from "./steps/others-form";
import { StepFourForm } from "./steps/step-four";
import { UpdateSpacePayload, Workspace } from "@/types/workspace";
import { formatAddress } from "@/lib/format-address";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import {
  reverseTransformSchedule,
  transformScheduleArray,
} from "./steps/helper";
import { useMe } from "@/data/user";
import { useUpdateWorkspaceMutation } from "@/data/workspace";
import { toast } from "react-toastify";
import { handleError } from "@/services/networkRequests";

function loadOtherPolicies(workspace?: Workspace) {
  const policies = [];
  if (!workspace) return [];
  if (workspace.other_policy.is_adult_filming_allowed === false) {
    policies.push("ADULT_FILMING_NOT_ALLOWED");
  }
  if (workspace.other_policy.is_alcohol_allowed === false) {
    policies.push("NO_ALCOHOL_ALLOWED");
  }
  if (workspace.other_policy.is_music_allowed === true) {
    policies.push("MUSIC_ALLOWED");
  }
  if (workspace.other_policy.is_pet_allowed === false) {
    policies.push("NO_PETS_ALLOWED");
  }
  if (workspace.other_policy.is_smoking_allowed === false) {
    policies.push("SMOKING_NOT_ALLOWED");
  }
  return policies;
}

export function UpdateSpaceForm({ workspace }: { workspace: Workspace }) {
  const { me: user } = useMe();

  const [otherPolicies, setOtherPolicies] = React.useState<string[]>([]);

  const params = useParams<{ id: string }>();

  const [searchParams] = useSearchParams();

  const step = searchParams.get("step") ?? 1;

  useEffect(() => {
    const result = loadOtherPolicies(workspace);
    console.log({ result });
    setOtherPolicies(result);
  }, []);

  const stepperRef = React.useRef<HTMLDivElement | null>(null);
  const stepper = React.useRef<StepperComponent | null>(null);
  const [currentSchema, setCurrentSchema] = React.useState(formSchema[0]);
  const [initValues] = React.useState<SpaceValues>({
    //First step
    location: "",
    countryRegion: workspace?.address?.country ?? "",
    state: workspace?.address?.state ?? "",
    city: workspace?.address?.city ?? "",
    streetAddress: workspace?.address?.street_address ?? "",
    zipCode: workspace?.address?.zip_code ?? "",
    map: {
      lat: 0,
      lng: 0,
    },

    //Second step
    spaceTitle: workspace?.title ?? "",
    about: workspace?.about ?? "",
    numberOfPeoples: workspace?.space_size ?? 0,
    facilities: workspace?.service_amenities.map(({ name }) => name) ?? [],
    instantBooking: workspace?.instant_booking ?? false,

    //Third step
    currency: workspace?.currency ?? "NGN",
    minHoursOption: String(workspace?.minimum_hours) ?? 0,
    securityDepositPercentage: workspace?.percentage_of_security_deposit ?? 0,
    pricing: workspace?.pricing.map((item) => {
      return {
        ...item,
        hourly: item.hourly ? Number(item?.hourly) : null,
        daily: item.daily ? Number(item?.daily) : null,
        weekly: item.weekly ? Number(item?.weekly) : null,
        monthly: item.monthly ? Number(item?.monthly) : null,
      };
    }) ?? [
      {
        daily: null,
        hourly: null,
        weekly: null,
        monthly: null,
        days: [],
      },
    ],
    operationalHours: workspace
      ? reverseTransformSchedule(workspace?.working_hours)
      : [{ open: "00:00", close: "00:00", days: [] }],
    otherPolicies: otherPolicies,

    //Fourth step
    spacePictures: workspace?.photos?.map((photo) => ({
      src: photo,
      title: "",
      size: "",
    })),
    contactImage: null,
    displayName: user?.displayName ?? "",
    emailAddress: user?.email ?? "",
  });

  const { mutateAsync: updateSpace, isLoading } = useUpdateWorkspaceMutation();

  const navigate = useNavigate();

  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(
      stepperRef.current as HTMLDivElement
    );
  };

  const prevStep = () => {
    if (!stepper.current) {
      return;
    }

    stepper.current.goPrev();

    setCurrentSchema(formSchema[stepper.current.currentStepIndex - 1]);
    window.scrollTo(0, 0);
  };

  const submitStep = async (values: SpaceValues, actions: FormikValues) => {
    if (!stepper.current) {
      return;
    }

    setCurrentSchema(formSchema[stepper.current.currentStepIndex]);

    const payload: Partial<UpdateSpacePayload> = {
      is_external_food_allowed: !values.facilities.includes(
        "EXTERNAL_FOOD_NOT_ALLOWED"
      ),
      title: values.spaceTitle,
      about: values.about,
      is_alcohol_allowed: !values.facilities.includes("NO_ALCOHOL_ALLOWED"),
      display_name: values.displayName,
      space_email: values.emailAddress,
      space_phone_number: "",
      whatsapp_phone_number: "",
      website_url: "",
      facebook: "",
      twitter: "",
      instagram: "",
      linkedin: "",
      space_amenity_obj: values.facilities.length
        ? (values.facilities as string[])
        : [],
      pricing: values.pricing,
      holidays: [],
      cancellation_policy_ids: ["qIXXAAtNPEvDoZAdGliV"],
      food_policy_description: "",
      percentage_of_security_deposit: 0,
      electricity_usage: "use-electricity",
      images: values.spacePictures?.map((img: any) => img.src)!,
      formatted_address:
        formatAddress({
          country: values.countryRegion,
          state: values.state,
          city: values.city,
          street_address: "",
          zip: values.zipCode,
        }) ?? "",
      address: {
        country: values.countryRegion,
        state: values.state,
        city: values.city,
        street_address: values.streetAddress,
        zip_code: values.zipCode,
      },
      operation_hours: transformScheduleArray(values.operationalHours),
      space_size: values.numberOfPeoples,
      capacity: values.numberOfPeoples,
      minimum_hours: Number(values.minHoursOption),
      map: {
        latitude: 0,
        longitude: 0,
      },
      age_restriction: "nil",
      is_adult_filming_allowed: !values.facilities.includes(
        "ADULT_FILMING_NOT_ALLOWED"
      ),
      is_music_allowed: values.facilities.includes("MUSIC_ALLOWED"),
      is_pet_allowed: !values.facilities.includes("NO_PETS_ALLOWED"),
      is_smoking_allowed: !values.facilities.includes("SMOKING_NOT_ALLOWED"),
      currency: values.currency,
      instant_booking: values.instantBooking,
      is_park_space_available: false,
    };

    if (stepper.current.currentStepIndex !== stepper.current.totatStepsNumber) {
      toast.promise(
        updateSpace(
          { ...payload, id: params.id! },
          {
            onSuccess: () => {
              toast.success("Space updated successfully");
              stepper?.current?.goNext();
              window.scrollTo(0, 0);
            },
            onError: (error) => handleError(error),
          }
        ),
        {
          pending: "Updating space...",
        }
      );
    } else {
      toast.promise(
        updateSpace(
          { ...payload, id: params.id! },
          {
            onSuccess: () => {
              toast.success("Space updated successfully");
              navigate("/my-listings/preview/" + workspace?.workspace_id);
            },
            onError: (error) => handleError(error),
          }
        ),
        {
          pending: "Updating space...",
        }
      );
    }
  };

  React.useEffect(() => {
    if (!stepperRef.current) return;
    loadStepper();
  }, [stepperRef]);

  useEffect(() => {
    if (!stepper.current) return;
    stepper.current?.goto(Number(step));
  }, [stepper, step]);

  const progress = ((stepper.current?.currentStepIndex ?? 0) / 5) * 100;
  return (
    <div ref={stepperRef} className="stepper" id="kt_add_space_stepper">
      <div className="w-full bg-[#f0f0f0]">
        <div style={{ width: `${progress}%` }} className="h-2.5 bg-[#007bff]" />
      </div>
      <div className="numbered-progress space-x-4">
        {Array.from({ length: formSchema.length }).map((_, index) => {
          return (
            <div
              data-kt-stepper-element="nav"
              className={cn(
                "flex items-center gap-x-3",
                index === 0 ? "current" : ""
              )}
            >
              <p className={cn("progress-indication-number")}>{index + 1}</p>
              <div className="progress-indication-bar !w-10">
                <div className="blue-bar"></div>
              </div>
            </div>
          );
        })}
      </div>
      <Formik
        validationSchema={currentSchema}
        initialValues={initValues}
        onSubmit={submitStep}
        validateOnChange={false}
        validateOnBlur={false}
        enableReinitialize
      >
        {({ errors, values, ...rest }) => {
          console.log({ errors, values, rest });

          return (
            <Form id="kt_add_space_form">
              <div className="workspace-setup-page-content">
                <div className="pages-parent">
                  <div className="current" data-kt-stepper-element="content">
                    <AddressForm values={values} />
                  </div>
                  <div data-kt-stepper-element="content">
                    <BasicDetailsForm values={values} />
                  </div>
                  <div data-kt-stepper-element="content">
                    <OthersForm values={values} onChange={rest.handleChange} />
                  </div>
                  <div data-kt-stepper-element="content">
                    <StepFourForm
                      values={values}
                      setValue={rest.setFieldValue}
                    />
                  </div>
                </div>

                <p
                  className={cn(
                    "error-in-listing",
                    "text-red-600 px-4 py-6",
                    Object.keys(errors).length ? "visible" : "hidden"
                  )}
                >
                  Please complete all the required fields
                </p>

                <div className="add-workspace-button-wrapper">
                  <button
                    onClick={prevStep}
                    type="button"
                    data-kt-stepper-action="previous"
                    className="btn button-outline"
                  >
                    Back
                  </button>

                  <button disabled={isLoading} type="submit" className="btn">
                    {!isLoading ? <>Continue</> : "Please wait..."}
                  </button>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}
