import React from "react";

export const BackIcon: React.FC<React.SVGAttributes<{}>> = (props) => {
  return (
    <svg
      width="18"
      height="16"
      viewBox="0 0 18 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.25 8C17.25 8.41421 16.9142 8.75 16.5 8.75L3.31066 8.75L8.78033 14.2197C9.07322 14.5126 9.07322 14.9874 8.78033 15.2803C8.48744 15.5732 8.01256 15.5732 7.71967 15.2803L0.96967 8.53033C0.676777 8.23744 0.676777 7.76256 0.96967 7.46967L7.71967 0.71967C8.01256 0.426777 8.48744 0.426777 8.78033 0.71967C9.07322 1.01256 9.07322 1.48744 8.78033 1.78033L3.31066 7.25L16.5 7.25C16.9142 7.25 17.25 7.58579 17.25 8Z"
        fill="#131416"
      />
    </svg>
  );
};
