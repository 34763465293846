import styles from "./index.module.scss";
import profileIcon from "@/assets/svgs/profile-circle.svg";
import verifyIcon from "@/assets/svgs/verify.svg";
import bankIcon from "@/assets/svgs/bank-cards-setting-icon.svg";
import companyIcon from "@/assets/svgs/company-settings-icon.svg";
import { useNavigate } from "react-router-dom";
import { useMe } from "@/data/user";

const AccountSettings = () => {
  const navigate = useNavigate();
  return (
    <div className={styles.page}>
      <h2 className={styles.title}>Account settings</h2>
      <div className={styles.options}>
        <div className={styles.CardSettings}>
          <div
            className={styles.rectangle}
            onClick={() => navigate("profile_settings")}
          >
            <div className={styles.settingsTextBox}>
              <h2>Profile settings</h2>
              <p>Change password, Profile image, Email</p>
            </div>
            <div className={styles.icon}>
              <img src={profileIcon} alt="profile-icon" />
            </div>
          </div>
          <div
            className={styles.rectangle}
            onClick={() => navigate("verification")}
          >
            <div className={styles.settingsTextBox}>
              <h2>Verification</h2>
              <p>Verify BVN or NIN</p>
            </div>
            <div className={styles.icon}>
              <img src={verifyIcon} alt="profile-icon" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountSettings;
