import { useState } from "react";
import styles from "./LoggedInHeader.module.scss";
import useCheckIsMobileScreen from "@/hooks/useCheckIsMobileScreen";
import smallLogo from "@/assets/svgs/logo-cospire-svg-mobile.svg";
import Logo from "@/assets/svgs/logo-cospire-svg.svg";
import LoggedInMenu from "../../../c/LoggedInMenu";
import { DownOutlined } from "@ant-design/icons";
import MenuDropDown from "../../../c/MenuDropDown";
import { Alert, Drawer, Space, Button as AntButton } from "antd";
import { Spacer } from "@/components/spacer";
import {
  ADMIN_LISTINGS,
  HOME_URL,
  LOGIN,
  MY_LISTING,
  VERIFY_EMAIL,
  WORKSPACES,
} from "@/routes/routes";
import { sendUserVerifyEmail } from "@/services/networkRequests/userService";
import { useMe } from "@/data/user";
import { useAuth } from "@/components/auth/use-auth";
import { cn } from "@/lib/utils";
import { Link, useNavigate } from "react-router-dom";

const LoggedInHeader = () => {
  const navigate = useNavigate();
  const { me: loggedInUser, isLoading: loading } = useMe();
  const { currentUser: user } = useAuth();
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const isMobile = useCheckIsMobileScreen();

  console.log({ loggedInUser, user });

  const showLoading = () => setShowMenu(true);

  const sendEmail = async (email: string) => {
    const payload = { userEmail: email, callbackUrl: HOME_URL + LOGIN };
    await sendUserVerifyEmail(payload);
    navigate(VERIFY_EMAIL, { replace: true });
  };

  return (
    <>
      <div className={styles.navbar}>
        <div className={styles.container}>
          {isMobile ? (
            <>
              <Link to={"/"} className={cn("flex items-center gap-2")}>
                <img
                  src={smallLogo}
                  alt="logo"
                  className="w-[176px] h-[44px]"
                />
              </Link>
              <LoggedInMenu
                name={loggedInUser?.displayName}
                menuIcon={<DownOutlined className={styles.icon} />}
                isMobile={isMobile}
                toggleMenu={showLoading}
              />
            </>
          ) : (
            <>
              <Link to={"/"} className={cn("flex items-center gap-2")}>
                <img src={Logo} alt="logo" className="w-[176px] h-[44px]" />
              </Link>
              <div className={styles.rightDiv}>
                <>
                  {loggedInUser?.role_type === "SuperAdmin" ? (
                    <>
                      <p
                        className="font-medium text-lg cursor-pointer"
                        onClick={() => {
                          navigate(WORKSPACES);
                        }}
                      >
                        Explore Spaces
                      </p>

                      <p
                        className="font-medium text-lg cursor-pointer"
                        onClick={() => {
                          navigate(ADMIN_LISTINGS);
                        }}
                      >
                        Listing
                      </p>
                      <p
                        className="font-medium text-lg cursor-pointer"
                        onClick={() => {
                          navigate("#");
                        }}
                      >
                        Booking
                      </p>
                      {/* <p
                        className="font-medium text-lg cursor-pointer"
                        onClick={() => {
                          navigate(ADMIN_LISTINGS);
                        }}
                      >
                        Calendar
                      </p>
                      <p
                        className="font-medium text-lg cursor-pointer"
                        onClick={() => {
                          navigate("/messages");
                        }}
                      >
                        Messages
                      </p>
                      <p
                        className="font-medium text-lg cursor-pointer"
                        onClick={() => {
                          navigate("#");
                        }}
                      >
                        Add-Ons
                      </p> */}
                      <p className="font-medium text-lg">
                        {loggedInUser?.displayName}
                      </p>
                    </>
                  ) : (
                    <>
                      {loggedInUser?.role_type === "Host" ? (
                        <>
                          <p
                            onClick={() => navigate(WORKSPACES)}
                            className={styles.text}
                          >
                            Explore Spaces
                          </p>
                          <p
                            onClick={() => navigate(MY_LISTING)}
                            className={styles.text}
                          >
                            My Listing
                          </p>
                          <p
                            className={styles.text}
                            onClick={() => {
                              loggedInUser?.role_type === "Host"
                                ? navigate("/host-profile/bookings")
                                : navigate("/customer-profile/booking_history");
                            }}
                          >
                            Bookings
                          </p>
                          {/* <p className={styles.text}>Calendar</p>
                          <p
                            className={styles.text}
                            onClick={() => {
                              navigate("/messages");
                            }}
                          >
                            Messages
                          </p>
                          <p className={styles.text}>Add-Ons</p> */}
                        </>
                      ) : (
                        <>
                          <p
                            onClick={() => navigate(WORKSPACES)}
                            className={styles.text}
                          >
                            Explore Spaces
                          </p>

                          <p
                            className={styles.text}
                            onClick={() => {
                              loggedInUser?.role_type === "Host"
                                ? navigate("/host-profile/bookings")
                                : navigate("/customer-profile/booking_history");
                            }}
                          >
                            Bookings
                          </p>
                          <p
                            className={styles.text}
                            onClick={() => {
                              navigate("/messages");
                            }}
                          >
                            Messages
                          </p>
                        </>
                      )}
                    </>
                  )}
                </>

                <LoggedInMenu
                  name={loggedInUser?.displayName}
                  menuIcon={<DownOutlined className={styles.icon} />}
                  toggleMenu={showLoading}
                />
              </div>
            </>
          )}
        </div>

        {!loading && (
          <Drawer
            closable
            destroyOnClose
            placement="right"
            open={showMenu}
            onClose={() => setShowMenu(false)}
            width={300}
          >
            <div>
              <MenuDropDown
                role={loggedInUser!.role_type}
                showMenu={setShowMenu}
              />
            </div>
          </Drawer>
        )}
      </div>
      {user?.emailVerified === false && (
        <>
          <div className="fixed top-[86px] inset-x-0 px-4 z-50">
            <Alert
              message="Please verify your email address to complete your registration"
              type="warning"
              action={
                <Space>
                  <AntButton
                    onClick={() => sendEmail(user.email!)}
                    type="text"
                    size="small"
                    ghost
                  >
                    Resend
                  </AntButton>
                </Space>
              }
              closable
            />
          </div>
          <Spacer height={40} />
        </>
      )}
    </>
  );
};

export default LoggedInHeader;
