import { CreditCardIcon } from "@/components/icons/credit-card";
import { BasicPlanCard } from "@/components/subscriptions/plans-cards/basic-plan";
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { CheckIcon, LockKeyholeIcon } from "lucide-react";
import {
  Breadcrumb,
  BreadcrumbEllipsis,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "@/components/ui/breadcrumb";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";

export default function ManageSubscriptionPage() {
  return (
    <div className="container mx-auto p-4">
      <div className="mb-6">
        <Breadcrumb>
          <BreadcrumbList>
            <BreadcrumbItem>
              <BreadcrumbLink className="text-[#C7C7C7]" href="/">
                Home
              </BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbSeparator className="text-[#C7C7C7]" />
            <BreadcrumbItem>
              <BreadcrumbLink
                className="text-[#C7C7C7]"
                href="/account-settings"
              >
                Account Settings
              </BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbSeparator className="text-[#C7C7C7]" />
            <BreadcrumbItem>
              <BreadcrumbPage className="text-primaryText">
                Subscription
              </BreadcrumbPage>
            </BreadcrumbItem>
          </BreadcrumbList>
        </Breadcrumb>
      </div>

      <div className="border border-[#C7C7C7] rounded-3xl md:py-24 py-16 px-2 md:px-0">
        <div className="max-w-6xl w-full mx-auto ">
          <h1 className="text-3xl font-bold mb-6">Subscription</h1>

          <div className="grid grid-cols-1 md:grid-cols-7 gap-6">
            <div className="md:col-span-5 md:pr-10">
              <BasicPlanCard />

              <div className="bg-[#E5E7E8] h-px my-6"></div>

              <div className="">
                <div className="text-base font-medium text-coSpireBlack mb-4">
                  Subscription details
                </div>
                <ul className="space-y-2">
                  <li>
                    <p className="text-sm text-[#626C70]">
                      <span className="font-medium mr-2">Status</span>
                      Basic plan active
                    </p>
                  </li>
                  <li>
                    <p className="text-sm text-[#626C70]">
                      <span className="font-medium mr-2">
                        Registration date
                      </span>
                      12 September 2024
                    </p>
                  </li>
                  <li>
                    <p className="text-sm text-[#626C70]">
                      <span className="font-medium mr-2">Billing cycle</span>
                      4weeks
                    </p>
                  </li>
                  <li>
                    <p className="text-sm text-[#626C70]">
                      <span className="font-medium mr-2">
                        Next billing date
                      </span>
                      11 October 2024
                    </p>
                  </li>
                </ul>
              </div>

              <div className="bg-[#E5E7E8] h-px my-6"></div>

              <div className="">
                <div className="text-base font-medium text-coSpireBlack mb-4">
                  Billing details
                </div>
                <ul className="space-y-2">
                  <li>
                    <p className="text-sm text-[#626C70]">
                      <span className="font-medium mr-2">Card type</span>
                      Debit card
                    </p>
                  </li>
                  <li>
                    <p className="text-sm text-[#626C70]">
                      <span className="font-medium mr-2">Last 4-digits</span>
                      4567
                    </p>
                  </li>
                  <li>
                    <p className="text-sm text-[#626C70]">
                      <span className="font-medium mr-2">Expiration</span>
                      Feb 2029
                    </p>
                  </li>
                </ul>
                <div className="mt-2 mb-4">
                  <button className="flex items-center space-x-2 py-2.5">
                    <CreditCardIcon className="text-primary" />
                    <span className="text-sm text-[#005CE8] font-semibold">
                      Edit Card Detail
                    </span>
                  </button>
                </div>
                <Button variant="secondary" className="rounded-full">
                  Cancel Subscription
                </Button>
              </div>
            </div>
            <Card className="bg-yellow-50 md:col-span-2">
              <CardHeader>
                <CardTitle className="text-amber font-medium text-2xl">
                  In Your Plan
                </CardTitle>
              </CardHeader>
              <CardContent>
                <ul className="space-y-2">
                  {[
                    "Booking management",
                    "List up to 30 spaces",
                    "Inbound marketing",
                    "Sponsored listing-2 times a week",
                    "Basic Insights and Analytics",
                    "Access to renter's KYC",
                    "Dedicated account manger",
                    "Customer testimonials",
                    "Enhanced profile customization",
                    "Loyalty Program and Rewards",
                  ].map((feature, index) => (
                    <li key={index} className="flex items-center">
                      <CheckIcon className="h-5 w-5 text-coSpireBlack mr-2" />
                      <span className="text-sm text-coSpireBlack">
                        {feature}
                      </span>
                    </li>
                  ))}
                  {[
                    "Advanced Insights and Analytics",
                    "Monthly performance report",
                    "Outbound marketing (Ads)",
                  ].map((feature, index) => (
                    <li
                      key={index}
                      className="flex items-center text-[#8C8C8C]"
                    >
                      <LockKeyholeIcon className="h-5 w-5 mr-2" />
                      <span className="text-sm">{feature}</span>
                    </li>
                  ))}
                </ul>
              </CardContent>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
}
