import { FirebaseError, initializeApp } from "firebase/app";
import {
  getAuth,
  GoogleAuthProvider,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  sendEmailVerification,
  User,
  sendPasswordResetEmail,
  onIdTokenChanged,
  updatePassword,
} from "@firebase/auth";
import { config } from "./config";
import { getFirestore, doc, getDoc, updateDoc } from "firebase/firestore";
import { IGetUserResponse } from "../interfaces/apiResponse";
import { toast } from "react-toastify";
import { ErrorMessage } from "formik";
import { useQueryClient } from "react-query";
import { API_ENDPOINTS } from "@/data/client/api-endpoints";

export const app = initializeApp(config);
// Initialize Cloud Firestore and get a reference to the service
const db = getFirestore(app);
export const auth = getAuth(app);
export const authState = onAuthStateChanged;
export const signIn = signInWithEmailAndPassword;
export const resetPassword = sendPasswordResetEmail;
export const authIdTokenChanged = onIdTokenChanged;
export type IError = FirebaseError;

export const GoogleProvider = new GoogleAuthProvider();
GoogleProvider.addScope("profile");
GoogleProvider.addScope("email");
GoogleProvider.setCustomParameters({
  prompt: "select_account",
  ux_mode: "redirect",
});

export const signInWithGoogle = () => {
  // auth.signInWithRedirect(GoogleProvider);
};

export const sendVerificationEmail = async (url: string) => {
  const user: User | null = auth.currentUser;
  try {
    if (user) {
      await sendEmailVerification(user, { url: url });
    }
  } catch (error) {
    toast.error("An error occured sending email");
  }
};

export const getToken = async () => {
  if (auth.currentUser) {
    const token: string = await auth.currentUser.getIdToken(true);
    if (token) {
      return token;
    }
    return null;
  }
  return null;
};

export const handleFirebaseError = (error: IError) => {
  switch (error.code) {
    case "auth/invalid-email":
      toast.error("Invalid email address");
      break;
    case "auth/user-not-found":
      toast.error("User not found");
      break;
    case "auth/wrong-password":
      toast.error("Incorrect password");
      break;
    case "auth/user-disabled":
      toast.error("User account is disabled");
      break;
    case "auth/too-many-requests":
      toast.error(
        "Too many unsuccessful login attempts. Please try again later."
      );
      break;
    case "INVALID_LOGIN_CREDENTIALS":
      toast.error("Invalid login credentials");
      break;
    default:
      toast.error("Please enter valid credentials");
      break;
  }
};

export const isPasswordValid = async (email: string, password: string) => {
  return signInWithEmailAndPassword(auth, email, password)
    .then((res) => {
      console.log("res", res);

      return { valid: true };
    })
    .catch((err) => {
      console.log("err", err);

      return { valid: false };
    });
};

export const changePassword = async (newPassword: string) => {
  const user: User | null = auth.currentUser;
  try {
    if (user) {
      await updatePassword(user, newPassword);
    }
  } catch (error: unknown) {
    handleFirebaseError(error as IError);
    throw error;
  }
};
