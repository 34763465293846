import { Link } from "react-router-dom";
import { getColor } from "./util";
import { pin } from "@/assets/img";
import { Workspace } from "@/types/workspace";
import { formatAddress } from "@/lib/format-address";
import { ApprovalRequest } from "@/types/approval_request";

export const columns = [
  {
    title: (
      <span className="text-sm font-medium uppercase text-coSpireBlack w-[500%] ">
        Listing
      </span>
    ),
    dataIndex: "workspace",
    key: "workspace.id",
    render: ({ title, address }: Workspace) => (
      <div>
        <p className="text-coSpireBlack font-normal text-base">{title}</p>
        <div className="flex w-[100%] gap-1">
          <img src={pin} alt="..." className="object-contain w-3" />
          <p className="text-primaryText text-sm">
            {formatAddress({ ...address, zip: address.zip_code })}
          </p>
        </div>
      </div>
    ),
  },
  {
    title: (
      <span className="text-sm whitespace-nowrap font-medium text-coSpireBlack uppercase">
        Space Type
      </span>
    ),
    dataIndex: "workspace",
    key: "workspace.space_category.id",
    render: (space: Workspace) => {
      return (
        <span className="text-primaryText text-sm">
          {typeof space.space_category === "object" &&
            space.space_category.name}
        </span>
      );
    },
  },
  {
    title: (
      <span className="text-sm font-medium text-coSpireBlack uppercase">
        Verdict
      </span>
    ),
    dataIndex: "status",
    key: "status",
    render: (status: "approved" | "rejected" | "pending") => {
      return (
        <span
          className="py-1.5 px-5 rounded-[56px] capitalize"
          style={getColor(status)}
        >
          {status}
        </span>
      );
    },
  },
  {
    title: (
      <span className="text-sm font-medium text-coSpireBlack uppercase">
        More
      </span>
    ),
    dataIndex: "",
    key: "id",
    render: ({ id, workspace }: ApprovalRequest) => {
      return (
        <Link
          className=" cursor-pointer text-primary text-base "
          to={`/my-listing/preview/space/${workspace.workspace_id}?request=${id}`}
        >
          View Details
        </Link>
      );
    },
  },
];
