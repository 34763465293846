export const getColor = (text: string) => {
  if (text == "pending") {
    return {
      color: "rgba(0, 71, 171, 1)",
      backgroundColor: "rgba(221, 235, 255, 1)",
    };
  } else if (text == "rejected") {
    return {
      color: "red",
      backgroundColor: "rgba(221, 235, 255, 1)",
    };
  } else if (text == "approved") {
    return {
      color: "green",
      backgroundColor: "rgba(221, 235, 255, 1)",
    };
  } else {
    return {
      color: "black",
      backgroundColor: "rgba(221, 235, 255, 1)",
    };
  }
};
