import FeatureList from "@/components/subscriptions/FeatureList";
import { subscription_data } from "@/data/static/subscription-data";
import { useActiveSubscription } from "@/data/subscription";
import { Features } from "@/types/subscription";
import { formatMoney } from "@/utils/format-money";
import { useMemo, useState } from "react";

interface PlansProps {
  onPlanSelect: (planId: string, freq: string) => void;
}
enum PlanBreakdown {
  MONTHLY = "monthly",
  YEARLY = "yearly",
}
const Plans = ({ onPlanSelect }: PlansProps) => {
  const [activeSelection, setActiveSelection] = useState<PlanBreakdown>(
    PlanBreakdown.MONTHLY,
  );

  const handleSelection = (breakdown: PlanBreakdown) => {
    setActiveSelection(breakdown);
  };

  const handleSubscriptionPlan = (plan: string) => {
    onPlanSelect(plan, activeSelection);
  };

  const { subscription, isLoading } = useActiveSubscription();

  console.log({ subscription, isLoading });

  const activeSubscription = useMemo(() => {
    return Object.values(subscription_data).find((item) => {
      return item.plan_code === subscription?.plan;
    });
  }, [subscription]);

  console.log({ activeSubscription });

  const activeTab = (buttonLabel: PlanBreakdown) => {
    return activeSelection === buttonLabel
      ? "bg-darkBlue text-white"
      : "bg-lightBlue text-textGrey";
  };
  return (
    <div className="grid place-items-center gap-4 text-coSpireBlack">
      <div className="flex">
        <button
          className={`rounded-l-2xl px-4 py-2 text-center ${activeTab(
            PlanBreakdown.MONTHLY,
          )}`}
          onClick={() => handleSelection(PlanBreakdown.MONTHLY)}
        >
          Monthly
        </button>
        <button
          className={`rounded-r-2xl px-4 py-2 text-center ${activeTab(
            PlanBreakdown.YEARLY,
          )}`}
          onClick={() => handleSelection(PlanBreakdown.YEARLY)}
          disabled
        >
          Yearly
        </button>
      </div>

      <div>
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-4 place-items-center">
          {Object.keys(subscription_data).map((key) => {
            const plan = subscription_data[key as keyof Features];

            return (
              <div
                key={key as string}
                className={`grid gap-4 p-6 border border-alpha-blue rounded-2xl ${
                  key === "premium" ? "bg-alpha-blue" : "bg-white"
                }`}
              >
                <div className="border-b-alpha-blue grid gap-4">
                  <div className="">
                    <div className="flex justify-between">
                      <p className="text-2xl leading-8 capitalize">{key}</p>
                      {key === "premium" && (
                        <span className="text-black bg-[#FFE599] font-[9px] px-2 py-1 rounded-2xl">
                          Recommended
                        </span>
                      )}
                    </div>
                    <p className="leading-4">
                      {subscription_data[key as keyof Features].description}
                    </p>
                  </div>

                  <div className="grid mt-8">
                    <p className="leading-9  text-3xl">
                      {formatMoney(
                        subscription_data[key as keyof Features].price * 100,
                      )}{" "}
                      <sub className="text-[9px] text-darkBlue">Save 5%</sub>
                    </p>
                    <p>Per month, billed quarterly</p>
                  </div>
                  <div className="grid ">
                    <button
                      className="ring-1 px-4 py-2 rounded-2xl w-full hover:bg-darkBlue hover:text-white disabled:cursor-not-allowed disabled:opacity-65"
                      disabled={
                        key === activeSubscription?.id ||
                        (activeSubscription &&
                          activeSubscription.level > plan.level)
                      }
                      onClick={() => handleSubscriptionPlan(key)}
                    >
                      {key === activeSubscription?.id ? (
                        "Subscribed"
                      ) : (
                        <>
                          {activeSubscription &&
                          activeSubscription.level < plan.level
                            ? "Upgrade"
                            : "Subscribe"}
                        </>
                      )}
                    </button>
                  </div>
                </div>
                <FeatureList
                  features={subscription_data[key as keyof Features].features}
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Plans;
