import React, { useState } from "react";
import styles from "./pricingFAQ.module.scss";
import {
  community1,
  about_us,
  community2,
  location,
  offices,
} from "@/assets/img";
import arrowright from "@/assets/svgs/arrow-long-right.svg";
// import { Button } from "antd";
import Button from "@/components/c/Button";
import users from "../../assets/svgs/users.svg";
import check from "../../assets/svgs/check.svg";
import chevronDown from "../../assets/svgs/chevron-down.svg";

import { useNavigate } from "react-router-dom";
import { TeamsBooking } from "@/components/a/Modals";

const PricingFAQ = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [activeIndex, setActiveIndex] = useState(null);

  const handleQuestionClick = (index: any) => {
    if (index === activeIndex) {
      setActiveIndex(null);
    } else {
      setActiveIndex(index);
    }
  };

  const handleGetAccess = () => {
    setIsOpen(true);
  };
  const closeModal = () => {
    setIsOpen(false);
  };

  const navigate = useNavigate();
  return (
    <div className={styles.page}>
      <div className={styles.banner}>
        <div className={styles.bannerContent}>
          <h1 className={styles.title}>Co-Spire Pricing</h1>
          <p className={styles.description}>
            Lorem ipsum dolor sit amet consectetur. Posuere pellentesque libero
            lectus diam. Aliquam luctus facilisi facilisi platea amet porta at
            netus.
          </p>
        </div>
      </div>

      <section className={styles.bookForTeams}>
        <div className={styles.top}>
          <p className={styles.subtitle}>Pricing</p>
          <h1 className={styles.title}>Flexible plans to suit you</h1>
        </div>

        <div className={styles.body}>
          {pricingPackages.map((pricingPackage) => {
            if (pricingPackage.name === "Business Plan")
              return (
                <div className={`${styles.businessCard} `}>
                  {pricingPackage.name === "Business Plan" && (
                    <div className={styles.businessTop}> Most popular plan</div>
                  )}
                  <div className={`${styles.card} ${styles.cardTopBorder}`}>
                    <div className={styles.cardTop}>
                      <h1 className={styles.price}>
                        ${pricingPackage.price}/year
                      </h1>
                      <p className={styles.plan}>{pricingPackage.name}</p>
                      <p className={styles.billing}>{pricingPackage.billed}</p>
                    </div>
                    <ul className={styles.properties}>
                      {pricingPackage.properties?.map((property) => (
                        <div className={styles.property}>
                          <img className={styles.icon} src={check} alt="" />
                          <li>{property}</li>
                        </div>
                      ))}
                    </ul>
                    <div className={styles.btnContainer}>
                      <Button
                        type="transparent"
                        onClick={handleGetAccess}
                        className={styles.button}
                        children="Get Access"
                      />
                    </div>
                  </div>
                </div>
              );

            return (
              <div>
                <div className={`${styles.card} ${styles.rounded}`}>
                  <div className={styles.cardTop}>
                    <h1 className={styles.price}>
                      ${pricingPackage.price}
                      {pricingPackage.name == "Free" ? "" : "/ltd"}{" "}
                    </h1>
                    <p className={styles.plan}>{pricingPackage.name}</p>
                    <p className={styles.billing}>{pricingPackage.billed}</p>
                  </div>
                  <ul className={styles.properties}>
                    {pricingPackage.properties?.map((property) => (
                      <div className={styles.property}>
                        <img className={styles.icon} src={check} alt="" />
                        <li>{property}</li>
                      </div>
                    ))}
                  </ul>
                  <div className={styles.btnContainer}>
                    <Button
                      onClick={handleGetAccess}
                      type="transparent"
                      className={styles.button}
                      children="Get Access"
                    />
                  </div>
                </div>
              </div>
            );
          })}
        </div>

        {isOpen && <TeamsBooking isOpen={isOpen} closeModal={closeModal} />}
      </section>

      <section className={styles.contactSales}>
        <div className={styles.contact}>
          <div className={styles.top}>
            <p>FAQS</p>
            <h1>Get immediate answers to any questions you might have</h1>
          </div>
          <div className={styles.accordionContainer}>
            {faqs.map((faq, index) => (
              <div key={index} className={styles.faq}>
                <div
                  className={styles.question}
                  onClick={() => handleQuestionClick(index)}
                >
                  <h1>{faq.question}</h1>
                  <img src={chevronDown} alt="" />
                </div>
                {activeIndex === index && (
                  <p className={styles.answer}>{faq.answer}</p>
                )}
              </div>
            ))}
          </div>
        </div>
      </section>

      <section className={styles.seventh}>
        <div>
          <h2>Sponsor an event with us</h2>
          <p>
            Lorem ipsum dolor sit amet consectetur. Eros viverra libero at
            libero eget. Orci vestibulum egestas nec auctor posuere. At id amet
            eu vitae. Quam id amet mauris fames arcu sit nec. Rhoncus ac nunc
            phasellus semper cras bibendum lectus. Commodo in et ut nibh in
            pellentesque aliquet.{" "}
          </p>

          <Button type="transparent">See all Events</Button>
        </div>
      </section>
    </div>
  );
};

export default PricingFAQ;

const faqs = [
  {
    question: "What is Co-Spire?",
    answer:
      "CO-SPIRE is your marketplace for creative, work and event spaces. It is designed to connect creatives, professionals, and event planners with unique spaces tailored for content shoots, podcast recordings, co-working, events, and more. Whether you're a space owner looking to monetize your venue or a professional seeking the perfect location, Co-Spire offers a seamless way to manage listings, bookings, and payments, making it easier for you to focus on your core activities.",
  },

  {
    question: "How do I sign up?",
    answer: `Signing up on Co-Spire is quick and easy. Simply visit our website, click on the "Sign Up" button, and fill in your details. You can choose to sign up as a host to list your space or as a user to book spaces. Once your account is created, you’ll have access to all the tools and features Co-Spire offers.`,
  },
  {
    question: "How do I List my space?",
    answer:
      "To list your space on Co-Spire, start by signing up on our platform. After registration, you can create a host profile by providing details about your space, including its type, location, and features. Once your profile is set up, you'll be ready to list your space and start accepting bookings from our community of creatives and professionals.",
  },
  {
    question: "Will I ever be charged unexpectedly for using the platform?",
    answer:
      "No, there are no unexpected charges. With our free plan, you can list up to six spaces without any upfront costs. We only earn a small percentage on each booking that happens through our platform, so you’re only charged when you’re earning. Any optional upgrades or services are clearly priced, and you’ll always have full control over what you choose to purchase.",
  },
  {
    question: "How do I get verified?",
    answer:
      "Getting verified on Co-Spire adds credibility to your listing and can increase your chances of booking. To get verified, submit your space for verification through your dashboard. Our team will review your submission and request additional information or a site visit. Once approved, your space will receive a verified badge, signalling to potential clients that your listing meets our quality standards.",
  },
];

const pricingPackages = [
  {
    name: "Free",
    price: 0,
    billed: "Auto Renew",
    properties: [
      "A super simple icon list element.",
      "It has several list items you can easily edit.",
      "Delete rows you don't need.",
      "Duplicate existing rows to add more.",
      "Change the icons per row.",
    ],
  },
  {
    name: "Business Plan",
    price: 99,
    billed: "Billed Annually",
    properties: [
      "A super simple icon list element.",
      "It has several list items you can easily edit.",
      "Delete rows you don't need.",
      "Duplicate existing rows to add more.",
      "Change the icons per row.",
    ],
  },
  {
    name: "Enterprise Plan",
    price: 129,
    billed: "Billed Once",
    properties: [
      "A super simple icon list element.",
      "It has several list items you can easily edit.",
      "Delete rows you don't need.",
      "Duplicate existing rows to add more.",
      "Change the icons per row.",
    ],
  },
];
