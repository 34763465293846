import { axiosPrivate } from "../axiosPrivate";
import { handleError } from "./error";
import { RequestResponse } from "../../types";
import {
  GET_LIST_OF_BANKS,
  CONFIRM_BANK_ACCOUNT,
  WITHDRAW_FUNDS,
} from "../apiEndpoints";
import { API_ENDPOINTS } from "@/data/client/api-endpoints";

const getBanks = async () => {
  try {
    const endpoint = `${import.meta.env.VITE_BASE_URL + GET_LIST_OF_BANKS}`;
    const response: RequestResponse = await axiosPrivate.get(endpoint);
    const result = response.data?.data;
    return result;
  } catch (error: any) {
    return handleError(error);
  }
};

const confirmBankAccount = async (accountNo: string, bankCode: string) => {
  try {
    const endpoint = `${
      import.meta.env.VITE_BASE_URL + CONFIRM_BANK_ACCOUNT
    }?account_number=${accountNo}&bank_code=${bankCode}`;
    const response: RequestResponse = await axiosPrivate.get(endpoint);
    return response.data?.data;
  } catch (error: any) {
    return handleError(error);
  }
};

const addAccount = async (payload: {
  account_name: string;
  account_number: string;
  bank_code: string;
  bank_name: string;
  currency: string;
}) => {
  try {
    const url =
      import.meta.env.VITE_BASE_URL + API_ENDPOINTS.ADD_WITHDRAWAL_BANK;
    const response: RequestResponse = await axiosPrivate.post(url, payload);
    return response.data;
  } catch (error: any) {
    return handleError(error);
  }
};
const withdrawFunds = async (data: any) => {
  try {
    const endpoint = `${import.meta.env.VITE_BASE_URL + WITHDRAW_FUNDS}`;
    const response: RequestResponse = await axiosPrivate.post(endpoint, data);
    return response.data;
  } catch (error: any) {
    return handleError(error);
  }
};

export { getBanks, confirmBankAccount, addAccount, withdrawFunds };
