import { RequestResponse } from "../../types";
import {
  CONFIRM_BOOKING_AVAILABILITY,
  CREATE_BOOKING,
  LIST_BOOKING,
  CANCEL_BOOKING,
  GET_ALL_BOOKING,
  GET_SINGLE_BOOKING,
  ADD_DATE_TO_BOOKING,
  ONE_OFF_BOOKING,
  CREATE_TRANSACTION,
} from "../apiEndpoints";
import { axiosPrivate } from "../axiosPrivate";

const confirmBookingAvailability = async (payload: any) => {
  const endpoint = `${
    import.meta.env.VITE_BASE_URL + CONFIRM_BOOKING_AVAILABILITY
  }`;
  const response: RequestResponse = await axiosPrivate.post(endpoint, payload);
  return response;
};

const createBooking = async (
  payload: any,
  workspace_id: string,
  user_id: string,
) => {
  const endpoint = `${import.meta.env.VITE_BASE_URL + CREATE_BOOKING}?workspace_id=${workspace_id}&user_id=${user_id}`;
  const response: RequestResponse = await axiosPrivate.post(endpoint, payload);
  console.log("res", response);
  return response;
};

const createTransaction = async (
  payload: any,
  workspace_id: string,
  user_id: string,
) => {
  const endpoint = `${import.meta.env.VITE_BASE_URL + CREATE_TRANSACTION}?workspace_id=${workspace_id}&user_id=${user_id}`;
  const response: RequestResponse = await axiosPrivate.post(endpoint, payload);
  console.log("res", response);
  return response;
};

const oneOffBooking = async (payload: any, workspace_id: string) => {
  const endpoint = `${import.meta.env.VITE_BASE_URL + ONE_OFF_BOOKING}?workspace_id=${workspace_id}`;
  const response: RequestResponse = await axiosPrivate.post(endpoint, payload);
  console.log("res", response);
  return response;
};

const listBooking = async (booking_id: string) => {
  const endpoint = `${import.meta.env.VITE_BASE_URL + LIST_BOOKING}?booking_id=${booking_id}`;
  const response: RequestResponse = await axiosPrivate.get(endpoint);
  return response;
};

const cancelBooking = async (booking_id: string) => {
  const endpoint = `${import.meta.env.VITE_BASE_URL + CANCEL_BOOKING}?booking_id=${booking_id}`;
  const response: RequestResponse = await axiosPrivate.post(endpoint);
  return response;
};

const getAllBookings = async (user_id: string) => {
  const endpoint = `${import.meta.env.VITE_BASE_URL + GET_ALL_BOOKING}?user_id=${user_id}`;
  const response: RequestResponse = await axiosPrivate.get(endpoint);
  return response.data;
};

const getSingleBooking = async (booking_id: any) => {
  const endpoint = `${import.meta.env.VITE_BASE_URL + GET_SINGLE_BOOKING}?booking_id=${booking_id}`;
  const response: RequestResponse = await axiosPrivate.get(endpoint);
  return response;
};

const addDateToBooking = async (bookingId: any, payload: any) => {
  const endpoint = `${import.meta.env.VITE_BASE_URL + ADD_DATE_TO_BOOKING}?booking_id=${bookingId}`;
  const response: RequestResponse = await axiosPrivate.patch(endpoint, payload);
  return response;
};

export {
  confirmBookingAvailability,
  createBooking,
  getAllBookings,
  listBooking,
  cancelBooking,
  getSingleBooking,
  addDateToBooking,
  oneOffBooking,
  createTransaction,
};
