import React, { useEffect } from "react";
import { SpaceValues } from "@/components/space-listing/schema";
import "./setupPage.scss";

import { CustomButton } from "@/components/customButton";
import { Col, Row, Space, Checkbox } from "antd";
import { CustomInputField } from "@/components/customInput";
import CustomTextArea from "@/components/customTextArea";

import { Radio, RadioGroup } from "@headlessui/react";
import { useFormikContext } from "formik";
import { cn } from "@/lib/utils";

export function BasicDetailsForm({ values }: { values: Partial<SpaceValues> }) {
  const { setFieldValue } = useFormikContext();

  return (
    <div className="page-one page-three">
      <div className={cn("heading-div", "flex !justify-between")}>
        <div>
          <h2>Choose a unique space title</h2>
          <p className="sub-title">
            Choose a unique space titles to get more visibility to your space.
          </p>
        </div>
        <div className=""></div>
      </div>
      <ul>
        <li>Name should <b>NOT </b>be company name</li>
        <li>
          Title should clearly describe the activities that the space allows.
        </li>
        <li>Title can describe unique features of the space</li>
      </ul>
      <p>[Example title: All white elegant studio with rooftop space]</p>

      <Row gutter={[45, 24]}>
        <Col md={12} xs={12}>
          <CustomInputField
            type={"text"}
            label="Space Title"
            name="spaceTitle"
            placeholder="Select Space Title"
            value={values.spaceTitle}
            onChange={(e) => setFieldValue("spaceTitle", e.target.value)}
          />
        </Col>
      </Row>
      <Space direction="vertical" size="middle" className="flex"></Space>
      <Row gutter={[45, 24]}>
        <Col md={12} xs={12}>
          <CustomInputField
            name="numberOfPeoples"
            type={"numeric"}
            label="How many guests can this space accommodate"
            placeholder="E.g 5"
            value={values.numberOfPeoples}
            onChange={(e) =>
              setFieldValue("numberOfPeoples", Number(e.target.value))
            }
          />
        </Col>
      </Row>

      <Space direction="vertical" size="middle" className="flex"></Space>
      <Row gutter={[45, 24]}>
        <Col md={12} xs={12}>
          <CustomTextArea
            label="About the space"
            name="about"
            placeholder="Enter space description"
            value={values.about}
            onChange={(e) => setFieldValue("about", e.target.value)}
          />
        </Col>
      </Row>

      <div className="amenities">
        <h2>What type of facilities do you have?</h2>
        <Checkbox.Group
          style={{
            width: "100%",
            display: "flex",
            flexWrap: "wrap",
            paddingTop: "1.5%",
          }}
          value={values.facilities}
          onChange={(checkedValues) => {
            setFieldValue("facilities", checkedValues);
          }}
        >
          <Row gutter={[8, 8]} wrap>
            <Col span={24}>
              <Checkbox value="conference-room">Conference room</Checkbox>
            </Col>
            <Col span={24}>
              <Checkbox value="air-conditioning">Air conditioning </Checkbox>
            </Col>
            <Col span={24}>
              <Checkbox value="green-room">Green room </Checkbox>
            </Col>
            <Col span={24}>
              <Checkbox value="kitchen-area">Kitchen area</Checkbox>
            </Col>
            <Col span={24}>
              <Checkbox value="hair">Hair/makeup/Changing room</Checkbox>
            </Col>
            <Col span={24}>
              <Checkbox value="outdoor">Outdoor space</Checkbox>
            </Col>
          </Row>
        </Checkbox.Group>
      </div>

      <div className="amenities">
        <h2>Activate instant booking for your space</h2>
        <p className="sub-title">
          Instant booking allows renter’s booking to be instantly confirmed.{" "}
        </p>
        <p>Do you want to activate instant booking?</p>
        <RadioGroup
          name="instantBooking"
          value={values.instantBooking ? "yes" : "no"}
          onChange={(value) => {
            setFieldValue("instantBooking", value === "yes" ? true : false);
          }}
        >
          <div className="my-4">
            <div className="flex items-center gap-2">
              <Radio
                value="yes"
                className="group cursor-pointer rounded-xl items-center justify-center bg-alpha-blue flex size-5 data-[checked]:border-primary border-2 border-[#EFF0F6]"
              >
                <span className="size-2 rounded-full group-data-[checked]:bg-primary group-data-[checked]:visible" />
              </Radio>
              <span>Yes</span>
            </div>
            <div className="flex items-center gap-2">
              <Radio
                value="no"
                className="group cursor-pointer rounded-xl items-center justify-center bg-alpha-blue flex size-5 data-[checked]:border-primary border-2 border-[#EFF0F6]"
              >
                <span className="size-2 rounded-full group-data-[checked]:bg-primary group-data-[checked]:visible" />
              </Radio>
              <span>No</span>
            </div>
          </div>
        </RadioGroup>
      </div>
    </div>
  );
}
