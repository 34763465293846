import React, { useEffect, useRef, useState } from "react";
import styles from "./Venues.module.scss";
import searchIcon from "@/assets/svgs/searchIconBlue.svg";
import venueImageOne from "@/assets/img/2_2_Frame_25.png";
import venueImageTwo from "@/assets/img/1_5_Frame_36.png";
import venueImageThree from "@/assets/img/2_4_Frame_26.png";
import venueImageFour from "@/assets/img/2_5_Frame_32.png";
import useCheckIsMobileScreen from "@/hooks/useCheckIsMobileScreen";
import star from "@/assets/svgs/star-filled.svg";
import userOne from "@/assets/img/userOne.png";
import userTwo from "@/assets/img/userTwo.png";
import userThree from "@/assets/img/userThree.png";
import userFour from "@/assets/img/userFour.png";
import { locations } from "@/mocks/index";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "@/redux/hook";

import { AntDesignOutlined, UserOutlined } from "@ant-design/icons";
import { Avatar, Divider, Drawer, Tooltip } from "antd";
import AddressAutocomplete from "@/components/address-autocomplete";
import { WORKSPACES } from "@/routes/routes";
import { useWorkspacesQuery } from "@/data/workspace";
import { cn } from "@/lib/utils";
import { useCategoriesQuery } from "@/data/category";
import { useActivitiesQuery } from "@/data/activities";
import { useLocationsQuery } from "@/data/location";
import SearchableSelect from "@/components/searchableSelect";
import { Formik, Field, Form } from 'formik';
import { Activities, Locations } from "@/types/activities";
import { CategoriesQueryOptions, Category } from "@/types/category";

const data = [
  "Content Creation",
  "Podcast studio",
  "Party space",
  "Filming venues",
  "Outdoor wedding",
  "Video shooting venues",
  "Work meetings",
  "Podcast Studio",
];

interface Space {
  thumbNail: string;
}
const LIMIT = 100;

type Obj = {
  name: string;
  slug?: string;
};

const Venues = () => {
  const isMobile = useCheckIsMobileScreen();
  const [search, setSearch] = useState("");
  const [isFocused, setIsFocused] = useState(false);
  const { workspaces, loading } = useWorkspacesQuery({
    limit: LIMIT,
  });
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [isActivityDrawerOpen, setIsActivityDrawerOpen] = useState(false);
  const [isLocationDrawerOpen, setIsLocationDrawerOpen] = useState(false);
  const [searchtitle, setSearchtitle] = useState('');
  const [searchLocation, setSearchLocation] = useState('');

  const { categories } = useCategoriesQuery({});
  const { activities } = useActivitiesQuery({});
  const { locations } = useLocationsQuery({});

  const filteredTitles = [
    ...activities.map((activity: any) => activity.name || activity.slug),
    ...categories.map((category: Category) => category.name),
  ];

  const filterSpaces = [
    ...locations.map((location: Locations) => {
      const address = location.address.street_address;
      const parts = address.split(',');
      if (parts.length > 2) {
        return parts.slice(-2).join(',');
      }
      return address;
    })
  ];
  const titleOptions = searchtitle === ""
    ? filteredTitles.map(
      (activity: string, index: number) => {
        return {
          value: index,
          label: activity,
        };
      }
    )
    : [];

  const locationOptions = searchLocation === ""
    ? filterSpaces.map(
      (location: string, index: number) => {
        return {
          value: index,
          label: location,
        };
      }
    )
    : [];

  const avatarData = [
    { img: userOne },
    { img: userTwo },
    { img: userThree },
    { img: userFour },
  ];

  return (
    <div className={styles.page}>
      <Formik
        initialValues={{ searchtitle: '', searchLocation: '' }}
        onSubmit={(values) => {
          navigate(WORKSPACES, { state: values });
        }}
      >
        {({ setFieldValue, handleSubmit }) => (
          <Form>
            <>
              <div className="md:hidden flex">
                <div className={cn(styles.mobile_container)}>
                  <div className={cn(styles.data_container)}>
                    <p className={styles.header_text}>
                      Find the perfect venue for your next event
                    </p>
                    <div className={styles.inbox_container}>
                      <div className={styles.search_inbox1}>
                        <input
                          type="search"
                          placeholder="What are you planning?"
                          value={searchtitle}
                          onFocus={() => setIsActivityDrawerOpen(true)}
                        />
                      </div>
                      <div className={styles.search_inbox}>
                        <input
                          type="search"
                          value={searchLocation}
                          placeholder="Where?"
                          onFocus={() => setIsLocationDrawerOpen(true)}
                        />
                      </div>
                    </div>
                    <button
                      onClick={() => {
                        navigate(WORKSPACES, {
                          state: { searchtitle, searchLocation },
                        });
                      }}
                    >
                      Search
                    </button>
                    <div className={styles.userAvatar_mobile}>
                      <Avatar.Group>
                        {avatarData.slice(0, 4).map((item, i) => (
                          <Avatar key={i} src={item.img} />
                        ))}
                      </Avatar.Group>
                      <div>
                        <p>200+ users</p>
                        <div className={styles.stars}>
                          {[...Array(5)].map((_, i) => (
                            <img key={i} src={star} width={20} alt="star" />
                          ))}
                        </div>
                      </div>
                    </div>
                    {isActivityDrawerOpen && (
                      <Drawer
                        placement="bottom"
                        width="100%"
                        onClose={() => setIsActivityDrawerOpen(false)}
                        open={isActivityDrawerOpen}
                        style={{
                          backgroundColor: "#fff",
                          borderRadius: "16px 16px 0 0",
                        }}
                        title="What are you planning?"
                      >
                        <div className={styles.searchResult}>
                          <Field
                            name="searchtitle"
                            component={SearchableSelect}
                            options={titleOptions}
                            placeholder="Enter your activity"
                            showSuffix={false}
                            onChange={(option: any) => {
                              setSearchtitle(option ? option.label : '');
                              setIsActivityDrawerOpen(false);
                            }}
                          />
                          {filteredTitles.length === 0 && searchtitle && (
                            <div className={styles.userSearch}>
                              <p>No data</p>
                            </div>
                          )}
                        </div>
                      </Drawer>
                    )}
                    {isLocationDrawerOpen && (
                      <Drawer
                        placement="bottom"
                        width="100%"
                        onClose={() => setIsLocationDrawerOpen(false)}
                        open={isLocationDrawerOpen}
                        style={{
                          backgroundColor: "#fff",
                          borderRadius: "16px 16px 0 0",
                        }}
                        title="Where?"
                      >
                        <div className={styles.searchResult}>
                          <Field
                            name="searchLocation"
                            component={SearchableSelect}
                            options={locationOptions}
                            placeholder="Enter city or address"
                            showSuffix={false}
                            onChange={(option: any) => {
                              setSearchLocation(option ? option.label : '');
                              setIsLocationDrawerOpen(false);
                            }}
                          />
                          {filterSpaces.length === 0 && searchLocation && (
                            <div className={styles.userSearch}>
                              <p>No data</p>
                            </div>
                          )}
                        </div>
                      </Drawer>
                    )}
                  </div>

                  <div className={styles.image_con}>
                    <div className={styles.imageCon}>
                      <div className={styles.imageSlide}>
                        <img src={venueImageOne} alt="" />
                        <img src={venueImageTwo} alt="" />
                        <img src={venueImageThree} alt="" />
                        <img src={venueImageFour} alt="" />
                        <img src={venueImageTwo} alt="" />
                        <img src={venueImageFour} alt="" />
                        <img src={venueImageFour} alt="" />
                        <img src={venueImageTwo} alt="" />
                        <img src={venueImageThree} alt="" />
                        <img src={venueImageOne} alt="" />
                        <img src={venueImageFour} alt="" />
                        <img src={venueImageThree} alt="" />
                      </div>
                      <div className={styles.imageSlide}>
                        <img src={venueImageOne} alt="" />
                        <img src={venueImageTwo} alt="" />
                        <img src={venueImageThree} alt="" />
                        <img src={venueImageFour} alt="" />
                        <img src={venueImageOne} alt="" />
                        <img src={venueImageTwo} alt="" />
                        <img src={venueImageOne} alt="" />
                        <img src={venueImageFour} alt="" />
                        <img src={venueImageTwo} alt="" />
                        <img src={venueImageFour} alt="" />
                        <img src={venueImageTwo} alt="" />
                        <img src={venueImageFour} alt="" />
                      </div>
                      <div className={styles.imageSlide}>
                        <img src={venueImageOne} alt="" />
                        <img src={venueImageTwo} alt="" />
                        <img src={venueImageThree} alt="" />
                        <img src={venueImageFour} alt="" />
                        <img src={venueImageThree} alt="" />
                        <img src={venueImageOne} alt="" />
                        <img src={venueImageThree} alt="" />
                        <img src={venueImageOne} alt="" />
                        <img src={venueImageFour} alt="" />
                        <img src={venueImageThree} alt="" />
                        <img src={venueImageOne} alt="" />
                        <img src={venueImageTwo} alt="" />
                      </div>
                      <div className={styles.imageSlide}>
                        <img src={venueImageOne} alt="" />
                        <img src={venueImageTwo} alt="" />
                        <img src={venueImageThree} alt="" />
                        <img src={venueImageFour} alt="" />
                        <img src={venueImageTwo} alt="" />
                        <img src={venueImageFour} alt="" />
                        <img src={venueImageFour} alt="" />
                        <img src={venueImageTwo} alt="" />
                        <img src={venueImageThree} alt="" />
                        <img src={venueImageOne} alt="" />
                        <img src={venueImageFour} alt="" />
                        <img src={venueImageThree} alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="hidden md:flex flex-col ">
                <div className={styles.container}>
                  <div className={styles.userAvatar}>
                    <Avatar.Group>
                      {avatarData.slice(0, 4).map((item, i) => (
                        <Avatar src={item.img} />
                      ))}
                    </Avatar.Group>

                    <div>
                      <p>200+ users</p>

                      <div className={styles.stars}>
                        <img src={star} width={20} alt="imf" />
                        <img src={star} width={20} alt="imf" />
                        <img src={star} width={20} alt="imf" />
                        <img src={star} width={20} alt="imf" />
                        <img src={star} width={20} alt="imf" />
                      </div>
                    </div>
                  </div>

                  <div className={styles.contentLeft}>
                    <div className={styles.content}>
                      <div className={styles.header}>
                        <h2 className={styles.headerText}>
                          Find the perfect venue for your next event
                        </h2>
                        <p className={styles.subText}>
                          Choose from our network of spaces , for your events, shoots
                          and special meetings.
                        </p>
                      </div>
                      <div className={styles.searchContainer}>
                        <div className={styles.search}>
                          <div className={styles.inputBox1}>
                            <Field
                              name="searchtitle"
                              component={SearchableSelect}
                              options={titleOptions}
                              placeholder="What are you planning?"
                              showSuffix={false}
                            />
                          </div>
                          <div className={styles.inputBox2}>
                            <Field
                              name="searchLocation"
                              component={SearchableSelect}
                              options={locationOptions}
                              placeholder="Where?"
                              showSuffix={false}
                            />
                          </div>
                          <button
                            className={styles.searchIcon}
                            onClick={() => handleSubmit()}
                          >
                            <img src={searchIcon} alt="search_icon" />
                          </button>
                        </div>
                      </div>
                      <div className={styles.tagsContainer}>
                        <span className={styles.span}>Popular searches</span>
                        <div className={styles.tags}>
                          {data.map((tag) => {
                            return (
                              <span
                                className={styles.tag}
                                onClick={() => setFieldValue('searchtitle', tag)}
                              >
                                {tag}
                              </span>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.contentRight}>
                    <div className={styles.imageCon}>
                      <div className={styles.imageSlide}>
                        <img src={venueImageOne} alt="" />
                        <img src={venueImageTwo} alt="" />
                        <img src={venueImageThree} alt="" />
                        <img src={venueImageFour} alt="" />
                        <img src={venueImageTwo} alt="" />
                        <img src={venueImageFour} alt="" />
                        <img src={venueImageFour} alt="" />
                        <img src={venueImageTwo} alt="" />
                        <img src={venueImageThree} alt="" />
                        <img src={venueImageOne} alt="" />
                        <img src={venueImageFour} alt="" />
                        <img src={venueImageThree} alt="" />
                      </div>
                      <div className={styles.imageSlide}>
                        <img src={venueImageOne} alt="" />
                        <img src={venueImageTwo} alt="" />
                        <img src={venueImageThree} alt="" />
                        <img src={venueImageFour} alt="" />
                        <img src={venueImageOne} alt="" />
                        <img src={venueImageTwo} alt="" />
                        <img src={venueImageOne} alt="" />
                        <img src={venueImageFour} alt="" />
                        <img src={venueImageTwo} alt="" />
                        <img src={venueImageFour} alt="" />
                        <img src={venueImageTwo} alt="" />
                        <img src={venueImageFour} alt="" />
                      </div>
                      <div className={styles.imageSlide}>
                        <img src={venueImageOne} alt="" />
                        <img src={venueImageTwo} alt="" />
                        <img src={venueImageThree} alt="" />
                        <img src={venueImageFour} alt="" />
                        <img src={venueImageThree} alt="" />
                        <img src={venueImageOne} alt="" />
                        <img src={venueImageThree} alt="" />
                        <img src={venueImageOne} alt="" />
                        <img src={venueImageFour} alt="" />
                        <img src={venueImageThree} alt="" />
                        <img src={venueImageOne} alt="" />
                        <img src={venueImageTwo} alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default Venues;
