import React from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { Col, Row, Space, Checkbox } from "antd";
import { CustomButton } from "@/components/customButton";
import { useUpdateWorkspaceMutation } from "@/data/workspace";
import { toast } from "react-toastify";
import { handleError } from "@/services/networkRequests";
import { Formik } from "formik";

type SpaceValues = {
  access_available: string[];
  amenities: string[];
  parking: string[];
};

function Amenities() {
  const { state } = useLocation();
  const navigate = useNavigate();
  const params = useParams<{ id: string }>();

  console.log("state: ", state);

  const { mutate: update, isLoading } = useUpdateWorkspaceMutation();

  function handleUpdatingSpace(values: SpaceValues) {
    console.log("values: ", values);

    const payload = {
      space_amenity_obj: values.amenities,
      access_available: values.access_available,
    };
    update(
      { ...payload, id: params.id! },
      {
        onSuccess: () => {
          toast.success("Space updated successfully");
          navigate(-1);
        },
        onError: (error) => handleError(error),
      }
    );
  }

  return (
    <div className="p-5 md:p-9 ">
      <div className=" w-[90%] md:w-[22%] flex justify-between text-textGrey text-base">
        <Link to={"#"}>Listing </Link>
        <span>&gt;</span>
        <Link to={"/my-listings/preview/" + params.id}> Listing details</Link>
        <span>&gt;</span>
        <Link className=" text-primaryText font-semibold" to={"#"}>
          Amenities
        </Link>
      </div>

      <Formik
        initialValues={{
          access_available: state?.access_available || [],
          amenities:
            state?.amenities.map((amenity: { name: string }) => amenity.name) ||
            [],
          parking: [],
        }}
        validate={(values) => {}}
        onSubmit={(values) => handleUpdatingSpace(values)}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue: setValue,
          /* and other goodies */
        }) => (
          <form onSubmit={handleSubmit}>
            <div className="rounded-2xl border border-gray-300 p-5 my-12">
              <p className="text-coSpireBlack text-3xl font-semibold leading-10 ">
                Amenities
              </p>
              <p className=" text-lg text-primaryText leading-7">
                Facilities and features give your spaces an extra edge.
              </p>

              <div className="mt-7">
                <p className="text-coSpireBlack text-xl md:text-2xl font-semibold leading-10 ">
                  Access Available
                </p>
                <p className="text-primaryText text-lg my-2">
                  Select all that apply
                </p>

                <Checkbox.Group
                  style={{ width: "100%", display: "flex", flexWrap: "wrap" }}
                  name="access_available"
                  value={values.access_available}
                  onChange={(value) => setValue("access_available", value)}
                >
                  <Row gutter={[8, 8]} wrap>
                    <Col md={24} xs={24}>
                      <Checkbox value="Elevator">Elevator</Checkbox>
                    </Col>
                    <Col md={24} xs={24}>
                      <Checkbox value="freight-elevator">
                        Freight Elevator 
                      </Checkbox>
                    </Col>
                    <Col md={24} xs={24}>
                      <Checkbox value="stairs">Stairs </Checkbox>
                    </Col>
                    <Col md={24} xs={24}>
                      <Checkbox value="wheelchair-Handicap-Access">
                        OWheelchair/ Handicap Access
                      </Checkbox>
                    </Col>
                  </Row>
                </Checkbox.Group>
              </div>
              <div className="mt-7">
                <p className="text-coSpireBlack text-xl md:text-2xl font-semibold leading-10 ">
                  What other amenities does your space have?
                </p>
                <p className="text-primaryText text-lg my-2">
                  Select all that apply
                </p>

                <Checkbox.Group
                  style={{ width: "100%", display: "flex", flexWrap: "wrap" }}
                  name="amenities"
                  value={values.amenities}
                  onChange={(value) => setValue("amenities", value)}
                >
                  <Row gutter={[8, 8]} wrap>
                    <Col md={24} xs={24}>
                      <Checkbox value="air-conditioning">
                        Air conditioning{" "}
                      </Checkbox>
                    </Col>
                    <Col md={24} xs={24}>
                      <Checkbox value="wifi">Wifi</Checkbox>
                    </Col>
                    <Col md={24} xs={24}>
                      <Checkbox value="projectors">Projectors</Checkbox>
                    </Col>
                    <Col md={24} xs={24}>
                      <Checkbox value="hair-makeup area">
                        Hair/makeup area
                      </Checkbox>
                    </Col>
                    <Col span={24}>
                      <Checkbox value="conference-room">
                        Conference room
                      </Checkbox>
                    </Col>
                    <Col span={24}>
                      <Checkbox value="green-room">Green room </Checkbox>
                    </Col>
                    <Col span={24}>
                      <Checkbox value="kitchen-area">Kitchen area</Checkbox>
                    </Col>
                    <Col span={24}>
                      <Checkbox value="outdoor">Outdoor space</Checkbox>
                    </Col>
                  </Row>
                </Checkbox.Group>
              </div>
              <div className="mt-7">
                <p className="text-coSpireBlack text-xl md:text-2xl font-semibold leading-10 ">
                  Tell us about your parking space
                </p>
                <p className="text-primaryText text-lg my-2">
                  Select all that apply
                </p>

                <Checkbox.Group
                  style={{ width: "100%", display: "flex", flexWrap: "wrap" }}
                >
                  <Row gutter={[8, 8]} wrap>
                    <Col md={24} xs={24}>
                      <Checkbox value="Inpremises">
                        Parking space in the premises or nearby
                      </Checkbox>
                    </Col>
                    <Col md={24} xs={24}>
                      <Checkbox value="OutsidePremises">
                        Parking space outside the premises{" "}
                      </Checkbox>
                    </Col>
                    <Col span={24}>
                      <Checkbox value="free-parking">
                        Free parking space
                      </Checkbox>
                    </Col>
                    <Col span={24}>
                      <Checkbox value="paid-parking">
                        Paid parking space
                      </Checkbox>
                    </Col>
                  </Row>
                </Checkbox.Group>
              </div>
            </div>

            <div className="flex mt-10 my-4 md:gap-7 md:justify-end items-center">
              <CustomButton
                children={"Back to listing details"}
                type={"link"}
                className="font-medium text-darkBlue text-base hover:font-semibold hover:text-darkBlue"
                onClick={() => navigate(-1)}
              />

              <CustomButton
                children={"Save and exit"}
                extraStyles={{ padding: "18px, 24px", fontWeight: "500" }}
                htmlType="submit"
                loading={isLoading}
              />
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
}

export default Amenities;
