import { Spacer } from "@/components/spacer";
import { Footer, Header } from "../components/a";
import { Outlet } from "react-router-dom";

const Layout = () => {
  return (
    <div className={"min-h-screen flex flex-col"}>
      <Header />
      <Spacer height={100} />
      <Outlet />
      <Footer />
    </div>
  );
};

export default Layout;
