import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { Checkbox } from "antd";
import AddonPopup from "@/components/subscriptions/AddonPopup";
import { SelectCard } from "@/components/subscriptions/addCard/select-card";
import AddCard from "@/components/subscriptions/addCard/AddCard";
import { usePaystackPayment } from "react-paystack";
import { ConfigValues } from "@/config";
import { useMe } from "@/data/user";
import { PaystackProps } from "react-paystack/dist/types";
import { useAddPaymentMethodMutation } from "@/data/wallet";
import { toast } from "react-toastify";
import { handleError } from "@/services/networkRequests";
import { subscription_data } from "@/data/static/subscription-data";
import { useCreateSubscriptionMutation } from "@/data/subscription";
import UpdateModalTemplate from "@/components/subscriptions/UpdateModalTemplate";
import blue_guard from "@/assets/svgs/blue_guard.svg";
import { formatMoney } from "@/utils/format-money";
import { HOME, SUBSCRIPTIONS } from "@/routes/routes";

interface AddOn {
  name: string;
}

const Payment = ({
  planId,
  frequency,
}: {
  planId: "starter" | "basic" | "premium";
  frequency: string;
}) => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [isCardOpen, setIsCardOpen] = useState(false);

  const [showModal, setShowModal] = useState(false);

  const { me: user } = useMe();

  const [method, setMethod] = useState<string | null>(null);

  const closeCardModal = () => {
    setIsCardOpen(false);
  };

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };
  const [selectedAddOns, setSelectedAddOns] = useState<string[]>([]);

  const plan = subscription_data[planId];

  const addOns: AddOn[] = [
    {
      name: "CO-SPIRE Verified",
    },
    {
      name: "walkTru-X™️",
    },
  ];
  const toggleAddOn = (addOnName: string) => {
    openModal();
    if (selectedAddOns.includes(addOnName)) {
      setSelectedAddOns(selectedAddOns.filter((item) => item !== addOnName));
    } else {
      setSelectedAddOns([...selectedAddOns, addOnName]);
    }
  };

  // const parseValue = (value: any) => {
  //   return parseInt(value.replace(/[^0-9.-]+/g, ""));
  // };

  const total = plan.price;

  const config: PaystackProps = {
    email: user?.email || "",
    amount: 5000,
    publicKey: ConfigValues.PAYSTACK_PUBLIC_KEY,
    channels: ["card"],
  };

  const { mutate: create, isLoading, status } = useCreateSubscriptionMutation();

  const { mutateAsync: add } = useAddPaymentMethodMutation();

  function handleAdd(reference: string) {
    toast.promise(
      add(
        { reference },
        {
          onSuccess: () => {
            toast.success("Payment method added successfully");
          },
          onError: (err) => {
            handleError(err);
          },
        },
      ),
      {
        pending: "Adding payment method...",
      },
    );
  }

  // you can call this function anything
  const onSuccess = (result: { reference: string }) => {
    handleAdd(result.reference);
  };

  // you can call this function anything
  const onClose = () => {
    // implementation for  whatever you want to do when the Paystack dialog closed.
    console.log("closed");
  };

  const initializePayment = usePaystackPayment(config);

  return (
    <div className="grid grid-cols-1 lg:grid-cols-3 gap-y-4">
      <div className="grid col-span-2 lg:m-6">
        <div className="grid gap-4 mb-8">
          <h2 className="text-3xl lg:4xl">Select Add-ons (optional)</h2>
          <p className="leading-4">
            Select an Add-on to add to your subscription
          </p>

          <div className="flex justify-start">
            {addOns.map((addOn, index) => (
              <div key={index}>
                <Checkbox
                  checked={selectedAddOns.includes(addOn.name)}
                  onChange={() => toggleAddOn(addOn.name)}
                  disabled
                >
                  {addOn.name}
                </Checkbox>
              </div>
            ))}
          </div>
          <AddonPopup
            isOpen={isOpen}
            closeModal={closeModal}
            onPlanSelect={() => console.log("now showing")}
          />
        </div>

        <div className="grid gap-4 mb-8">
          <h2 className="text-3xl xl:4xl">Select payment method</h2>
          <SelectCard setMethod={setMethod} />
        </div>

        <button
          className="font-medium text-darkBlue text-left"
          onClick={() => {
            initializePayment({
              onSuccess,
              onClose,
              config: config,
            });
          }}
        >
          Add New Card
        </button>

        <div className="flex items-start space-x-2 mt-3 mb-8">
          <img src={blue_guard} alt="..." />
          <p className="text-sm">
            When adding new card, you will be charged a temporary fee of ₦
            {formatMoney(50 * 100)} which will be instantly refunded just to
            validate your card.
          </p>
        </div>

        <button
          className="px-4 py-2 rounded-2xl lg:w-2/3 bg-darkBlue text-white w-full disabled:bg-opacity-60"
          onClick={() => {
            console.log("Payment processing");
            setShowModal(true);
            create(
              {
                auth_code: method!,
                plan_code: plan.plan_code,
                addons: [],
              },
              {
                onSuccess: (response) => {
                  toast.success("Subscription created successfully");
                  navigate(SUBSCRIPTIONS, { replace: true });
                },
                onError: (err) => {
                  handleError(err);
                },
                onSettled: () => {
                  setShowModal(false);
                },
              },
            );
          }}
          disabled={!method || isLoading}
        >
          {isLoading ? "Processing..." : "Make payment"}
        </button>
        <AddCard isOpen={isCardOpen} closeModal={closeCardModal} />
        <UpdateModalTemplate
          action={status}
          isOpen={showModal}
          closeModal={() => setShowModal(false)}
        />
      </div>
      <div className="grid place-items-center">
        <div className="grid bg-alpha-blue gap-4 order-first lg:order-last rounded-2xl p-6">
          <div className="">
            <div className="flex">
              <p className="text-2xl leading-8">Plan</p>
            </div>
            <p className="leading-4">
              lorem ipsum sum sumtin lorem a sumtin ip sum{" "}
            </p>
          </div>
          <button
            className="font-medium text-darkBlue text-left"
            onClick={() => navigate(-1)}
          >
            Change Plan
          </button>
          <div className="border-y-[#C9D9F4] grid gap-2 py-2">
            {/* {plans[planId].map((plan, index) => (
              <div className="flex justify-between">
                <p>{plan.name}</p>
                <p>{plan.value}</p>
              </div>
            ))} */}
            <div className="flex justify-between">
              <p>{plan.name}</p>
              <p>{plan.price}</p>
            </div>
          </div>
          <div className="flex justify-between">
            <p className="text-2xl leading-8">Total</p>
            <p className="text-2xl leading-8">₦ {total}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Payment;
