import Introduction from "@/components/subscriptions/Introduction";
import Plans from "@/components/subscriptions/Plans";
import CustomPlans from "@/components/subscriptions/CustomPlans";
import AddOns from "@/components/subscriptions/AddOns";
import { NavIcon } from "@/components/SVGs";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { SUBSCRIPTIONS_PLAN_URL } from "@/routes/routes";

const SubscriptionsPage = () => {
  const navigate = useNavigate();
  const [addOnDetails, setAddOnDetails] = useState("");

  const handleAddonPlanSelection = (plan: string) => {
    setAddOnDetails(plan);
    console.log("Successfully received Addon: ", addOnDetails);
  };

  console.log("subscription page");

  return (
    <div className="grid gap-8 m-8">
      <div className="flex flex-start gap-2 text-sm items-center">
        <button className="text-textGrey" onClick={() => navigate(-1)}>
          Bookings
        </button>
        <NavIcon />
        <span>Subscriptions</span>
      </div>

      <div className="grid gap-8">
        <Introduction />
        <Plans
          onPlanSelect={(planId, frequency) => {
            navigate(SUBSCRIPTIONS_PLAN_URL(planId), {
              state: { frequency },
            });
          }}
        />
        <CustomPlans />
        <AddOns onAddonSelect={handleAddonPlanSelection} />
      </div>
    </div>
  );
};

export default SubscriptionsPage;
