import React, { useEffect } from "react";
import { SpaceValues } from "@/components/space-listing/schema";
import "./setupPage.scss";

import { CustomButton } from "@/components/customButton";
import { Col, Row, Space, Input, Select, Checkbox } from "antd";

import { CustomInputField } from "@/components/customInput";
import CustomTextArea from "@/components/customTextArea";

import { Radio, RadioGroup } from "@headlessui/react";
import { Field, FieldArray, useFormikContext } from "formik";
import { cn } from "@/lib/utils";
import SelectInput from "@/components/selectInputDropdown";
import TimePickerComponent from "@/components/customTimePicker";
import dayjs from "dayjs";

//OTHER POLICIES
const FORM_VALUES = [
  { label: "No alcohol allowed", value: "NO_ALCOHOL_ALLOWED" },
  { label: "No pets allowed", value: "NO_PETS_ALLOWED" },
  {
    label: "Adult filming not allowed",
    value: "ADULT_FILMING_NOT_ALLOWED",
  },
  {
    label: "Smoking not allowed",
    value: "SMOKING_NOT_ALLOWED",
  },
  {
    label: "External food not allowed",
    value: "EXTERNAL_FOOD_NOT_ALLOWED",
  },
  {
    label: "Is music allowed",
    value: "MUSIC_ALLOWED",
  },
];

const MIN_HOURS_OPTIONS = [
  { name: "1 hour", value: "1" },
  { name: "2 hours", value: "2" },
  { name: "3 hours", value: "3" },
];

const CURRENCY_OPTIONS = [
  { name: "NGN", value: "NGN", disabled: false },
  { name: "GBP", value: "GBP", disabled: true },
  { name: "USD", value: "USD", disabled: true },
];

const currencySymbolMap: { [x: string]: string } = {
  NGN: "₦",
  GBP: "£",
  USD: "$",
};

const RATES = [
  { label: "Hour", type: "hourly" },
  { label: "Daily", type: "daily" },
  { label: "Weekly", type: "weekly" },
  { label: "Monthly", type: "monthly" },
];

type OthersFormProps = {
  values: Partial<SpaceValues>;
  onChange: (event: React.ChangeEvent) => void;
};

export function OthersForm({ values, onChange }: OthersFormProps) {
  const { setFieldValue } = useFormikContext();

  const currency = values?.currency || "";
  const minHoursOption = values?.minHoursOption || "";
  const pricing = values?.pricing || [];
  const operationalHours = values?.operationalHours || [];

  return (
    <div className="page-one page-three">
      <div className="flex flex-col w-full my-4">
        <h2>Select all that apply to your space</h2>
        <Checkbox.Group
          style={{
            width: "100%",
            display: "flex",
            flexWrap: "wrap",
            paddingTop: "1.5%",
          }}
          value={values.otherPolicies}
          onChange={(checkedValues) => {
            setFieldValue("otherPolicies", checkedValues);
          }}
        >
          <Row gutter={[8, 8]} wrap>
            {FORM_VALUES.map((item) => (
              <Col span={24}>
                <Checkbox value={item.value}>{item.label}</Checkbox>
              </Col>
            ))}
          </Row>
        </Checkbox.Group>
      </div>

      <div>
        <h2>Set a price for your space</h2>
        <p className="text-lg text-primaryText mt-3">
          Choose a unique space titles to get more visibility to your space.
        </p>
      </div>
      <div className="w-[320px]">
        <SelectInput
          name="minHoursOption"
          label="Minimum Hour"
          options={MIN_HOURS_OPTIONS}
          value={minHoursOption}
          onChange={onChange}
        />
      </div>
      <div className="w-[320px]">
        <SelectInput
          name="currency"
          label="Currency"
          options={CURRENCY_OPTIONS}
          value={currency}
          onChange={onChange}
        />
      </div>
      <FieldArray
        name="pricing"
        render={(arrayHelpers) => {
          return (
            <div>
              {values.pricing && values.pricing.length > 0 ? (
                values.pricing.map((_, index) => (
                  <div key={index}>
                    <div className="container-wrapper mb-4 relative">
                      <div className="content-wrapper">
                        {RATES.map((rate) => (
                          <div className="page-input-field" key={rate.type}>
                            <label>{rate.label}</label>
                            <Input
                              size="large"
                              className="page-input-filed-input-wrapper"
                              placeholder=""
                              name={`pricing.${index}.${rate.type}`}
                              //@ts-ignore
                              value={values.pricing[index][rate.type] ?? ""}
                              onChange={(e) => {
                                const value = parseInt(e.target.value);

                                if (value >= 0 || e.target.value === "") {
                                  setFieldValue(
                                    `pricing.${index}.${rate.type}`,
                                    value
                                  );
                                }
                              }}
                              suffix={
                                <span>
                                  {currencySymbolMap[currency] ??
                                    currencySymbolMap["NGN"]}
                                </span>
                              }
                              type="number"
                              onWheel={(e) => e.preventDefault()}
                              onScroll={(e) => e.preventDefault()}
                            />
                          </div>
                        ))}
                      </div>

                      <div className="date-input-wrapper">
                        {[
                          "sunday",
                          "monday",
                          "tuesday",
                          "wednesday",
                          "thursday",
                          "friday",
                          "saturday",
                        ].map((i, idx) => (
                          <div key={idx}>
                            <input
                              name={`pricing.${index}.days.${index}`}
                              type="checkbox"
                              checked={pricing[index].days.includes(i)}
                              onChange={(e) => {
                                if (e.target.checked) {
                                  if (
                                    !pricing
                                      ?.flatMap((item) => item.days)
                                      .includes(i)
                                  )
                                    setFieldValue(`pricing.${index}.days`, [
                                      ...pricing[index].days,
                                      i,
                                    ]);
                                } else {
                                  setFieldValue(`pricing.${index}.days`, [
                                    ...pricing[index].days.filter(
                                      (day) => day !== i
                                    ),
                                  ]);
                                }
                              }}
                              className={cn(
                                !pricing[index].days.includes(i) &&
                                  pricing
                                    ?.flatMap((item) => item.days)
                                    .includes(i) &&
                                  "cursor-not-allowed"
                              )}
                              title={i}
                            />
                            <span
                              className={cn(
                                "capitalize",
                                !pricing[index].days.includes(i) &&
                                  pricing
                                    ?.flatMap((item) => item.days)
                                    .includes(i)
                                  ? "line-through"
                                  : ""
                              )}
                            >
                              {i.slice(0, 3)}
                            </span>
                          </div>
                        ))}
                      </div>

                      <button
                        type="button"
                        className="absolute top-3 right-4"
                        onClick={() => arrayHelpers.remove(index)}
                      >
                        x
                      </button>
                    </div>
                  </div>
                ))
              ) : (
                <button
                  type="button"
                  onClick={() =>
                    arrayHelpers.push({
                      hourly: null,
                      daily: null,
                      monthly: null,
                      weekly: null,
                      days: [],
                    })
                  }
                >
                  {/* show this when user has removed all friends from the list */}
                  Add init
                </button>
              )}
              <div className="flex justify-start">
                {values.pricing &&
                  values?.pricing?.flatMap((item) => item.days).length < 7 && (
                    <button
                      type="button"
                      onClick={() => {
                        arrayHelpers.insert(
                          Number(values.pricing?.length ?? 0) + 1,
                          {
                            hourly: null,
                            daily: null,
                            monthly: null,
                            weekly: null,
                            days: [],
                          }
                        );
                      }}
                      className="text-lg text-primary font-medium"
                    >
                      Add more +
                    </button>
                  )}
              </div>
            </div>
          );
        }}
      />

      <Row className="mt-6" gutter={[45, 24]}>
        <Col md={24} xs={24}>
          <h2>Add space operating hours</h2>
          <p className="text-lg mt-4">
            Choose your open days and hours for your space.
          </p>
          <FieldArray
            name="operationalHours"
            render={(arrayHelpers) => {
              return (
                <>
                  <div className="page-input-field">
                    {values.operationalHours &&
                    values.operationalHours.length > 0
                      ? values.operationalHours.map((item, index) => (
                          <div className="date-input-field relative !mt-4">
                            <div className="page-input-field-double ">
                              <Row gutter={[45, 24]}>
                                <Col md={12} lg={12} xs={24}>
                                  <TimePickerComponent
                                    placeholder="16:00"
                                    selectedTime={dayjs(item.open, "HH:mm")}
                                    size="large"
                                    onTimeChange={(instance) => {
                                      setFieldValue(
                                        `operationalHours.${index}.open`,
                                        instance?.hour() +
                                          ":" +
                                          instance?.minute()
                                      );
                                    }}
                                    label="Open At"
                                    className="time-picker-wrapper page-input-filed-input-wrapper"
                                  />
                                </Col>

                                <Col md={12} lg={12} xs={24}>
                                  <TimePickerComponent
                                    placeholder="Select Time"
                                    selectedTime={dayjs(item.close, "HH:mm")}
                                    size="large"
                                    onTimeChange={(instance) => {
                                      setFieldValue(
                                        `operationalHours.${index}.close`,
                                        instance?.hour() +
                                          ":" +
                                          instance?.minute()
                                      );
                                    }}
                                    label="Close At"
                                    className="time-picker-wrapper page-input-filed-input-wrapper"
                                  />
                                </Col>
                              </Row>
                            </div>
                            <div className="date-input-wrapper">
                              {[
                                "sunday",
                                "monday",
                                "tuesday",
                                "wednesday",
                                "thursday",
                                "friday",
                                "saturday",
                              ].map((i, idx) => (
                                <div key={idx}>
                                  <input
                                    name={`operationalHours.${index}.days.${index}`}
                                    type="checkbox"
                                    checked={operationalHours[
                                      index
                                    ].days.includes(i)}
                                    className={
                                      cn(
                                        !operationalHours[index].days.includes(
                                          i
                                        ) &&
                                          operationalHours
                                            ?.flatMap((item) => item.days)
                                            .includes(i)
                                      )
                                        ? "cursor-not-allowed"
                                        : "cursor-pointer"
                                    }
                                    onChange={(e) => {
                                      if (e.target.checked) {
                                        if (
                                          !operationalHours
                                            ?.flatMap((item) => item.days)
                                            .includes(i)
                                        )
                                          setFieldValue(
                                            `operationalHours.${index}.days`,
                                            [...operationalHours[index].days, i]
                                          );
                                      } else {
                                        setFieldValue(
                                          `operationalHours.${index}.days`,
                                          [
                                            ...operationalHours[
                                              index
                                            ].days.filter((day) => day !== i),
                                          ]
                                        );
                                      }
                                    }}
                                    title={i}
                                  />
                                  <span
                                    className={cn(
                                      "capitalize",
                                      !operationalHours[index].days.includes(
                                        i
                                      ) &&
                                        operationalHours
                                          ?.flatMap((item) => item.days)
                                          .includes(i)
                                        ? "line-through"
                                        : ""
                                    )}
                                  >
                                    {i.slice(0, 3)}
                                  </span>
                                </div>
                              ))}
                            </div>
                            <button
                              type="button"
                              className="absolute top-3 right-4"
                              onClick={() => arrayHelpers.remove(index)}
                            >
                              x
                            </button>
                          </div>
                        ))
                      : null}
                  </div>

                  {values.operationalHours &&
                    values?.operationalHours?.length < 7 && (
                      <button
                        type="button"
                        onClick={() => {
                          arrayHelpers.insert(
                            Number(values.operationalHours?.length ?? 0) + 1,
                            {
                              open: "00:00",
                              close: "00:00",
                              days: [],
                            }
                          );
                        }}
                        className="text-lg text-primary font-medium"
                      >
                        Add more +
                      </button>
                    )}
                </>
              );
            }}
          />
        </Col>
      </Row>
    </div>
  );
}
