import React, { useState, useEffect, useMemo } from "react";
import { Input } from "antd";
import NodeGeocoder from "node-geocoder";
import axios from "axios";
import { debounce } from "lodash";
import { FormikBag, FormikHelpers, FormikProps } from "formik";
import { IWorkSpaceForm } from "../../interfaces/forms";

interface Location {
  description: string;
  latitude: number;
  longitude: number;
}

const API_KEY = import.meta.env.VITE_MAP_KEY; // Replace with your geocoding API key
const BASE_URL = "https://maps.googleapis.com/maps/api/geocode/json";

export const useGeoTag = ({
  formik,
}: {
  formik: FormikProps<IWorkSpaceForm> | undefined;
}) => {
  const [suggestions, setSuggestions] = useState<Location[]>([]);
  const [options, setOptions] = useState<{ value: string }[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [address, setAddress] = useState("");
  const [latLOng, setLatLong] = useState({ lat: "", lng: "" });
  const [searchParams, setSearchParams] = useState("");

  const getLocations = async (params: string) => {
    setIsLoading(true);
    try {
      const suggestions = await fetchLocationSuggestions(params);
      setSuggestions(suggestions);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching location suggestions:", error);
    }
  };

  const fetchLocationSuggestions = async (
    query: string,
  ): Promise<Location[]> => {
    const response = await axios.get(BASE_URL, {
      params: {
        address: query,
        key: API_KEY,
      },
    });
    return response.data.results.map((result: any) => ({
      description: result.formatted_address,
      latitude: result.geometry.location.lat,
      longitude: result.geometry.location.lng,
    }));
  };

  const handleLocationSelect = (location: Location) => {
    setAddress(location.description);
    // setLatLong({lat: location.latitude.toString(), lng: location.longitude.toString()});
    const locationDetails = {
      lat: location.latitude.toString(),
      lng: location.longitude.toString(),
    };
    if (formik) {
      formik.setFieldValue("map", locationDetails);
    }
  };

  const debounceSearch = useMemo(
    () =>
      debounce((value: string) => {
        setSearchParams(value);
      }, 1200),
    [],
  );

  const handleSearch = async (query: string) => {
    setAddress(query);
    // setLatLong({
    //     lat: "",
    //     lng: ""
    // })
  };

  const handleSelect = (value: string) => {
    const selectedLocation = suggestions.find(
      (location) => location.description === value,
    );
    if (selectedLocation) {
      handleLocationSelect(selectedLocation);
    }
  };

  useEffect(() => {
    if (searchParams) {
      getLocations(searchParams);
    }
  }, [searchParams]);

  useEffect(() => {
    debounceSearch(address);
  }, [address]);

  useEffect(() => {
    setOptions(
      suggestions.map((suggestion) => ({ value: suggestion.description })),
    );
  }, [suggestions]);

  return {
    fetchLocationSuggestions,
    handleSearch,
    handleSelect,
    isLoading,
    suggestions,
    address,
    options,
    latLOng,
  };
};
