import React from "react";
import { CloseIcon } from "@/components/SVGs";
import ModalWrapper from "@/components/subscriptions/ModalWrapper";
import { Form, Input, Checkbox } from "antd";
import { CustomButton } from "@/components/customButton";

interface Prop {
  isOpen: boolean;
  closeModal: () => void;
}

interface FormField {
  name: string;
  label: string | JSX.Element;
  rules?: { required?: boolean; message: string }[];
  placeholder?: string;
  type?: string;
  initialValue?: any;
}

const CallBackPopup: React.FC<Prop> = ({ isOpen, closeModal }) => {
  const [form] = Form.useForm();

  const formFields: FormField[] = [
    {
      name: "fullName",
      label: <span>Full Name</span>,
      rules: [{ required: true, message: "Please input your full name!" }],
      placeholder: "Enter your Full Name",
    },
    {
      name: "email",
      label: <span>Email Address</span>,
      rules: [{ required: true, message: "Please input your email address!" }],
      placeholder: "Enter your Email Address",
      type: "email",
    },
    {
      name: "customPlan",
      label: <span>I would like to discuss a custom plan for my space</span>,
      initialValue: false,
    },
  ];

  const onFinish = async () => {
    try {
      const values = await form.validateFields();
      console.log("Form values:", values);
      console.log("Done");
      closeModal();
    } catch (error) {
      console.error("Validation failed:", error);
    }
  };

  return (
    <ModalWrapper isOpen={isOpen}>
      <div className="grid bg-white shadow-xl py-6 px-8 rounded-2xl lg:w-3/5">
        <button
          title="Close icon"
          className="flex justify-end"
          onClick={closeModal}
        >
          <CloseIcon />
        </button>
        <Form
          form={form}
          onFinish={onFinish}
          layout="vertical"
          size="large"
          autoComplete="off"
          className=""
        >
          {formFields.map((field) => (
            <Form.Item
              key={field.name}
              name={field.name}
              label={field.name === "customPlan" ? "" : field.label}
              initialValue={field.initialValue}
              rules={field.rules}
            >
              {field.name === "customPlan" ? (
                <Checkbox>{field.label}</Checkbox>
              ) : (
                <Input
                  type={field.type || "text"}
                  placeholder={field.placeholder}
                />
              )}
            </Form.Item>
          ))}
          <CustomButton
            disabled={
              !form.isFieldsTouched(true) ||
              form.getFieldsError().filter(({ errors }) => errors.length > 0)
                .length > 0
            }
            type="primary"
            htmlType="submit"
            className="bg-darkBlue text-white"
          >
            Submit Message
          </CustomButton>
        </Form>
      </div>
    </ModalWrapper>
  );
};

export default CallBackPopup;
