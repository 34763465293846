interface InputSchedule {
  open: string;
  close: string;
  days: string[];
}

interface DaySchedule {
  open_time: string;
  close_time: string;
}

interface Schedule {
  [day: string]: DaySchedule;
}

export function transformScheduleArray(inputArray: InputSchedule[]): Schedule {
  const daysOfWeek: Schedule = {
    sunday: { open_time: "closed", close_time: "closed" },
    monday: { open_time: "closed", close_time: "closed" },
    tuesday: { open_time: "closed", close_time: "closed" },
    wednesday: { open_time: "closed", close_time: "closed" },
    thursday: { open_time: "closed", close_time: "closed" },
    friday: { open_time: "closed", close_time: "closed" },
    saturday: { open_time: "closed", close_time: "closed" },
  };

  inputArray.forEach(({ open, close, days }) => {
    days.forEach((day) => {
      if (daysOfWeek[day]) {
        daysOfWeek[day] = {
          open_time: open,
          close_time: close,
        };
      }
    });
  });

  return daysOfWeek;
}

export function reverseTransformSchedule(schedule: Schedule): InputSchedule[] {
  const result: InputSchedule[] = [];
  const visitedSchedules: { [key: string]: boolean } = {};

  // Iterate over the days of the week
  Object.entries(schedule).forEach(([day, { open_time, close_time }]) => {
    const key = `${open_time}-${close_time}`;

    // If this time pair hasn't been processed yet
    if (!visitedSchedules[key]) {
      // Find all days that share the same open and close times
      const matchingDays = Object.entries(schedule)
        .filter(
          ([, { open_time: open, close_time: close }]) =>
            open === open_time && close === close_time
        )
        .map(([day]) => day);

      // Mark the schedule as visited
      visitedSchedules[key] = true;

      // Add a new InputSchedule object to the result
      result.push({
        open: open_time,
        close: close_time,
        days: matchingDays,
      });
    }
  });

  return result;
}

interface InputPricing {
  hourly: number;
  daily: number;
  weekly: number | null;
  monthly: number | null;
  days: string[];
}

interface OutputPricing {
  hourly: number;
  daily: number;
  weekly: number | null;
  monthly: number | null;
  day: string;
}

export function transformPricingArray(
  inputArray: InputPricing[]
): OutputPricing[] {
  // Use a counter to track the number of results
  let result: OutputPricing[] = [];

  for (const { hourly, daily, weekly, monthly, days } of inputArray) {
    const transformedItems = days.map((day) => ({
      hourly: hourly ? parseInt(String(hourly), 10) : 0,
      daily: daily ? parseInt(String(daily), 10) : 0,
      weekly: weekly ? parseInt(String(weekly), 10) : null,
      monthly: monthly ? parseInt(String(monthly), 10) : null,
      day,
    }));

    // Add transformed items but ensure the total length doesn't exceed 7
    const remainingSlots = 7 - result.length;
    if (remainingSlots <= 0) break; // Stop if we've reached 7 objects

    result = result.concat(transformedItems.slice(0, remainingSlots));
  }

  return result;
}
