import React from "react";
import { CloseIcon, SecurityIcon } from "@/components/SVGs";
import { Form, Input, Checkbox } from "antd";
import { CustomButton } from "@/components/customButton";
import ModalWrapper from "@/components/subscriptions/ModalWrapper";

interface Prop {
  isOpen: boolean;
  closeModal: () => void;
}

interface FormField {
  name: string;
  label: string | JSX.Element;
  rules?: { required?: boolean; message: string }[];
  placeholder?: string;
  type?: string;
  initialValue?: any;
}

const AddCardPopup: React.FC<Prop> = ({ isOpen, closeModal }) => {
  const [form] = Form.useForm();

  const formFields: FormField[] = [
    {
      name: "card_number",
      label: <span>Card Number</span>,
      rules: [{ required: true, message: "Please enter a valid number" }],
      placeholder: "1234 5678 9101 1121",
    },
    {
      name: "exp_date",
      label: <span>Expiration Date</span>,
      rules: [{ required: true, message: "Please enter a valid number" }],
      placeholder: "MM/YY",
      type: "number",
    },
    {
      name: "cvv",
      label: <span> CVV</span>,
      rules: [{ required: true, message: "Please enter a valid number" }],
      placeholder: "CVV",
      type: "number",
    },
  ];

  const onFinish = async () => {
    try {
      const values = await form.validateFields();
      console.log("Form values:", values);
      console.log("Done");
      closeModal();
    } catch (error) {
      console.error("Validation failed:", error);
    }
  };

  return (
    <ModalWrapper isOpen={isOpen}>
      <div className="grid bg-white shadow-xl py-6 px-8 rounded-2xl gap-4">
        <button
          title="Close icon"
          className="flex justify-end"
          onClick={closeModal}
        >
          <CloseIcon />
        </button>
        <Form
          form={form}
          onFinish={onFinish}
          layout="vertical"
          size="large"
          autoComplete="off"
          className="grid grid-cols-1 md:grid-cols-2 gap-2"
        >
          {formFields.map((field, index) => (
            <Form.Item
              key={field.name}
              name={field.name}
              label={field.name === "customPlan" ? "" : field.label}
              initialValue={field.initialValue}
              rules={field.rules}
              className={`${index === 0 ? "md:col-span-2" : ""}`}
            >
              {field.name === "customPlan" ? (
                <Checkbox>{field.label}</Checkbox>
              ) : (
                <Input
                  type={field.type || "text"}
                  placeholder={field.placeholder}
                />
              )}
            </Form.Item>
          ))}

          <CustomButton
            disabled={
              !form.isFieldsTouched(true) ||
              form.getFieldsError().filter(({ errors }) => errors.length > 0)
                .length > 0
            }
            type="primary"
            htmlType="submit"
            className="bg-darkBlue text-white"
          >
            Continue
          </CustomButton>
        </Form>

        <p className="flex items-center gap-2">
          <SecurityIcon /> Please note your banking information are not stored
          on CO-SPIRE
        </p>
      </div>
    </ModalWrapper>
  );
};

export default AddCardPopup;
