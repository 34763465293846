import { Button, DatePicker, Divider } from "antd";
import styles from "./index.module.scss";
import CustomIcons from "@/components/icons";
import { Form, useNavigate, useParams } from "react-router-dom";
import "@splidejs/react-splide/css";
import slideOne from "../../../../assets/img/list1.webp";
import slideTwo from "../../../../assets/img/list4.webp";
import slideThree from "../../../../assets/img/list2.webp";
import slideFour from "../../../../assets/img/list3.webp";
import Splider from "@/components/splide/splide";
import { useEffect, useState } from "react";
// import ConfirmationModal from "./Modals/confirmationModal";
import CustomTag from "@/components/customTag";
import useBooking from "../useMessages";
import { CustomButton } from "@/components/customButton";
// import CancelBookingModal from "./Modals/cancelBookingModal";
import dayjs, { Dayjs } from "dayjs";
import { LWorkspace } from "@/types";
import { getWorkSpace } from "@/services/networkRequests/workspaceService";
import LocalizedFormat from "dayjs/plugin/localizedFormat";
import { listBooking } from "@/services/networkRequests/bookingService";
import { toast } from "react-toastify";
import { set } from "lodash";
import TimePickerComponent from "@/components/customTimePicker";
import CustomerDatePicker from "@/components/confirmDatePicker/customerDatePicker";
// import { Value } from "react-multi-date-picker";
import Star from "@/assets/svgs/Vector.svg";
import MultiDate, { Value } from "react-multi-date-picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";

dayjs.extend(LocalizedFormat);
const RentersMessages = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const { getColor } = useBooking();

  const [timeStamp, setTimeStamp] = useState<Dayjs[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
  const [currentData, setCurrentData] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [workspace, setWorkspace] = useState<LWorkspace | null>(null);
  const [selectedDates, setSelectedDates] = useState<Dayjs[]>([]);

  //@ts-ignore
  const selectedDate: Value = selectedDates.map((dates) =>
    dates.format("YYYY-DD-MM"),
  );

  const handeDateChange = (dates: Dayjs[]) => {
    setSelectedDates(dates);
  };

  console.log("the time changes", timeStamp);
  /**
   *step one : get array of date
   *step two :  disable other date on the calender expet the date that is in the array using react multi date picker
   */

  useEffect(() => {
    async function getData() {
      setLoading(true);
      if (id) {
        try {
          const response = await listBooking(id);
          const { data } = response.data;
          console.log(data, "data");
          setCurrentData(data);
          setTimeStamp(data.time_slot);
        } catch (err) {
          console.log(err);
        } finally {
          setLoading(false);
        }
      } else {
        toast.error("Please login to continue");
      }
    }
    getData();
    // console.log(currentData, 'currentData');
  }, [id]);
  // useEffect(() => {
  //     setCurrentData(data?.filter((value: any) => value.id === id));
  // }, [id, data])
  const availabilityData = [
    "11:00AM",
    "11:00PM",
    // Add more time slots here
  ];
  const slides: string[] =
    workspace && workspace.photos
      ? workspace.photos.map((photo: string) => photo)
      : ["slideOne", "slideTwo", "slideThree", "slideFour"];
  const space = { title: "slides" };

  interface ctaButton {
    title: "inprogress" | "Ongoing" | "pending check" | "Reserved";
  }
  interface Icomp {
    (arg: ctaButton): JSX.Element | null;
  }

  console.log("d current data", currentData);

  const CtaButton: Icomp = ({ title }: ctaButton) => {
    const components: Record<
      | "Payment"
      //  | "Cancel"
      | "pending check",
      JSX.Element
    > = {
      "pending check": (
        <div>
          <CustomButton
            children={"Proceed to Payment"}
            className={styles.paymentBtn}
            onClick={() => setIsModalOpen(true)}
            disabled={true}
          />
          {/* <CustomButton
            children={"Cancel Booking"}
            className={styles.paymementBtn}
            extraStyles={{ marginTop: "10px" }}
            onClick={() => setIsCancelModalOpen(true)}
          /> */}
        </div>
      ),
      Payment: (
        <div>
          {showProceedToPayment ? (
            <CustomButton
              children={"Proceed to Payment"}
              className={styles.paymementBtn}
              onClick={() => setIsModalOpen(true)}
            />
          ) : (
            <div>
              <div className={styles.spaceInfo}>
                {/* <TimePickerComponent
                            placeholder='Pick a free time'
                            // @ts-ignore
                            selectedTime={timeStamp} // You can pass the selected time if needed
                            onTimeChange={(newTime) => {
                                setTimeStamp(newTime)// Handle the selected time change here
                            }}
                            availability={availabilityData}
                            className={styles.paymementBtn}
                             /> */}
              </div>

              <DatePicker
                placeholder="Pick a free date"
                onChange={handleStartDateChange}
                format="YYYY/MM/DD"
                disabledDate={disabledDate}
                className={styles.paymementBtn}
              />
              {/* <CustomerDatePicker
                value={selectedDate}
                onChange={handeDateChange}
                // showDays={''}
                
                // disabled={'dateDisable'}
           
              /> */}
            </div>
          )}

          {/* <CustomButton
            children={"Cancel Booking"}
            className={styles.paymementBtn}
            extraStyles={{ marginTop: "10px" }}
            onClick={() => setIsCancelModalOpen(true)}
          /> */}
        </div>
      ),
      // Cancel: (
      //   <CustomButton
      //     children={"Cancel Booking"}
      //     className={styles.paymementBtn}
      //     onClick={() => setIsCancelModalOpen(true)}
      //   />
      // ),
    };

    // if (title === "Reserved" || title === "Ongoing") {
    //   return components.Cancel;
    // }
    if (title === "inprogress") {
      return components["Payment"];
    }
    if (title === "pending check") {
      return components["pending check"];
    }
    return null;
  };
  const formatDate = (created_ts: Date) => {
    const formattedDate = dayjs(created_ts).format("dddd, Do MMMM, YYYY");
    return formattedDate;
  };
  const minDate =
    currentData?.status === "inprogress" ||
    // currentData?.status === "pending check"
    (currentData?.status === "Ongoing" && currentData?.time_slot.length > 0)
      ? currentData?.time_slot.map((slot: any) =>
          dayjs(slot.day).format("YYYY-MM-DD"),
        )
      : null;
  console.log(minDate, "min datessssssssssssss");
  console.log(currentData?.time_slot, "min datessssssssssssss");
  // const minDate = [currentData?.start_date, currentData?.end_date].map((date) => dayjs(date).format("YYYY-MM-DD"));
  // console.log(minDate, 'minDate');
  const disabledDate = (current: Dayjs | null) => {
    if (!current || !minDate) {
      return true;
    }
    const currentFormatted = current.format("YYYY-MM-DD");
    return !minDate.includes(currentFormatted);
  };

  const booked_on = formatDate(currentData?.created_ts);
  const start_date = formatDate(currentData?.start_date);
  const amountWithSymbol = currentData?.amount;
  const amountWithoutSymbol = parseFloat(amountWithSymbol?.replace("$", ""));
  const workspaceId = currentData?.workspace_id;

  const fetchWorkspace = async () => {
    setLoading(true);

    await getWorkSpace(workspaceId)
      .then((res) => {
        setWorkspace(res?.data?.data);
        console.log("res", res?.data?.data);
      })
      .catch((error) => {
        console.log("error", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    fetchWorkspace();
  }, [workspaceId]);
  const [showProceedToPayment, setShowProceedToPayment] = useState(false);

  function calculateAverage(numbers: number[]) {
    if (numbers.length === 0) {
      return 0;
    }

    const sum = numbers.reduce((total, num) => total + num, 0);
    const average = Math.floor(sum / numbers.length);

    return average;
  }

  const handleStartDateChange = (date: Dayjs | null) => {
    if (date && !disabledDate(date)) {
      // If a valid startDate is selected or there's no min_date
      setShowProceedToPayment(true);
    } else {
      setShowProceedToPayment(false);
    }
  };

  return (
    <div>
      {/* <ConfirmationModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
      />
      <CancelBookingModal
        isModalOpen={isCancelModalOpen}
        setIsModalOpen={setIsCancelModalOpen}
        bookingId={currentData?.id}
      /> */}
      <div className={styles.heading}>
        <div>
          <div className={styles.inlineRow}>
            <span onClick={() => navigate(-1)}>
              <CustomIcons name="backIcon" />
            </span>
            <h2 className={styles.title}>Booking Summary</h2>
          </div>
        </div>
      </div>
      <Divider orientation="left"></Divider>

      <div className={styles.bookingMainWrapper}>
        <div className={styles.BookingMainDetails}>
          <Splider
            thumbnails={slides}
            space={space}
            className={styles.spliderContainer}
          />
          <div className={styles.spaceInfo}>
            <span>
              <CustomTag
                color={getColor(currentData?.status)}
                text={currentData?.status}
              />
            </span>
            <div className={styles.spaceInfoSub}>
              <div>
                <p className={styles.spaceName}>{currentData?.company_info}</p>
                <span className={styles.rating}>
                  {workspace?.verified_reviews?.length > 0 && (
                    <div>
                      <CustomIcons
                        name="star"
                        svgProps={{
                          width: "25px",
                          height: "25px",
                        }}
                      />{" "}
                      <p>
                        {calculateAverage(
                          workspace?.verified_reviews?.map((item: any) => {
                            return item.review_rating;
                          }),
                        )}
                      </p>
                    </div>
                  )}

                  {/* <p>5.0</p> */}
                </span>
                <span className={styles.verified}>
                  {workspace?.verified_reviews?.length > 0 && (
                    <div>
                      <CustomIcons name="verifiedIcon" />
                      <p>
                        {workspace?.verified_reviews?.length} Verified Reviews
                      </p>
                    </div>
                  )}
                </span>
              </div>
              <p>Booked on {booked_on}</p>
            </div>
          </div>
          <div></div>
        </div>
        <div className={styles.cta}>
          <CtaButton title={currentData?.status} />
        </div>
      </div>

      <Divider orientation="left"></Divider>

      <div className={styles.bookingInformation}>
        <p className={styles.infoTitle}>Booking Information</p>
        <div className={styles.infoItems}>
          <div className={styles.infoItem}>
            <small className={styles.infoItemTitle}>START DATE</small>
            <p className={styles.infoItemDetail}>{start_date}</p>
          </div>
          <div className={styles.infoItem}>
            <small className={styles.infoItemTitle}>NUMBER OF PEOPLE</small>
            <p className={styles.infoItemDetail}>{currentData?.no_of_seats}</p>
          </div>
          <div className={styles.infoItem}>
            <small className={styles.infoItemTitle}>TYPE OF SPACE</small>
            <p className={styles.infoItemDetail}>{currentData?.service_type}</p>
          </div>
          <div className={styles.infoItem}>
            <small className={styles.infoItemTitle}>PHONE NUMBER</small>
            <p className={styles.infoItemDetail}>{currentData?.phone_number}</p>
          </div>
        </div>

        <Divider orientation="left"></Divider>

        <p className={styles.infoTitle}>Payment Information</p>
        <div className={styles.infoItems}>
          <div className={styles.infoItem}>
            <small className={styles.infoItemTitle}>AMOUNT</small>
            <p className={styles.infoItemDetail}>{amountWithoutSymbol} NGN</p>
          </div>
          {/* <div className={styles.infoItem}>
            <small className={styles.infoItemTitle}>PAYMENT DATE</small>
            <p className={styles.infoItemDetail}>Monday, 1st May, 2023</p>
          </div>
          <div className={styles.infoItem}>
            <small className={styles.infoItemTitle}>TRANSACTION ID</small>
            <p className={styles.infoItemDetail}>123436</p>
                  </div> */}
          <div className={styles.infoItem}>
            <small className={styles.infoItemTitle}>TRANSACTION STATUS</small>
            <p className={styles.infoItemDetail}>
              {currentData?.time_slot && currentData?.time_slot.length > 0
                ? "On going"
                : "Pending"}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RentersMessages;
