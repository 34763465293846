import { useEffect, useState } from "react";
import "antd/dist/reset.css";
import Router from "./routes";
import "@/assets/styles/index.scss";
import "@splidejs/react-splide/css";
import WhatsApp from "./components/whatsApp/whatsApp";
import BrevoChatWidget from "./components/brevoChat/BrevoChatWidget";
import { ModalProvider } from "./components/modal-view/context";
import { QueryClient, QueryClientProvider } from "react-query";
import ModalsContainer from "./components/modal-view/container";
import { AuthProvider } from "./context/auth-context";
import posthog from "posthog-js";

import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://4d69727e81aadd4aaebbbc43fbea9874@o4507972600594432.ingest.de.sentry.io/4507972645290064",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

posthog.init("phc_FnGp3bcacmBeFnqjlvoqgRkSY7RhrNuMRQJ7bUGpKks", {
  api_host: "https://us.i.posthog.com",
  person_profiles: "always", // or 'always' to create profiles for anonymous users as well
});

// posthog.capture('my event', { property: 'value' })

//

function App() {
  const [queryClient] = useState(() => new QueryClient());

  useEffect(() => {
    if (document && document.querySelector("html")) {
      document.querySelector("html")!.style.scrollBehavior = "auto";
      window.scroll({ top: 0 });
      document.querySelector("html")!.style.scrollBehavior = "";
    }
  }, [window.location.pathname]); // triggered on route change

  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <ModalProvider>
          <Router />
          {/* < WhatsApp/> */}
          {/* <BrevoChatWidget/> */}
          <ModalsContainer />
        </ModalProvider>
      </AuthProvider>
    </QueryClientProvider>
  );
}

export default App;
