import { signIn, auth, handleFirebaseError } from "../../firebase/utils";
import { RequestResponse } from "../../types";
import {
  CREATE_USER,
  CREATE_WORKSPACE,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
} from "../apiEndpoints";
import { axiosPrivate } from "../axiosPrivate";
import { toast } from "react-toastify";
import { handleError } from "./error";
import { API_ENDPOINTS } from "@/data/client/api-endpoints";

const registerUser = async (payload: any) => {
  const endpoint = `${import.meta.env.VITE_BASE_URL + CREATE_USER}`;
  const response: RequestResponse = await axiosPrivate.post(endpoint, payload);
  return response;
};

const login = async (payload: { email: string; password: string }) => {
  const { email, password } = payload;
  return signIn(auth, email, password)
    .then((res) => res.user)
    .catch((err) => {
      handleFirebaseError(err);
      throw err;
    });
};

const forgotPassword = async (payload: any) => {
  try {
    const endpoint = `${import.meta.env.VITE_BASE_URL + FORGOT_PASSWORD}`;
    const response: RequestResponse = await axiosPrivate.post(
      endpoint,
      payload,
    );
    toast.success("Please check your email to reset password");
    return response.data;
  } catch (error: any) {
    return handleError(error);
  }
};

const resetPassword = async (payload: any) => {
  try {
    const endpoint = `${import.meta.env.VITE_BASE_URL + RESET_PASSWORD}`;
    const response: RequestResponse = await axiosPrivate.post(
      endpoint,
      payload,
    );
    toast.success("Password changed successfully");
    return response.data;
  } catch (error: any) {
    return handleError(error);
  }
};

export { registerUser, login, forgotPassword, resetPassword };
