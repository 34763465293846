import { Checkbox } from "antd";
import React, { useEffect, useState } from "react";
import { SpaceValues } from "@/components/space-listing/schema";
import countryData from "@/assets/data/countries+states+cities.json";
import { Field, useFormikContext } from "formik";
import SearchableSelect from "@/components/searchableSelect";
import AddressAutocomplete from "@/components/address-autocomplete";
import { cn } from "@/lib/utils";
import { useMyLocationsWorkspacesQuery } from "@/data/workspace";
import { WorkspaceLocation } from "@/types/workspace";

type Obj = {
  name: string;
};

const convertedCountryData = Array.prototype.slice.call(countryData);

export function AddressForm({ values }: { values: Partial<SpaceValues> }) {
  const { setFieldValue } = useFormikContext();
  const [useExistingLocation, setUseExistingLocation] = useState(false);

  const country = values?.countryRegion || "";
  const state = values?.state || "";

  const handleAddressChange = (address: string, zipCode?: string) => {
    setFieldValue("streetAddress", address);
    if (zipCode) {
      setFieldValue("zipCode", zipCode);
    }
  };

  const { locations } = useMyLocationsWorkspacesQuery({});

  const countryOptions = convertedCountryData.map(
    (country: Obj, index: number) => ({
      value: index,
      label: country.name,
    })
  );

  const locationOptions = (locations as WorkspaceLocation[]).map(
    (location: WorkspaceLocation) => ({
      value: location.id,
      label: location.formatted_address,
    })
  );

  const stateOptions =
    values.countryRegion !== ""
      ? convertedCountryData[Number(country)].states.map(
          (state: Obj, index: number) => ({
            value: index,
            label: state.name,
          })
        )
      : [];

  const cityOptions =
    values?.countryRegion !== "" && values?.state !== ""
      ? convertedCountryData[Number(country)].states[Number(state)].cities.map(
          (city: Obj, index: number) => ({
            value: index,
            label: city.name,
          })
        )
      : [];

  const handleLocationSelect = (selectedLocationId: string) => {
    const selectedLocation = (locations as WorkspaceLocation[]).find(
      (location: WorkspaceLocation) => location.id === selectedLocationId
    );

    if (selectedLocation) {
      setFieldValue("countryRegion", selectedLocation.address.country);
      setFieldValue("state", selectedLocation.address.state);
      setFieldValue("city", selectedLocation.address.city);
      setFieldValue("streetAddress", selectedLocation.address.street_address);
      setFieldValue("zipCode", selectedLocation.address.zip_code);
    }
  };

  useEffect(() => {
    if (values?.location) handleLocationSelect(values?.location);
  }, [values, setFieldValue]);

  return (
    <div className="page-one page-two">
      <div className={cn("heading-div", "flex !justify-between")}>
        <div>
          <h2>Tell us your location</h2>
          <p className="sub-title">Let us know where your space is situated</p>
        </div>
      </div>

      {/* Show checkbox to use existing location */}
      <Checkbox
        checked={useExistingLocation}
        onChange={(e) => setUseExistingLocation(e.target.checked)}
      >
        Use existing location
      </Checkbox>

      {/* Conditionally render the Search Location field */}
      {useExistingLocation && (
        <div className="page-input-field">
          <label>Search Location</label>
          <div className="page-input-filed-input-wrapper">
            <Field
              name="location"
              component={SearchableSelect}
              options={locationOptions}
              placeholder="Select a location"
              onChange={(selectedValue: string) =>
                handleLocationSelect(selectedValue)
              }
            />
          </div>
        </div>
      )}

      <div className="page-input-field">
        <label>Country / Region</label>
        <div className="page-input-filed-input-wrapper">
          <Field
            name="countryRegion"
            component={SearchableSelect}
            options={countryOptions}
            placeholder="Select a country"
          />
        </div>
      </div>

      <div className="page-input-field-double">
        <div className="page-input-field">
          <label>State</label>
          <div className="page-input-filed-input-wrapper">
            <Field
              name="state"
              component={SearchableSelect}
              options={stateOptions}
              placeholder="Select a state"
            />
          </div>
        </div>
        <div className="page-input-field">
          <label>City</label>
          <div className="page-input-filed-input-wrapper">
            <Field
              name="city"
              component={SearchableSelect}
              options={cityOptions}
              placeholder="Select a city"
            />
          </div>
        </div>
      </div>

      <div className="page-input-field-double">
        <div className="page-input-field">
          <label>Street Address</label>
          <div className="page-input-filed-input-wrapper">
            <AddressAutocomplete
              address={values?.streetAddress || ""}
              setAddress={handleAddressChange}
            />
          </div>
        </div>
        <div className="page-input-field">
          <label>Zip Code</label>
          <div className="page-input-filed-input-wrapper">
            <Field
              as="input"
              type="text"
              placeholder="Enter Zip Code"
              id="zipCode"
              name="zipCode"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
