import React, { useState } from "react";
import CallBackPopup from "@/components/subscriptions/CallBackPopup";
const CustomPlans = () => {
  const [isOpen, setIsOpen] = useState(false);

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  return (
    <div className="grid place-items-center">
      <div className="flex flex-col justify-between lg:flex-row gap-4 p-6 border border-alpha-blue rounded-2xl">
        <div className="grid gap-y-4">
          <p className="text-2xl leading-8">Title</p>
          <p className="leading-4 lg:w-2/3">
            Make your own plan by selecting the features your business needs
            from a range of our elective features
          </p>
          <p className="leading-9 text-3xl">Price</p>
        </div>
        <div className="flex items-center justify-center">
          <button className="ring-1 px-4 py-2 rounded-2xl" onClick={openModal}>
            Request a call back
          </button>
        </div>
      </div>

      <CallBackPopup isOpen={isOpen} closeModal={closeModal} />
    </div>
  );
};

export default CustomPlans;
