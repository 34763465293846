import { useEffect, useState } from "react";
import axios from "axios";

interface Location {
  lat: number;
  lng: number;
}

interface UseGeoLocation {
  location: Location | null;
  loading: boolean;
  error: string | null;
}

const useGeoLocation = (address: string): UseGeoLocation => {
  const [location, setLocation] = useState<Location | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchLocation = async () => {
      setLoading(true);
      setError(null);
      try {
        const response = await axios.get<{
          results: { geometry: { location: Location } }[];
        }>(
          `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
            address,
          )}&key=${import.meta.env.VITE_MAP_KEY}`,
        );

        if (response.data.results && response.data.results.length > 0) {
          setLocation(response.data.results[0].geometry.location);
        } else {
          setError("No location found");
        }
      } catch (error) {
        setError("Error fetching location");
      } finally {
        setLoading(false);
      }
    };
    fetchLocation();

    // Cleanup function
    return () => {
      // Cleanup logic if needed
    };
  }, [address]);

  return { location, loading, error };
};

export default useGeoLocation;
