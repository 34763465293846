import { Card, CardContent } from "@/components/ui/card";

export function BasicPlanCard() {
  return (
    <Card className="bg-[#0f172a] text-white mb-6 relative">
      <CardContent className="p-6">
        <h2 className="text-2xl font-bold text-yellow-400 mb-2">BASIC PLAN</h2>
        <p className="mb-4 text-sm text-grey-200">
          You are currently on a basic plan, this plan give you access to up to
          30 spaces, receive and manage you bookings and also access basic
          analytics. To get more out of your listing, consider upgrading
          premium.
        </p>
        <button className="bg-[#005CE8] text-white py-3 px-6 rounded-full text-sm font-semibold hover:bg-[#005CE8] hover:bg-opacity-60 transition-colors">
          Upgrade Subscription
        </button>
        <div className="absolute bottom-0 right-0">
          <svg
            width="181"
            height="121"
            viewBox="0 0 181 121"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M191.402 97.0532L178.689 71.4483L154.176 58.017L136.179 76.406L161.925 89.1054L176.058 112.111L181.002 107.454L166.944 83.3023L147.521 73.8773L154.974 66.6299L173.811 76.5999L186.906 101.337L191.402 97.0532Z"
              fill="#FEC20E"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M130.822 81.4524L113.292 99.6437L140.558 110.38L150.67 127.958L144.382 134.505L133.941 116.463L106.892 105.364L100.936 111.68L128.227 122.315L142.385 145.826L159.496 128.885L159.237 128.626L145.142 104.957L124.967 96.6965L131.325 90.7897L150.674 100.332L165.246 122.93L170.465 117.219L155.713 94.5194L130.822 81.4524Z"
              fill="#FEC20E"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M91.3106 106.006L78.5968 80.4015L54.0845 66.9702L36.0869 85.3592L61.8327 98.0585L75.966 121.065L80.9102 116.407L66.8523 92.2554L47.4287 82.8305L54.882 75.583L73.719 85.553L86.8145 110.29L91.3106 106.006Z"
              fill="#FEC20E"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M30.7298 90.4055L13.1997 108.597L40.4663 119.333L50.5784 136.911L44.2903 143.458L33.8496 125.416L6.80049 114.317L0.844629 120.633L28.1352 131.268L42.2934 154.779L59.4043 137.838L59.1454 137.579L45.0505 113.91L24.8753 105.65L31.233 99.7428L50.5825 109.286L65.1543 131.883L70.373 126.172L55.6209 103.472L30.7298 90.4055Z"
              fill="#FEC20E"
            />
            <g opacity="0.6">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M149.402 45.0532L136.689 19.4483L112.176 6.01704L94.1787 24.406L119.925 37.1054L134.058 60.1114L139.002 55.4536L124.944 31.3023L105.521 21.8773L112.974 14.6299L131.811 24.5999L144.906 49.337L149.402 45.0532Z"
                fill="#FEC20E"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M88.8216 29.4524L71.2915 47.6437L98.5581 58.3802L108.67 75.958L102.382 82.5049L91.9414 64.4633L64.8923 53.3636L58.9364 59.6803L86.227 70.3151L100.385 93.8262L117.496 76.885L117.237 76.6261L103.142 52.9569L82.9671 44.6965L89.3247 38.7897L108.674 48.3324L123.246 70.9295L128.465 65.2194L113.713 42.5194L88.8216 29.4524Z"
                fill="#FEC20E"
              />
            </g>
          </svg>
        </div>
      </CardContent>
    </Card>
  );
}
