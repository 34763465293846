import React from 'react';
import PlacesAutocomplete, { geocodeByAddress } from 'react-places-autocomplete';
import './index.module.scss';

interface AddressAutocompleteProps {
  address: string;
  setAddress: (address: string, zipCode?: string) => void;
  placeholder?: string;
}

const AddressAutocomplete: React.FC<AddressAutocompleteProps> = ({ address, setAddress, placeholder }) => {
  const handleSelect = async (value: string) => {
    const results = await geocodeByAddress(value);

    // Extract postal code safely
    const addressComponents = results[0].address_components || [];
    const postalCodeComponent = addressComponents.find(component => component.types.includes('postal_code'));

    const postal_code = postalCodeComponent ? postalCodeComponent.long_name : '';

    setAddress(value, postal_code);
  };

  return (
    <PlacesAutocomplete value={address} onChange={setAddress} onSelect={handleSelect}>
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <div className="autocomplete-container">
          <input
            {...getInputProps({
              type:"text",
              id:"streetAddress",
              name:"streetAddress",
              placeholder: placeholder || 'Enter Address',
              className: 'autocomplete-input',
            })}
          />
          <div className="autocomplete-dropdown-container">
            {loading && <div>Loading...</div>}
            {suggestions.map((suggestion, index) => {
              const className = suggestion.active ? 'suggestion-item--active' : 'suggestion-item';
              return (
                <div
                  {...getSuggestionItemProps(suggestion, {
                    className,
                  })}
                  key={index}
                >
                  <span>{suggestion.description}</span>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </PlacesAutocomplete>
  );
};

export default AddressAutocomplete;
