import React from "react";
import styles from "./index.module.scss";
import { useNavigate } from "react-router-dom";
import { removeIcon } from "../../../assets/img";
import banknotes from "../../../assets/svgs/paymentsLarge.svg";
import more from "../../../assets/svgs/more.svg";
import useCheckIsMobileScreen from "../../../hooks/useCheckIsMobileScreen";
import { CustomButton } from "../../../components/customButton";
import { Divider } from "antd";
import useTransactions from "./useTransactions";
import NoDataPage from "../../../components/noDataPage/noDataPage";
import TableNav from "../../../components/tableComponent/tableHeader/tableNav";
import { Spacer } from "../../../components/spacer";
import CustomTable from "../../../components/tableComponent/Table/Table";
import CustomTag from "../../../components/customTag";

const TransactionsPayments = () => {
  const isMobile = useCheckIsMobileScreen();
  const navigate = useNavigate();
  const {
    transactionData,
    currentTab,
    getColor,
    data,
    tabLists,
    onTabClick,
    paymentStatus,
    onSelectPaymentStatus,
    onSearch,
    loggedInUser,
  } = useTransactions();

  const columns = [
    {
      title: <span className={styles.tableHeader}>Customer Information</span>,
      dataIndex: "workspaceInformation",
      key: "workspaceInformation",
      render: (workspaceInfo: { name: string; createdDate: Date }) => (
        <div className={styles.nameCon}>
          <h1 className={styles.name}>{workspaceInfo.name}</h1>
          <p className={styles.date}>Booked on : Friday 15th June, 2023</p>
        </div>
        // <>
        // 	<p>Created: Date</p>
        // 	{/* <p>Created: {format(workspaceInfo.createdDate, "MMMM d, yyyy")}</p> */}
        // </>
      ),
    },
    {
      title: <span className={styles.tableHeader}>Booking Status</span>,
      dataIndex: "bookingStatus",
      key: "bookingStatus",
      render: (text: string) => (
        <CustomTag color={getColor(text)} text={text} />
      ),
    },
    {
      title: <span className={styles.tableHeader}>Amount</span>,
      dataIndex: "amount",
      key: "amount",
      render: (amount: number) => (
        <span className={`${styles.amount}`}>{`₦${amount}`}</span>
      ),
    },
    {
      title: <span className={styles.tableHeader}>Payment Status</span>,
      dataIndex: "paymentStatus",
      key: "paymentStatus",
      render: (text: string) => (
        <CustomTag color={getColor(text)} text={text} />
      ),
    },
    {
      title: <span className={styles.tableHeader}>More</span>,
      dataIndex: "more",
      key: "more",
      render: () =>
        loggedInUser?.role_type === "Host" ? (
          <img src={more} alt="more" />
        ) : (
          <p onClick={() => {}} className={`${styles.viewMore}`}>
            View More
          </p>
        ),
    },
  ];

  return (
    <div className={styles.page}>
      <div className={styles.heading}>
        <div>
          <h2 className={styles.title}>Transactions</h2>
          <p>Lorem Ipsum dolor</p>
        </div>
        {isMobile ? (
          <div className={styles.remove}>
            <div className={styles.icon}>
              <img src={removeIcon} alt="remove" />
              <p className={styles.subtitle}>Remove</p>
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
      <Divider orientation="left"></Divider>

      <div className={styles.content}>
        <div className={styles.table}>
          {data.length === 0 && (
            <NoDataPage
              icon={banknotes}
              title={"No Active transaction"}
              subtitle="All  your transactions will be displayed here."
            />
          )}

          {data && data.length > 0 && (
            <div>
              <TableNav
                tabLists={tabLists}
                onTabChange={onTabClick}
                currentTab={currentTab}
                options={paymentStatus}
                onSearch={onSearch}
                selectPlaceHolder={"Booking Status"}
                searchPlaceHolder={"Search Payment"}
                onSelectPaymentStatus={onSelectPaymentStatus}
              />
              <Spacer height={32} />
              <CustomTable columns={columns} data={data} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TransactionsPayments;
