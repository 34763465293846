import { useState } from "react";
import styles from "./imageUpload.module.scss";
import cloud from "@/assets/svgs/cloud-add.svg";
import greentick from "@/assets/svgs/green-tick.svg";

type ImageFile = {
  src: string;
  title: string;
  size: string;
};

type ImageUploaderProps = {
  uploadedImages: ImageFile[];
  setUploadedImages: React.Dispatch<React.SetStateAction<ImageFile[]>>;
};

const ImageUploader = ({
  uploadedImages,
  setUploadedImages,
}: ImageUploaderProps) => {
  const [errorMessage, setErrorMessage] = useState("");

  const handleImageUpload = (files: FileList | null) => {
    if (!files || files.length === 0) return;

    const imagesArray: { src: string; title: string; size: string }[] = [];
    const uploadPromises: Promise<unknown>[] = [];

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const reader = new FileReader();

      const uploadPromise = new Promise((resolve, reject) => {
        reader.onload = (e) => {
          const img = new Image();
          img.src = e.target!.result as string;

          img.onload = () => {
            if (img.width > img.height) {
              // Image is horizontal, proceed with upload
              imagesArray.push({
                src: img.src,
                title: file.name,
                size: getFileSize(file.size),
              });
              resolve({});
            } else {
              // Image is not horizontal, display error message
              reject("Please upload horizontal images only.");
            }
          };
        };

        reader.readAsDataURL(file);
      });

      uploadPromises.push(uploadPromise);
    }

    Promise.all(uploadPromises)
      .then(() => {
        setUploadedImages([...uploadedImages, ...imagesArray]);
        setErrorMessage("");
      })
      .catch((error) => setErrorMessage(error));
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    handleImageUpload(files);
  };

  const handleBrowseClick = () => {
    document.getElementById("fileInput")?.click();
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files) {
      const acceptedTypes = ["image/png", "image/jpeg", "image/jpg"];
      const maxSize = 10 * 1024 * 1024; // 10MB in bytes

      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        if (!acceptedTypes.includes(file.type)) {
          setErrorMessage("Please upload PNG, JPEG, or JPG files only.");
          return;
        }
        if (file.size > maxSize) {
          setErrorMessage(`Maximum file size exceeded (${maxSize} bytes).`);
          return;
        }
      }
      setErrorMessage("");
      handleImageUpload(files);
    }
  };

  const handleDeleteImage = (index: number) => {
    const newImages = [...uploadedImages];
    newImages.splice(index, 1);
    setUploadedImages(newImages);
  };

  return (
    <div className="image-uploader-parent">
      <div
        className="image-uploader"
        onDragOver={handleDragOver}
        onDrop={handleDrop}
      >
        <img src={cloud} alt="upload" className="cloud-svg" />
        <p className="bold-p">Choose a file or drag & drop it here</p>
        <p className="faint-p">PNG, JPEG, or JPG files up to {100}</p>
        <button
          type="button"
          onClick={handleBrowseClick}
          className="cancel browseFiles"
        >
          Browse Files
        </button>
        <input
          title="Image Upload"
          id="fileInput"
          type="file"
          className="hidden"
          onChange={handleFileChange}
          accept=".png,.jpg,.jpeg" // Limit accepted file types
          multiple // Allow multiple file selection
        />
      </div>
      {errorMessage && <p className="text-red-600">{errorMessage}</p>}
      <div className="uploaded-images-parent">
        {uploadedImages?.map((imageData: any, index: number) => (
          <div className="uploaded-image-div" key={index}>
            <button
              onClick={() => handleDeleteImage(index)}
              style={{
                position: "absolute",
                top: "15px",
                right: "30px",
                background: "none",
                border: "none",
                cursor: "pointer",
                color: "red",
                fontSize: "30px",
              }}
            >
              &times;
            </button>
            <img src={imageData.src} alt={`Uploaded ${index}`} />
            <div className="uploaded-image-data">
              <p className="title">{imageData.title}</p>
              <div className="bottom">
                <p>
                  {imageData.size} of {imageData.size}.
                </p>
                <div className="status">
                  <img src={greentick} alt="" />
                  <p>Completed</p>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

function getFileSize(bytes: number) {
  const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  if (bytes === 0) return "0 Byte";
  const i = Math.floor(Math.log(bytes) / Math.log(1024));
  return `${parseFloat((bytes / Math.pow(1024, i)).toFixed(2))} ${sizes[i]}`;
}

export default ImageUploader;
