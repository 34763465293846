import React from "react";

export const BankIcon: React.FC<React.SVGAttributes<{}>> = (props) => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M22.4478 19.75V22.75H2.44775V19.75C2.44775 19.2 2.89775 18.75 3.44775 18.75H21.4478C21.9978 18.75 22.4478 19.2 22.4478 19.75Z"
        fill="currentColor"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7.44775 11.75H5.44775V18.75H7.44775V11.75Z"
        fill="currentColor"
      />
      <path
        d="M11.4478 11.75H9.44775V18.75H11.4478V11.75Z"
        fill="currentColor"
      />
      <path
        d="M15.4478 11.75H13.4478V18.75H15.4478V11.75Z"
        fill="currentColor"
      />
      <path
        d="M19.4478 11.75H17.4478V18.75H19.4478V11.75Z"
        fill="currentColor"
      />
      <path
        d="M23.4478 23.5H1.44775C1.03775 23.5 0.697754 23.16 0.697754 22.75C0.697754 22.34 1.03775 22 1.44775 22H23.4478C23.8578 22 24.1978 22.34 24.1978 22.75C24.1978 23.16 23.8578 23.5 23.4478 23.5Z"
        fill="currentColor"
      />
      <path
        d="M21.8178 6.50375L12.8178 2.90375C12.6178 2.82375 12.2778 2.82375 12.0778 2.90375L3.07775 6.50375C2.72775 6.64375 2.44775 7.05375 2.44775 7.43375V10.7537C2.44775 11.3038 2.89775 11.7537 3.44775 11.7537H21.4478C21.9978 11.7537 22.4478 11.3038 22.4478 10.7537V7.43375C22.4478 7.05375 22.1678 6.64375 21.8178 6.50375ZM12.4478 9.25375C11.6178 9.25375 10.9478 8.58375 10.9478 7.75375C10.9478 6.92375 11.6178 6.25375 12.4478 6.25375C13.2778 6.25375 13.9478 6.92375 13.9478 7.75375C13.9478 8.58375 13.2778 9.25375 12.4478 9.25375Z"
        fill="currentColor"
      />
    </svg>
  );
};
