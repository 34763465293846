import React from "react";
import { Divider, Tabs, TabsProps } from "antd";
import NewListing from "./NewListing/NewListing";
import RejectList from "./NewListing/RejectList";
import AllListing from "./NewListing/AllListing";
import LiveListing from "./NewListing/LiveListing";
import SearchBar from "@/components/a/SearchBar/workspace";
import { Search } from "@/components/Search/Search";
import { useApprovalRequestsQuery } from "@/data/approval_request";

const handleTabChange = (key: string) => {
  console.log(key, "key");
};

const handleChande = () => {
  console.log("Okay");
};

function SuperAdmin() {
  const { requests, loading, error } = useApprovalRequestsQuery({});

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: <span className="text-base text-[#929292]">All </span>,
      children: <AllListing requests={requests} />,
    },
    // {
    //   key: "2",
    //   label: <span className="text-base text-[#929292]">New listings</span>,
    //   children: <NewListing />,
    // },
    // {
    //   key: "3",
    //   label: <span className="text-base text-[#929292]">Rejected </span>,
    //   children: <RejectList />,
    // },
    // {
    //   key: "4",
    //   label: <span className="text-base text-[#929292]">Live</span>,
    //   children: <LiveListing />,
    // },
  ];
  return (
    <div className="p-5 md:p-15 ">
      <div className="flex justify-between invisible md:visible ">
        <p className="text-2xl font-semibold text-coSpireBlack">
          Listed Spaces
        </p>
        <p className="text-sm text-[#929292]">Sort by</p>
      </div>

      <p className="text-2xl font-semibold text-coSpireBlack md:invisible">
        Listed Spaces
      </p>

      <Divider className="my-3 md:my-10 invisible md:visible  " />

      <div className="border p-4 rounded-2xl pt-10 md:pt-0 md:border-none w-full ">
        <div>
          <div className="absolute top-[270px] md:top-auto md:right-16 ">
            <Search
              onChange={handleChande}
              className=" h-13 w-72 md:w-80 md:h-14 p-2"
              placeholder="Search Listing"
            />
          </div>

          <Tabs
            defaultActiveKey="1"
            items={items}
            onChange={handleTabChange}
            className="my-5"
          />
        </div>
      </div>
    </div>
  );
}

export default SuperAdmin;
