export const CreditCardIcon: React.FC<React.SVGAttributes<{}>> = (props) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.2503 3.84427V6.28594C11.2503 6.84427 10.8003 7.29427 10.242 7.29427H2.67533C2.10866 7.29427 1.66699 6.8276 1.66699 6.26927C1.67533 5.3276 2.05033 4.46927 2.67533 3.84427C3.30033 3.21927 4.16699 2.83594 5.11699 2.83594H10.242C10.8003 2.83594 11.2503 3.28594 11.2503 3.84427Z"
        fill="currentColor"
      />
      <path
        d="M1.66699 9.5474V13.7141C1.66699 15.6224 3.20866 17.1641 5.11699 17.1641H14.8753C16.7837 17.1641 18.3337 15.6141 18.3337 13.7057V9.5474C18.3337 8.98906 17.8837 8.53906 17.3253 8.53906H2.67533C2.11699 8.53906 1.66699 8.98906 1.66699 9.5474ZM6.66699 14.3724H5.00033C4.65866 14.3724 4.37533 14.0891 4.37533 13.7474C4.37533 13.4057 4.65866 13.1224 5.00033 13.1224H6.66699C7.00866 13.1224 7.29199 13.4057 7.29199 13.7474C7.29199 14.0891 7.00866 14.3724 6.66699 14.3724ZM12.0837 14.3724H8.75033C8.40866 14.3724 8.12533 14.0891 8.12533 13.7474C8.12533 13.4057 8.40866 13.1224 8.75033 13.1224H12.0837C12.4253 13.1224 12.7087 13.4057 12.7087 13.7474C12.7087 14.0891 12.4253 14.3724 12.0837 14.3724Z"
        fill="currentColor"
      />
      <path
        d="M16.6417 1.46094H14.1917C13.1333 1.46094 12.5 2.09427 12.5 3.1526V5.6026C12.5 6.66094 13.1333 7.29427 14.1917 7.29427H16.6417C17.7 7.29427 18.3333 6.66094 18.3333 5.6026V3.1526C18.3333 2.09427 17.7 1.46094 16.6417 1.46094ZM15.0083 6.1026C14.9833 6.1276 14.925 6.16094 14.8833 6.16094L14.0167 6.28594C13.9917 6.29427 13.9583 6.29427 13.9333 6.29427C13.8083 6.29427 13.7 6.2526 13.625 6.16927C13.525 6.06927 13.4833 5.9276 13.5083 5.7776L13.6333 4.91094C13.6417 4.86927 13.6667 4.81094 13.6917 4.78594L15.1083 3.36927C15.1333 3.4276 15.1583 3.49427 15.1833 3.56094C15.2167 3.6276 15.25 3.68594 15.2833 3.74427C15.3083 3.79427 15.3417 3.84427 15.375 3.8776C15.4083 3.9276 15.4417 3.9776 15.4667 4.0026C15.4833 4.0276 15.4917 4.03594 15.5 4.04427C15.575 4.13594 15.6583 4.21927 15.7333 4.2776C15.75 4.29427 15.7667 4.31094 15.775 4.31094C15.8167 4.34427 15.8667 4.38594 15.9 4.41094C15.95 4.44427 15.9917 4.4776 16.0417 4.5026C16.1 4.53594 16.1667 4.56927 16.2333 4.6026C16.3 4.63594 16.3667 4.66094 16.425 4.6776L15.0083 6.1026ZM17 4.11094L16.725 4.38594C16.7167 4.4026 16.6917 4.41094 16.6667 4.41094C16.6583 4.41094 16.65 4.41094 16.6417 4.41094C16.0417 4.23594 15.5667 3.76094 15.3917 3.16094C15.3833 3.1276 15.3917 3.09427 15.4167 3.06927L15.6917 2.79427C16.1417 2.34427 16.5667 2.3526 17.0083 2.79427C17.2333 3.01927 17.3417 3.23594 17.3417 3.4526C17.3333 3.66927 17.225 3.88594 17 4.11094Z"
        fill="currentColor"
      />
    </svg>
  );
};
