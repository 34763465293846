import React, { Fragment, useState, useEffect } from "react";
import styles from "./index.module.scss";
import { CustomButton } from "@/components/customButton";
import { image, removeIcon } from "@/assets/img";
import useCheckIsMobileScreen from "@/hooks/useCheckIsMobileScreen";
import more from "@/assets/svgs/more.svg";
import Star from "@/assets/svgs/Vector.svg";
import Chekbadge from "@/assets/svgs/check-badge.svg";
import { Divider, Modal, Tag } from "antd";
import workspaceImage from "@/assets/img/Space3.webp";
import { Table, Pagination } from "antd";
import CustomTag from "@/components/customTag";
import TableNav from "@/components/tableComponent/tableHeader/tableNav";
import { Spacer } from "@/components/spacer";
// import useBooking from "./useBooking";
import CustomTable from "@/components/tableComponent/Table/Table";
import { useNavigate } from "react-router-dom";
import { getAllBookings } from "@/services/networkRequests/bookingService";
import CustomDropDown from "@/components/a/Modals/customDropDown/CustomDropDown";
import { Link } from "react-router-dom";
import Preview from "@/components/Preview/Preview";
import bookingPreview from "@/assets/svgs/bookinPreview.svg";
import { default as dayjs } from "dayjs";

import BookingCalendar from "@/components/BookingCalendar/index";
import { HOST_BOOKING, WORKSPACES } from "@/routes/routes";
import { BOOKING_DETAILS } from "@/routes/routes";
import { useBookingHistoryQuery } from "@/data/booking";
import { Booking } from "@/types/booking";
import { formatMoney } from "@/utils/format-money";
import { Workspace } from "@/types/workspace";
import { MarkerIcon } from "@/components/icons/marker-icon";

export default function RentersBookingHistory() {
  const navigate = useNavigate();
  const isMobile = useCheckIsMobileScreen();
  //   const {
  //     tabLists,
  //     currentTab,
  //     onTabClick,
  //     paymentStatus,
  //     onSelectPaymentStatus,
  //     onSearch,
  //     getColor,
  //     data,
  //     isLoading,
  //     bookings,
  //     getAllBookingForHost,
  //     customerBookingsData,
  //     bookTest,
  //     hostWorkSpaces,
  //   } = useBooking();

  const { bookings, loading: isLoading } = useBookingHistoryQuery({});

  const [viewMore, setViewMore] = useState(true);
  const [showTable, setShowTable] = useState(false);

  const detailsLink = `/host-profile/bookings/hostBooking_details`;

  // const [visible, setVisible] = useState(false);
  // const handleBookingSchedule = () => {
  //   setVisible(true);
  // };

  // const closeModal = () => {
  //   setVisible(false);
  // };

  const items = [
    {
      key: 1,
      label: (
        <Link to={detailsLink} className={styles.dropDownText}>
          View Booking details
        </Link>
      ),
    },
    {
      key: 2,
      label: (
        <Link to="#" className={styles.dropDownText}>
          Cancle Booking
        </Link>
      ),
    },
    {
      key: 3,
      label: (
        <Link to="#" className={styles.dropDownText}>
          Delete Booking
        </Link>
      ),
    },
  ];

  const handleShowTable = () => {
    setViewMore(!viewMore);
    setShowTable(!showTable);
  };

  const handleViewMore = (id: string) => {
    // setViewMore(!viewMore);
    // setShowTable(!showTable);
    navigate(`booking_details/${id}`);
  };

  const columns = [
    {
      title: <span className="uppercase text-coSpireBlack">Listing</span>,
      dataIndex: "workspace",
      key: "workspace.id",
      render: (workspace: Workspace) => (
        <div className={styles.workspaceView}>
          <img src={workspace.photos[0]} alt="..." />
          <div className={`${styles.workspaceInfo}`}>
            <h1 className={`${styles.name}`}>
              {workspace?.title.slice(0, 25)}
            </h1>
            <div>
              <MarkerIcon className="text-primaryText w-[18px] h-[18px]" />
              <p>{workspace.formatted_address}</p>
            </div>
          </div>
        </div>
      ),
    },
    {
      title: <span className="uppercase text-coSpireBlack">Start date</span>,
      dataIndex: "booking_details",
      key: "booking_details.start_date",
      render: (details: Booking["booking_details"]) => (
        <span className={"text-center"}>{`${dayjs(details.start_date)
          .add(dayjs(details.start_time, "HH:mm").hour(), "hour")
          .add(dayjs(details.start_time, "HH:mm").minute(), "minutes")
          .format("MMMM D, YYYY h:mm A")}`}</span>
      ),
    },
    // {
    //   title: (
    //     <span className="uppercase text-coSpireBlack">
    //       Activity
    //     </span>
    //   ),
    //   dataIndex: "activity",
    //   key: "activity",
    //   render: (activity: string) => (
    //     <span className={`text-center`}>{`${activity}`}</span>
    //   ),
    // },
    {
      title: <span className="uppercase text-coSpireBlack">Amount</span>,
      dataIndex: "booking_details",
      key: "booking_details.amount",
      render: (details: Booking["booking_details"]) => (
        <span className={`text-center`}>{formatMoney(details.price)}</span>
      ),
    },
    // {
    //   title: (
    //     <span className="uppercase text-coSpireBlack">
    //       Status
    //     </span>
    //   ),
    //   dataIndex: "payment_status",
    //   key: "payment_status",
    //   render: (text: string) => (
    //     <CustomTag
    //       color={getColor(text)}
    //       text={text === "pending check" ? "Pending" : text}
    //     />
    //   ),
    // },
    {
      title: <span className="uppercase text-coSpireBlack">More</span>,
      dataIndex: "id",
      key: "id",
      render: (id: string, text: string, record: any) => {
        if (record.status === "Cancelled") {
          return <p className={`${styles.disabled}`}>View More</p>;
        } else {
          return (
            <p
              onClick={() => handleViewMore(id)}
              className={`${styles.viewMore}`}
            >
              View More
            </p>
          );
        }
      },
    },
  ];

  const pageSize = 5;

  const rowClassName = (record: any) => {
    if (record.status === "Cancelled") {
      return `${styles.disabledRow}`;
    }
    return ""; // No special class for other rows
  };

  return (
    <div className={styles.page}>
      <div>
        <div className="flex justify-end pb-10">
          <CustomButton
            children={"Book a space"}
            onClick={() => {
              navigate(WORKSPACES);
            }}
          />
        </div>
        <div className={styles.heading}>
          <div>
            <h2 className={styles.title}>Booking History</h2>
          </div>
          {isMobile ? (
            <div className={styles.remove}>
              <div className={styles.icon}>
                <img src={removeIcon} alt="remove" />
                <p className={styles.subtitle}>Remove</p>
              </div>
            </div>
          ) : (
            <Fragment></Fragment>
          )}
        </div>
        <Divider orientation="left"></Divider>
        <div>
          {/* <TableNav
            tabLists={tabLists}
            onTabChange={onTabClick}
            currentTab={currentTab}
            options={paymentStatus}
            onSearch={onSearch}
            selectPlaceHolder={"Date"}
            searchPlaceHolder={"Search Bookings"}
            onSelectPaymentStatus={onSelectPaymentStatus}
          /> */}
          <Spacer height={32} />
          <div className={styles.tableCtrl}>
            <CustomDropDown dropDownProps={{ items }}></CustomDropDown>
            {isLoading ? (
              <Preview
                image={bookingPreview}
                bookingText="No bookings yet!"
                labelText="Your recent bookings will be displayed here."
              />
            ) : (
              <CustomTable
                columns={columns}
                // @ts-ignore
                data={bookings}
                rowClassName={rowClassName}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
