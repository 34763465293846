import { CreditCardIcon } from "@/components/icons/credit-card";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { UserIcon, ShieldCheckIcon, BuildingIcon } from "lucide-react";
import { Link } from "react-router-dom";
import {
  Breadcrumb,
  BreadcrumbEllipsis,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "@/components/ui/breadcrumb";
import { BankIcon } from "@/components/icons/bank-icon";
import { BadgeIcon } from "@/components/icons/badge-icon";
import { PersonalCardIcon } from "@/components/icons/personal-card-icon";

export default function AccountSettingsPage() {
  const ITEMS = [
    {
      title: "Profile settings",
      description: "Change password, Profile image, Email",
      icon: <PersonalCardIcon className="h-6 w-6 text-primary" />,
      to: "#",
    },
    {
      title: "Verifications",
      description: "Verify BVN or NIN",
      icon: <BadgeIcon className="h-6 w-6 text-primary" />,
      to: "#",
    },
    {
      title: "Bank settings",
      description: "Withdrawal account, Subscriptions",
      icon: <BankIcon className="h-6 w-6 text-primary" />,
      to: "#",
    },
    {
      title: "Company & Business Settings",
      description: "Verify CAC",
      icon: <BadgeIcon className="h-6 w-6 text-primary" />,
      to: "#",
    },
    {
      title: "Subscription",
      description: "Manage your subscription",
      icon: <CreditCardIcon className="h-6 w-6 text-primary" />,
      to: "/account-settings/manage-subscription",
    },
  ];
  return (
    <div className="container mx-auto p-4">
      <div className="mb-6">
        <Breadcrumb>
          <BreadcrumbList>
            <BreadcrumbItem>
              <BreadcrumbLink className="text-[#C7C7C7]" href="/">
                Home
              </BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbSeparator className="text-[#C7C7C7]" />
            <BreadcrumbItem>
              <BreadcrumbLink
                className="text-primaryText"
                href="/account-settings"
              >
                Account Settings
              </BreadcrumbLink>
            </BreadcrumbItem>
          </BreadcrumbList>
        </Breadcrumb>
      </div>

      <div className="border border-[#C7C7C7] rounded-3xl md:py-24 py-16 px-2 md:px-0">
        <div className="max-w-6xl w-full mx-auto ">
          <h1 className="text-2xl font-bold mb-6">Account settings</h1>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {ITEMS.map((item, index) => (
              <SettingCard
                key={index}
                title={item.title}
                description={item.description}
                icon={item.icon}
                to={item.to}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

function SettingCard({
  title,
  description,
  icon,
  to = "#",
}: {
  title: string;
  description: string;
  icon: JSX.Element;
  to?: string;
}) {
  return (
    <Link to={to}>
      <Card className="bg-[#F7F9FB] border border-[#C7C7C7] rounded">
        <CardHeader className="flex flex-row items-center justify-between space-y-0">
          <CardTitle className="text-lg font-medium text-coSpireBlack">
            {title}
          </CardTitle>
          {icon}
        </CardHeader>
        <CardContent>
          <p className="text-xs text-gray-500">{description}</p>
        </CardContent>
      </Card>
    </Link>
  );
}
