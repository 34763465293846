import React, { useEffect } from "react";
import cospire_workspace from "@/assets/img/become_space_owner.png";
import { CustomInputField, CustomSelect, PhoneInput } from "@/components";
import { CustomButton } from "@/components/customButton";
import { useFormik } from "formik";
import { useMe } from "@/data/user";
import { useAuth } from "@/components/auth/use-auth";

import * as Yup from "yup";
import { BecomeSpaceOwner } from "@/types/space-owner";

function RentalPSaceOwner() {
  const { me: loggedInUser, isLoading: loading } = useMe();
  const { currentUser: user } = useAuth();

  console.log(loggedInUser, user);

  const validateUser = () => {
    return Yup.object({
      fullname: Yup.string().required("Enter your full name"),
      email: Yup.string()
        .required("Enter your email")
        .email("Invalid email format"),
      phoneNumber: Yup.number().required("Enter your phone number"),
      position: Yup.string().required("Select your position"),
    });
  };

  const formik = useFormik({
    initialValues: {
      fullname: loggedInUser?.displayName || "",
      email: loggedInUser?.email || "",
      phoneNumber: user?.phoneNumber ?? null,
      position: "",
    },
    validationSchema: validateUser(),
    onSubmit: (values: BecomeSpaceOwner) => {
      console.log(values);
    },
  });

  useEffect(() => {
    if (loggedInUser) {
      formik.setValues({
        fullname: loggedInUser.displayName || "",
        email: loggedInUser.email || "",
        phoneNumber: user?.phoneNumber ?? null,
        position: formik.values.position,
      });
    }
  }, [loggedInUser]);

  const position = [
    { label: "Founder/CEO", value: "Founder/CEO" },
    { label: "Managerial", value: "Managerial" },
    { label: "others", value: "others" },
  ];

  return (
    <div>
      <div className="md:grid grid-cols-2 md:min-h-screen -mt-5 ">
        <div className=" p-5 md:p-10 ">
          <p className="font-semibold text-3xl text-coSpireBlack ">
            List Your Space
          </p>
          <p className="text-lg text-primaryText my-3">
            CO-SPIRE users are searching for Spaces like yours
          </p>

          <form onSubmit={formik.handleSubmit} className="my-9 md:w-5/6">
            <div>
              <CustomInputField
                type={"text"}
                label={"Owner's Fullname"}
                placeholder="Your Fullname"
                name={"fullname"}
                value={loggedInUser?.displayName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.fullname && formik.errors.fullname}
              />
            </div>
            <div>
              <CustomInputField
                type={"email"}
                label={"Email Address"}
                placeholder="Your Email"
                name={"email"}
                value={loggedInUser?.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.email && formik.errors.email}
              />
            </div>
            <div>
              <PhoneInput
                label={"Phone Number"}
                placeholder="Your Phone number"
                name={"phoneNumber"}
                // @ts-ignore
                value={user?.phoneNumber || formik.values.phoneNumber}
                onChange={(value) => formik.setFieldValue("phoneNumber", value)}
                onBlur={formik.handleBlur}
                error={formik.touched.phoneNumber && formik.errors.phoneNumber}
              />
            </div>
            <div className="my-4">
              <CustomSelect
                options={position}
                placeHolder="Select your Position"
                label={"Position at the Company"}
                value={formik.values.position}
                onChange={(value) => formik.setFieldValue("position", value)}
                error={formik.touched.position && formik.errors.position}
              />
            </div>

            <div className="my-5">
              <CustomButton
                children={"Proceed"}
                extraStyles={{ width: "100%" }}
                htmlType="submit"
              />
            </div>
          </form>
        </div>
        <div
          className="bg-cover bg-left"
          style={{ backgroundImage: `url(${cospire_workspace})` }}
        ></div>
      </div>
    </div>
  );
}

export default RentalPSaceOwner;
