import React, { useState, useEffect } from "react";
import styles from "./index.module.scss";
import { HOST_TABS, SERVICES } from "../../../mocks";
import Description from "./components/Decription";
import { Contact } from "./components/Contact/contact";
import Operations from "./components/Operations/operations";
import ConfirmationPage from "./components/ConfirmationPage/confirmationPage";
import SuccessModal from "../../../components/a/Modals/Success";
import { success } from "@/assets/img";
import { CustomButton } from "../../../components/customButton";
import { Col } from "antd";
import { Formik, FormikHelpers, FormikProps } from "formik";
import { SPACE_FORM_SCHEMA } from "../../../validationSchemas/schema";
import { IWorkSpaceForm } from "../../../interfaces/forms";
import { RightOutlined } from "@ant-design/icons";
import { ADD_SPACE_DETAILS, HOST_PROFILE } from "../../../routes/routes";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import useHostForm from "./useHostForm";
import { setEditModeOn } from "../../../redux/rootReducer";
import { useAppDispatch } from "../../../redux/hook";
import { TabTitle } from "@/utils/titleFunction";

const HostForm = () => {
  TabTitle("co-spire | New Space");
  const {
    initialState,
    activeTab,
    setActiveTab,
    openModal,
    handleFormSubmit,
    currentTab,
    navigate,
    tabs,
    openSelectDays,
    isSubmitting,
    setOpenModal,
  } = useHostForm();
  const dispatch = useAppDispatch();
  return (
    <>
      <div className={styles.page}>
        <section className={styles.container}>
          <div className={styles.breadcrumbs}>
            {" "}
            <span
              className={styles.first}
              onClick={() => navigate(`${ADD_SPACE_DETAILS}/description`)}
            >
              Add a Space
            </span>{" "}
            <RightOutlined className={styles.arrow} />{" "}
            <span className={styles.label}>{currentTab}</span>{" "}
          </div>
          <div className={styles.banner}></div>

          <Formik
            validateOnMount
            validateOnChange
            enableReinitialize
            initialValues={initialState}
            validationSchema={SPACE_FORM_SCHEMA}
            onSubmit={(
              values: IWorkSpaceForm,
              { setSubmitting }: FormikHelpers<IWorkSpaceForm>,
            ) => {
              setSubmitting(true);
              handleFormSubmit(values);
              setSubmitting(false);
              // resetForm();
            }}
          >
            {(formik: FormikProps<IWorkSpaceForm>) => {
              return (
                <div className={styles.content}>
                  {currentTab !== tabs.preview && (
                    <div className={styles.formContainer}>
                      <div className={styles.tabContainer}>
                        {HOST_TABS.map((tab) => {
                          return (
                            <Link
                              className={styles.tabs}
                              key={tab.slug}
                              to={`${ADD_SPACE_DETAILS}/${tab.slug}`}
                            >
                              <div
                                className={` ${
                                  activeTab === tab.slug ? styles.active : ""
                                } ${styles.tabItem}`}
                              >
                                <img
                                  src={tab.icon}
                                  alt=""
                                  className={styles.svgicon}
                                />
                                <span className={styles.label}>
                                  {tab.label}
                                </span>
                              </div>
                            </Link>
                          );
                        })}
                      </div>
                      <div className={styles.formContent}>
                        {currentTab === tabs.description && (
                          <div className={styles.tabContent}>
                            <Description formik={formik} />
                          </div>
                        )}
                        {currentTab === tabs.contact_details && (
                          <div className={styles.tabContent}>
                            <Contact formik={formik} />
                          </div>
                        )}
                        {currentTab === tabs.operation_hours && (
                          <div className={styles.tabContent}>
                            <Operations formik={formik} />
                          </div>
                        )}
                      </div>
                    </div>
                  )}

                  {currentTab === tabs.preview && (
                    <>
                      <ConfirmationPage
                        formik={formik}
                        isSubmitting={isSubmitting}
                      />
                    </>
                  )}
                </div>
              );
            }}
          </Formik>
        </section>
      </div>

      {openModal && (
        <SuccessModal
          openModal={openModal}
          setOpenModal={setOpenModal}
          heading={"Yippee! You’re Almost Done!"}
          closeModal={() => {
            setOpenModal(false);
            dispatch(setEditModeOn(false));
            navigate(`${HOST_PROFILE}/workspaces`);
          }}
          icon={success}
          description={
            "We have received your request and we are reviewing it. A verification email would be sent to you once your space is approved. Increase visibility for your coworking space, rank higher in search results and connect faster and better with prospects when you add your space to our list of Featured Coworking Spaces"
          }
          // button={
          //    <Col flex='auto'>
          //       <CustomButton children={"Add to Featured Workspaces"} className={styles.btnWhite} />
          //    </Col>
          // }
        />
      )}
    </>
  );
};

export default HostForm;
