import { CustomButton } from "@/components/customButton";
import { Radio, RadioGroup } from "@headlessui/react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { CustomSelect, CustomTextArea } from "@/components";
import { Formik } from "formik";
import { useUpdateWorkspaceMutation } from "@/data/workspace";
import { toast } from "react-toastify";
import { handleError } from "@/services/networkRequests";

type SpaceValues = {
  rules: string;
  percentage_of_security_deposit: string;
  cancelation_policy: string;
};

const CANCELATION_POLICIES = [
  {
    value: "qIXXAAtNPEvDoZAdGliV",
    label: "Most Flexible",
    details: [
      "Renters can cancel until 24 hours before booking date for a full refund, and space owners won’t be paid.",
      "If renters cancel after that, space owners will be paid 60% of booking fee.",
    ],
  },
  {
    value: "5s7n7eLAXfwsBp5HPWLt",
    label: "Average",
    details: [
      "Renters can cancel until 3 days before Booking date for a full refund, and hosts won’t be paid.",
      "If guests cancel after that, hosts will be paid 50% of booking fee.",
    ],
  },
  {
    value: "cnTG0doYsXYxKFjQJo3D",
    label: "Firm",
    details: [
      "To receive a full refund, renters must cancel at least 10 days before check-in.",
      "If renters cancel between 3 and 9 days before check-in, space owners will be paid 50% of booking fee.",
      "If renters cancel less than 3 days before booking date, space owners will be paid 100% for all nights.",
      "Guests can also receive a full refund if they cancel within 24 hours of booking, if the cancellation occurs at least 5 days before booking date",
    ],
  },
];
const SECURITY_DEPOSIT_PERCENTAGES = [
  { label: "5%", value: "5" },
  { label: "10%", value: "10" },
  { label: "20%", value: "20" },
];

function SpaceRules() {
  const { state } = useLocation();
  const params = useParams<{ id: string }>();

  const { mutate: update, isLoading } = useUpdateWorkspaceMutation();

  const navigate = useNavigate();
  function handleUpdatingSpace(values: SpaceValues) {
    const payload = {
      rules: values.rules,
      percentage_of_security_deposit: Number(
        values.percentage_of_security_deposit
      ),
      cancellation_policy_ids: [values.cancelation_policy],
    };

    update(
      { ...payload, id: params.id! },
      {
        onSuccess: () => {
          toast.success("Space updated successfully");
          navigate(-1);
        },
        onError: (error) => handleError(error),
      }
    );
  }

  return (
    <div className="p-5 md:p-9 ">
      <div className="w-[90%] md:w-[22%] flex justify-between text-textGrey text-base">
        <Link to={"#"}>Listings </Link>
        <span>&gt;</span>
        <Link to={"/my-listings/preview/" + params.id}> Listing details</Link>
        <span>&gt;</span>
        <Link className=" text-primaryText font-semibold" to={"#"}>
          Space rules
        </Link>
      </div>

      <Formik
        initialValues={{
          rules: state?.rules || "",
          percentage_of_security_deposit: state?.percentage_of_security_deposit,
          cancelation_policy:
            state?.cancellation_policy_ids?.length >= 1
              ? state?.cancellation_policy_ids[0]
              : "",
        }}
        validate={(values) => {
          const errors: {
            rules?: string;
            percentage_of_security_deposit?: string;
            cancelation_policy?: string;
          } = {};
          if (!values.rules) {
            errors.rules = "Required";
          }
          if (!values.percentage_of_security_deposit) {
            errors.percentage_of_security_deposit = "Required";
          }
          if (!values.cancelation_policy) {
            errors.cancelation_policy = "Required";
          }
          return errors;
        }}
        onSubmit={(values) => handleUpdatingSpace(values)}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue: setValue,
          /* and other goodies */
        }) => (
          <form onSubmit={handleSubmit}>
            <div className="rounded-2xl border border-gray-300 p-5 my-12">
              <p className="text-coSpireBlack text-3xl font-semibold leading-10 ">
                Space rules
              </p>
              <p className=" text-lg text-primaryText leading-7">
                Enter rules and regulation that guide your space
              </p>

              <form action="">
                <div className="md:w-[50%] my-4">
                  <CustomTextArea
                    name="rules"
                    label="Enter Space Rule"
                    onChange={handleChange}
                    value={values.rules}
                  />
                </div>

                <p className="text-coSpireBlack text-3xl font-semibold leading-10 my-7">
                  Security Deposit
                </p>

                <div className="md:w-[50%]">
                  <CustomSelect
                    name="percentage_of_security_deposit"
                    options={SECURITY_DEPOSIT_PERCENTAGES}
                    placeHolder="Select a percentage form"
                    label={"Select a percentage form"}
                    value={values.percentage_of_security_deposit}
                    onChange={(value) => {
                      setValue("percentage_of_security_deposit", value);
                    }}
                  />
                </div>

                <div className="py-4">
                  <h3 className="text-2xl font-semibold text-coSpireBlack py-3">
                    Cancelation Policy
                  </h3>
                  <p className="text-lg leading-8 pb-5">
                    Select the policies that guide the cancellation of prior
                    bookings made for your space
                  </p>

                  <RadioGroup
                    value={values.cancelation_policy}
                    onChange={(value) => {
                      setValue("cancelation_policy", value);
                    }}
                  >
                    {CANCELATION_POLICIES.map((item) => (
                      <div className="my-4">
                        <div className="flex item-center gap-2 font-medium  ">
                          <Radio
                            value={item.value}
                            className="group cursor-pointer rounded-xl items-center justify-center bg-alpha-blue flex size-5 data-[checked]:border-primary border-2 border-[#EFF0F6]"
                          >
                            <span className="size-2 rounded-full group-data-[checked]:bg-primary group-data-[checked]:visible" />
                          </Radio>
                          <span>{item.label}</span>
                        </div>

                        <ul className="pl-8 mt-2 list-disc list-outside text-primaryText space-y-1 text-base">
                          {item.details.map((detail, index) => (
                            <li key={index}>{detail}</li>
                          ))}
                        </ul>
                      </div>
                    ))}
                  </RadioGroup>
                </div>
                <p className="text-lg font-medium text-darkBlue cursor-pointer">
                  Learn more about cancellation policy
                </p>
              </form>
            </div>
            <div className="flex mt-10 my-4 md:gap-7 md:justify-end items-center">
              <CustomButton
                children={"Back to listing details"}
                type={"link"}
                className="font-medium text-darkBlue text-base hover:font-semibold hover:text-darkBlue"
                onClick={() => navigate(-1)}
              />

              <CustomButton
                children={"Save and exit"}
                extraStyles={{ padding: "18px, 24px", fontWeight: "500" }}
                htmlType="submit"
                loading={isLoading}
              />
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
}

export default SpaceRules;
