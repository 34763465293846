import React from "react";
import { AddSpaceForm } from "@/components/space-listing/add-space-form";

export default function NewListingPage() {
  return (
    <div>
      <AddSpaceForm />
    </div>
  );
}
