export const config = {
  apiKey: import.meta.env.VITE_API_KEY || " ",
  authDomain: `${import.meta.env.VITE_PROJECT_ID}.firebaseapp.com`,
  databaseURL: `https://${import.meta.env.VITE_PROJECT_ID}.firebaseio.com`,
  projectId: import.meta.env.VITE_PROJECT_ID,
  storageBucket: `${import.meta.env.VITE_PROJECT_ID}.appspot.com`,
  messagingSender: import.meta.env.VITE_APP_SENDER_ID,
  appId: import.meta.env.VITE_API_ID,
  measurementId: `G-${import.meta.env.VITE_MEASUREMENT_ID}`,
};
