import React from "react";

export const PersonalCardIcon: React.FC<React.SVGAttributes<{}>> = (props) => {
  return (
    <svg
      width="21"
      height="19"
      viewBox="0 0 21 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16.1221 0.75H4.12207C1.91207 0.75 0.12207 2.53 0.12207 4.72V14.78C0.12207 16.97 1.91207 18.75 4.12207 18.75H16.1221C18.3321 18.75 20.1221 16.97 20.1221 14.78V4.72C20.1221 2.53 18.3321 0.75 16.1221 0.75ZM6.62207 4.92C7.89207 4.92 8.93207 5.96 8.93207 7.23C8.93207 8.5 7.89207 9.54 6.62207 9.54C5.35207 9.54 4.31207 8.5 4.31207 7.23C4.31207 5.96 5.35207 4.92 6.62207 4.92ZM10.4921 14.41C10.4021 14.51 10.2621 14.57 10.1221 14.57H3.12207C2.98207 14.57 2.84207 14.51 2.75207 14.41C2.66207 14.31 2.61207 14.17 2.62207 14.03C2.79207 12.35 4.13207 11.02 5.81207 10.86C6.34207 10.81 6.89207 10.81 7.42207 10.86C9.10207 11.02 10.4521 12.35 10.6121 14.03C10.6321 14.17 10.5821 14.31 10.4921 14.41ZM17.1221 14.5H15.1221C14.7121 14.5 14.3721 14.16 14.3721 13.75C14.3721 13.34 14.7121 13 15.1221 13H17.1221C17.5321 13 17.8721 13.34 17.8721 13.75C17.8721 14.16 17.5321 14.5 17.1221 14.5ZM17.1221 10.5H13.1221C12.7121 10.5 12.3721 10.16 12.3721 9.75C12.3721 9.34 12.7121 9 13.1221 9H17.1221C17.5321 9 17.8721 9.34 17.8721 9.75C17.8721 10.16 17.5321 10.5 17.1221 10.5ZM17.1221 6.5H12.1221C11.7121 6.5 11.3721 6.16 11.3721 5.75C11.3721 5.34 11.7121 5 12.1221 5H17.1221C17.5321 5 17.8721 5.34 17.8721 5.75C17.8721 6.16 17.5321 6.5 17.1221 6.5Z"
        fill="currentColor"
      />
    </svg>
  );
};
