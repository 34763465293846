import { SpaceValues } from "@/components/space-listing/schema";
import React, { useState, useRef } from "react";
import "./setupPage.scss";

import { Col, Row } from "antd";
import dayjs from "dayjs";
import objectSupport from "dayjs/plugin/objectSupport"; // ES 2015
import { useFormikContext } from "formik";

import styles from "./imageUpload.module.scss";
import camera from "@/assets/svgs/camera.svg";
import CropModal from "@/components/CropModal";
import ImageUploader from "./image-uploader";

dayjs.extend(objectSupport);

export function StepFourForm({}: any) {
  const { values, setFieldValue: setValue } = useFormikContext<SpaceValues>();

  return (
    <div className="page-one page-three">
      <div className="heading-div">
        {/* <CustomButton
          children={"Save and Exit"}
          type={"link"}
          className="save-continue-btn"
          // onClick={saveContinue}
          // disabled={saving}
        /> */}
      </div>
      <Row gutter={[45, 24]}>
        <Col md={24} xs={24}>
          <h2>Upload your space picture</h2>
          <p className="sub-title">
            Add images of your workspace. For the best results, follow the
            instructions below;
          </p>

          <div className="double-list">
            <ul>
              <li>Upload a minimum of 4 images</li>
              <li>Ensure your images are horizontal</li>
              <li>Screenshots are not allowed</li>
            </ul>
            <ul>
              <li>We recommend uploading up to 10 images</li>
              <li>Ensure you upload coloured photos, not Black and white</li>
              <li>Ensure yor images are of high quality</li>
            </ul>
          </div>
          <ImageUploader
            uploadedImages={values.spacePictures}
            setUploadedImages={(images: unknown) => {
              setValue("spacePictures", images);
            }}
          />
        </Col>
      </Row>
      <hr />
      <h2>Space manager’s details</h2>
      <ul className="text-lg pl-0 ml-0">
        <li>
          Upload a profile picture that your customer can relate to, when they
          send you a message.
        </li>
        <li>
          This could be your space manger’s picture or anyone you decide to be
          the face of your space
        </li>
      </ul>
      <ProfileImageUpload
        image={values.contactImage}
        setImage={(image: unknown) => setValue("contactImage", image)}
      />

      <div className="page-input-field">
        <label>Display Name</label>
        <div className="page-input-filed-input-wrapper">
          <input
            type="text"
            placeholder="Enter owner’s name"
            value={values.displayName}
            onChange={(e) => setValue("displayName", e.target.value)}
          />
        </div>
      </div>
      <div className="page-input-field">
        <label>Email Address</label>
        <div className="page-input-filed-input-wrapper">
          <input
            readOnly
            type="email"
            placeholder="Enter email address"
            value={values.emailAddress}
            onChange={(e) => setValue("emailAddress", e.target.value)}
          />
        </div>
      </div>
    </div>
  );
}

interface ProfileImageUploadProps {
  image: string | null;
  setImage: (image: string | null) => void;
}

const ProfileImageUpload: React.FC<ProfileImageUploadProps> = ({
  image,
  setImage,
}) => {
  const [imageToCrop, setImageToCrop] = useState<string | null>(null);
  const [isCropModalOpen, setIsCropModalOpen] = useState<boolean>(false);

  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImageToCrop(reader.result as string);
        setIsCropModalOpen(true);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleCropComplete = (croppedImage: string) => {
    setImage(croppedImage);
    setIsCropModalOpen(false);
  };

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <div className={styles.circularUploadImage}>
      <div className={styles.imageContainer}>
        {image ? (
          <img src={image} alt="Profile" className={styles.profileImage} />
        ) : (
          <div className={styles.profilePlaceholder}></div>
        )}

        <div className={styles.uploadButton} onClick={handleButtonClick}>
          <img src={camera} alt="camera" />
        </div>
      </div>
      <input
        title="Image upload"
        type="file"
        accept="image/*"
        onChange={handleImageUpload}
        ref={fileInputRef}
        className={styles.uploadInput}
        style={{ display: "none" }}
      />

      {isCropModalOpen && (
        <CropModal
          isOpen={isCropModalOpen}
          closeModal={() => setIsCropModalOpen(false)}
          imageToCrop={imageToCrop}
          onCropComplete={handleCropComplete}
        />
      )}
    </div>
  );
};
