import React, { useState } from "react";
import "./index.scss";
import { CustomButton } from "@/components/customButton";
import { cospire_workspace } from "@/assets/img";
import CustomSelect from "@/components/customSelect";
import CustomSelectTags from "@/components/customSelectTags";
import { useNavigate } from "react-router-dom";
import { useActivitiesQuery } from "@/data/activities";
import { useCategoriesQuery } from "@/data/category";

const SpaceDescription: React.FC = () => {
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState(0);
  const [type, setType] = useState<{
    id: "";
    name: "";
    description: "";
  } | null>(null);
  const [selectedActivities, setSelectedActivities] = useState<string[]>([]);

  const handleGetStarted = () => setCurrentStep(1);

  const { categories } = useCategoriesQuery({});

  const { activities } = useActivitiesQuery({});

  const StepOne = React.memo(() => (
    <form className="form">
      <h2>How would you describe your space?</h2>
      <p className="sub-title">
        Choose from our network of spaces, for your events, shoots, and special
        meetings.
      </p>
      <div className="inputContainer">
        <CustomSelect
          placeHolder="Creative space, Working Space, Outdoor Space, Event Space"
          label="How would you describe your space?"
          value={type ? JSON.stringify(type) : ""}
          onChange={(value) => setType(JSON.parse(value))}
          options={categories.map(({ name, id, description }) => ({
            label: name,
            value: JSON.stringify({ id, name, description }),
          }))}
          showSearch={true}
          optionFilterProp="label"
        />
      </div>
      <div className="buttonContainer">
        <CustomButton
          children="Get Started"
          disabled={!type}
          onClick={handleGetStarted}
          htmlType="button"
          extraStyles={{ width: "100%" }}
        />
      </div>
    </form>
  ));

  const StepTwo = React.memo(() => (
    <form className="form">
      <h2>{type?.name}</h2>
      <p className="sub-title">Select an activity</p>
      <div className="inputContainer">
        <CustomSelectTags
          placeHolder=""
          label={`What type of activities would you like to host in the ${type?.name}?`}
          onChangeTags={(res) => setSelectedActivities(res)}
          value={selectedActivities}
          showSearch={true}
          options={activities.map(({ name, id }) => ({
            label: name,
            value: id,
          }))}
        />
      </div>
      <div className="buttonContainer">
        <CustomButton
          children="Proceed"
          htmlType="submit"
          extraStyles={{ width: "100%" }}
          disabled={selectedActivities.length <= 0}
          onClick={() => {
            navigate("/add-new-space", {
              state: { type: type?.id, activities: selectedActivities },
            });
          }}
        />
      </div>
    </form>
  ));

  return (
    <div className="page-one">
      <div className="section">
        <>{currentStep === 0 ? <StepOne /> : <StepTwo />}</>
        <div
          className="container"
          style={{ backgroundImage: `url(${cospire_workspace})` }}
        />
      </div>
    </div>
  );
};

export default SpaceDescription;
