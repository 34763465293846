import * as Yup from "yup";

const firstStepSchema = Yup.object({
  location: Yup.string().label("Location").optional(),
  countryRegion: Yup.string().required().label("Country / Region"),
  state: Yup.string().required().label("State"),
  city: Yup.string().required().label("City"),
  streetAddress: Yup.string().required().label("Street Address"),
  zipCode: Yup.string().required().label("Zip Code"),
  map: Yup.object({
    lat: Yup.number().optional().default(0),
    lng: Yup.number().optional().default(0),
  }),
});

const secondStepSchema = Yup.object({
  spaceTitle: Yup.string().required(),
  about: Yup.string().required(),
  numberOfPeoples: Yup.number().required().default(0),
  facilities: Yup.array(Yup.string().required()).default([]),
  instantBooking: Yup.boolean().nullable().default(null).required(),
});

const thirdStepSchema = Yup.object({
  currency: Yup.mixed<"USD" | "EUR" | "GBP" | "NGN">()
    .oneOf(["USD", "EUR", "GBP", "NGN"])
    .required(),
  securityDepositPercentage: Yup.number().required(),
  minHoursOption: Yup.string().required(),
  pricing: Yup.array(
    Yup.object({
      hourly: Yup.number().required().default(null).nullable(),
      daily: Yup.number().required().default(null).nullable(),
      weekly: Yup.number().default(null).nullable(),
      monthly: Yup.number().default(null).nullable(),
      days: Yup.array(Yup.string()).min(1).required().default([]),
    })
  ),
  operationalHours: Yup.array(
    Yup.object({
      open: Yup.string().required(),
      close: Yup.string().required(),
      days: Yup.array(Yup.string()).min(1).required().default([]),
    })
  ),
  otherPolicies: Yup.array(Yup.string()).default([]),
});

const fourthStepSchema = Yup.object({
  spacePictures: Yup.array(
    Yup.mixed<{ src: string; title: string; size: string }>().required(
      "File is required"
    )
  ).required(),
  contactImage: Yup.mixed().required().nullable(),
  displayName: Yup.string().required(),
  emailAddress: Yup.string().required(),
});

export type SecondStepValues = {
  spaceTitle: string;
  about: string | undefined;
  numberOfPeoples: number;
  spaceRules: string;
  facilities: string[];
  instantBooking: boolean | null;
};

export type ThirdStepValues = {
  currency: "USD" | "EUR" | "GBP" | "NGN";
  securityDepositPercentage: number;
  minHoursOption: string;
  pricing: {
    hourly: number | null;
    daily: number | null;
    weekly: number | null;
    monthly: number | null;
    days: string[];
  }[];
  operationalHours: {
    open: string;
    close: string;
    days: string[];
  }[];
  otherPolicies: string[];
};

export type SpaceValues = Yup.InferType<typeof firstStepSchema> &
  Yup.InferType<typeof secondStepSchema> &
  ThirdStepValues &
  Yup.InferType<typeof fourthStepSchema>;

export const formSchema = [
  firstStepSchema,
  secondStepSchema,
  thirdStepSchema,
  fourthStepSchema,
];
