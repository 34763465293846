import { Dialog } from "@/components/b/Dialog";
import { CloseIcon } from "@/components/SVGs";
import { Input } from "antd";
import { CustomButton } from "@/components/customButton";
import { useState } from "react";

interface Prop {
  isOpen: boolean;
  closeModal: () => void;
  onSubmitted: (feedback: string) => void;
  loading: boolean;
}

function RejectListingModal({
  isOpen,
  closeModal,
  onSubmitted,
  loading,
}: Prop) {
  const [feedback, setFeedback] = useState<string>("");
  const [error, setError] = useState<string | null>(null);

  const onFinish = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      if (!feedback) setError("Please provide a reason for rejecting");
      console.log("Done", feedback);
      onSubmitted(feedback);
    } catch (error) {
      console.error("Validation failed:", error);
    }
  };
  return (
    <div>
      <Dialog isOpen={isOpen} close={closeModal}>
        <div className="flex justify-center items-center h-screen">
          <div className="grid bg-white shadow-xl py-6 px-8 rounded-2xl lg:w-3/5">
            <button
              title="Close icon"
              className="flex justify-end"
              onClick={closeModal}
            >
              <CloseIcon />
            </button>
            <form onSubmit={onFinish}>
              <div className="my-2">
                <label>Feedback</label>
                <Input.TextArea
                  onChange={(e) => setFeedback(e.target.value)}
                  value={feedback}
                  placeholder={"Reason for Rejecting"}
                  rows={4}
                />
              </div>
              {error && <p className="text-red-500">{error}</p>}
              <CustomButton
                type="primary"
                htmlType="submit"
                className="bg-darkBlue text-white"
                disabled={loading}
                loading={loading}
              >
                Submit Review
              </CustomButton>
            </form>
          </div>
        </div>
      </Dialog>
    </div>
  );
}

export default RejectListingModal;
