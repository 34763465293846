import React, { ReactNode, useState } from "react";
import styles from "./index.module.scss";
import { TabTitle } from "@/utils/titleFunction";
import { BlueSettingsIcon, DefaultSettingsIcon } from "@/components/SVGs";
import dayjs from "dayjs";
import { TickCircleIcon } from "@/components/icons/tick-circle";
import { cn } from "@/lib/utils";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useMyWorkspaceQuery } from "@/data/workspace";
import { formatAddress } from "@/lib/format-address";
import { UserOutlined } from "@ant-design/icons";
import { useRequestSpaceApprovalMutation } from "@/data/approval_request";
import { handleError } from "@/services/networkRequests";
import { toast } from "react-toastify";
import totalOrders from "@/assets/svgs/Total_orders.svg";
import tick from "@/assets/svgs/Total_orders_tick.svg";
import plus from "@/assets/svgs/plus-white.svg";
import { Breadcrumb, Button } from "antd";
import { useMe } from "@/data/user";
import { HOST_PROFILE } from "@/routes/routes";
import { capitalize } from "lodash";
import { Workspace } from "@/types/workspace";

function formatPricingString(pricing?: Workspace["pricing"]): string {
  let result = "";

  pricing?.forEach(({ days, hourly, daily }) => {
    days.forEach((day) => {
      result += `${capitalize(day)}: Hourly - ₦${hourly}, Daily - ₦${daily}\n`;
    });
  });

  return result;
}

interface Stage {
  title: string;
  description: string;
  display?: ReactNode;
  dropdown?: {
    label: string;
    value: string;
  }[];
  action: number;
  valid: boolean;
}

const PreviewSpaceDetails = () => {
  TabTitle("co-spire | Preview Space Details");
  const { me: loggedInUser } = useMe();
  const path = HOST_PROFILE;
  const [activeState, setActiveState] = useState<{
    [key: string]: boolean;
  }>({
    "Stage 1": false,
    "Stage 2": false,
    "Stage 3": false,
    "Stage 4": false,
    "Rules & Security deposit": false,
    "More amenities": false,
  });

  const params = useParams<{ id: string }>();

  const navigate = useNavigate();

  const { workspace, isLoading: loading } = useMyWorkspaceQuery(params.id!, {
    enabled: !!params.id,
  });

  console.log({ workspace });

  const handleStateUpdate = (title: string) => {
    setActiveState((prev) => ({
      ...prev,
      [title]: !prev[title],
    }));
  };

  const handlePageNavigation = (action: number) => {
    navigate(`/my-listings/${params.id}?step=${action}`);
  };

  const { mutateAsync: request, isLoading } = useRequestSpaceApprovalMutation();

  function handleRequest(payload: { workspace: string }) {
    toast.promise(
      request(payload, {
        onSuccess: () => {
          toast.success("Successful");
        },
        onError: (err) => handleError(err),
      }),
      {
        pending: "Updating...",
      }
    );
  }

  const actionCards = [
    {
      head: "Rules & Security deposit",
      subhead: "Add space rules and set your security deposits",
      alt: "rules",
      cardIcon: totalOrders,
      url: `/space-rules/${params.id}`,
      payload: {
        rules: workspace?.rules,
        percentage_of_security_deposit:
          workspace?.percentage_of_security_deposit,
        cancellation_policy_ids: workspace?.cancellation_policy,
      },
      valid: !!workspace?.rules && !!workspace?.percentage_of_security_deposit,
    },
    {
      head: "More amenities",
      subhead: "Add more details for ",
      alt: "more",
      cardIcon: totalOrders,
      url: `/space-amenities/${params.id}`,
      payload: {
        amenities: workspace?.service_amenities,
        access_available: workspace?.access_available ?? [],
        parking_options: workspace?.parking_options ?? {},
      },
      valid: !!workspace?.service_amenities,
    },
    {
      head: "Add-ons",
      subhead: "Change password, Profile image, Email",
      alt: "addons",
      cardIcon: totalOrders,
      url: "",
    },
    // {
    //   head: "Space quantity",
    //   subhead: "Change password, Profile image, Email",
    //   alt: "qty",
    //   cardIcon: tick,
    // },
  ];

  const stages: Stage[] = [
    {
      title: "Stage 1",
      description: "Space type, location",
      dropdown: [
        {
          label: "Search Location",
          value: workspace?.formatted_address ?? "",
        },
        {
          label: "Location",
          value:
            formatAddress({
              country: workspace?.address.country || "",
              city: workspace?.address.city || "",
              state: workspace?.address.state || "",
              street_address: workspace?.address.street_address || "",
              zip: workspace?.address.zip_code || "",
            }) ?? "",
        },
      ],
      action: 1,
      valid: !!workspace?.address && !!workspace?.formatted_address,
    },
    {
      title: "Stage 2",
      description: "Space title, Short description, Amenities and more",
      dropdown: [
        {
          label: "Space title",
          value: workspace?.title ?? "",
        },
        {
          label: "How many guests can this space accomodate?",
          value: String(workspace?.space_size) || "0",
        },
        {
          label: "About the space?",
          value: workspace?.about ?? "",
        },
        {
          label: "What type of facilities do you have?",
          value:
            workspace?.service_amenities?.map((item) => item.name).join(", ") ||
            "",
        },
        {
          label: "Activate instant booking for your space?",
          value: workspace?.instant_booking ? "Yes" : "No",
        },
        {
          label: "What type activities that you’d like to host in the space?",
          value:
            workspace?.activities
              .map((activity) => activity.name)
              ?.join(", ") || "",
        },
      ],
      action: 2,
      valid:
        !!workspace?.title &&
        !!workspace?.space_size &&
        !!workspace.about &&
        workspace?.service_amenities?.length > 0,
    },
    {
      title: "Stage 3",
      description: " Alcohol policy, Pricing, Operation hours ",
      dropdown: [
        {
          label: "External Food Allowed?",
          value: workspace?.food_policy?.is_external_food_allowed
            ? "Yes"
            : "No",
        },
        {
          label: "Alcohol policy",
          value: workspace?.other_policy?.is_alcohol_allowed ? "Yes" : "No",
        },
        {
          label: "Pet policy",
          value: workspace?.other_policy?.is_pet_allowed ? "Yes" : "No",
        },
        {
          label: "Smoking policy",
          value: workspace?.other_policy?.is_smoking_allowed ? "Yes" : "No",
        },
        {
          label: "Noise policy",
          value: workspace?.other_policy?.is_music_allowed ? "Yes" : "No",
        },
        {
          label: "Adult Filming",
          value: workspace?.other_policy?.is_adult_filming_allowed
            ? "Yes"
            : "No",
        },
        {
          label: "Pricing",
          value:
            formatPricingString(workspace?.pricing) || "No pricing available",
        },
        {
          label: "Minimum hours",
          value: String(workspace?.minimum_hours) || "",
        },
        {
          label: "Operating Hours",
          value: Object.values(workspace?.working_hours ?? {})
            .map(
              (item: { open_time: string; close_time: string }, index) =>
                `${capitalize(
                  Object.keys(workspace?.working_hours ?? {}).at(index)
                )} ${
                  item?.open_time !== "closed" && item?.close_time !== "closed"
                    ? dayjs(item.open_time, "HH:mm").format("h:mm A") +
                      "-" +
                      dayjs(item.close_time, "HH:mm").format("h:mm A")
                    : "Closed"
                }`
            )
            .join("\n"),
        },
      ],
      action: 3,
      valid:
        !!workspace?.minimum_hours &&
        workspace?.food_policy?.is_external_food_allowed !== undefined &&
        !!workspace?.other_policy &&
        !!workspace.pricing,
    },
    {
      title: "Stage 4",
      description: "Photos, Contact details",
      action: 4,
      display: (
        <div className="grid gap-2">
          <p className="text-base font-medium text-darkBlue">Contact Details</p>
          <div className="flex items-center gap-2">
            {workspace?.host?.avatar ? (
              <img
                src={workspace?.host?.avatar}
                alt={workspace?.host?.name}
                className="rounded-full w-12 h-12"
              />
            ) : (
              <UserOutlined className="rounded-full flex items-center justify-center w-12 h-12 border" />
            )}

            <div className="grid">
              <p className="font-medium">{workspace?.host?.name}</p>
              <p>{workspace?.space_contacts?.space_email}</p>
            </div>
          </div>
        </div>
      ),
      valid:
        !!workspace?.photos?.length &&
        !!workspace?.host &&
        !!workspace?.space_contacts,
    },
  ];

  const progress =
    (stages.filter((stage) => stage.valid).length / stages.length) * 100;

  const disabled = stages.some((stage) => !stage.valid);
  const allStagesValid = stages.slice(0, 4).every((stage) => stage.valid);

  if (loading) {
    return <div>Loading...</div>;
  }

  const links = [
    {
      title: (
        <Link
          to={
            loggedInUser?.role_type === "Host" ? `${path}/add-new-space` : "/"
          }
        >
          Listing
        </Link>
      ),
    },
    {
      title: "Listing details",
      link: "",
    },
  ];

  return (
    <>
      <div className={styles.page}>
        <Breadcrumb
          separator=">"
          items={links}
          className={styles.breadcrumbs}
        />
        <section className={styles.container}>
          <div className={styles.progressContainer}>
            {progress === 100 ? (
              <h3 className={styles.title}>Listing details</h3>
            ) : (
              <>
                <h3 className={styles.title}>
                  {disabled
                    ? "You’ve made progress!"
                    : workspace?.approved_space
                    ? "Your listing has been published!"
                    : "Your Listing Is 100% Done!"}
                </h3>
                <div className={styles.progressBarWrapper}>
                  <div className={styles.progressBarBackground}>
                    <div
                      className={styles.progressBar}
                      style={{ width: `${progress}%` }}
                    ></div>
                  </div>
                </div>
                <p className={styles.description}>
                  {progress !== 100
                    ? `Your listing is ${progress}% done.`
                    : "Well done. Now let’s publish your listing."}
                </p>
              </>
            )}
          </div>
        </section>
        <section className="grid border border-alpha-blue rounded-2xl">
          <div className={styles.stages}>
            {allStagesValid && (
              <div className={styles.detailsActions}>
                <div className={cn(styles.titleContainer, "border-b")}>
                  <h3 className={styles.title}>Additional details & actions</h3>
                  <p className={styles.description}>
                    Add more details about your space so that your customers can
                    find you faster. Profiles with complete details appear first
                    during search.
                  </p>
                </div>
                <div className={styles.cardsWithIcon}>
                  {actionCards.map(
                    ({ head, subhead, alt, url, payload, valid }, index) => (
                      <div
                        className={
                          alt === "addons" ? styles.cardDisabled : styles.card
                        }
                        key={alt}
                        onClick={() => handleStateUpdate(head)}
                      >
                        <div className={styles.textBox}>
                          <p className={styles.head}>{head}</p>
                          <p className={styles.subhead}>{subhead}</p>
                        </div>
                        {!valid ? (
                          <img src={totalOrders} alt={alt} />
                        ) : (
                          <img src={tick} alt={alt} />
                        )}
                        <div className={styles.edit_button}>
                          <button
                            type="button"
                            onClick={() => navigate(url, { state: payload })}
                          >
                            Edit
                          </button>
                        </div>
                      </div>
                    )
                  )}
                </div>
              </div>
            )}
            <div className={styles.mainHeaderRow}>
              <div className={cn(styles.titleContainer, "border-b")}>
                <h3 className={styles.title}>Listing Checklist</h3>
                <p className={styles.description}>
                  Publish your listing when you complete all stages
                </p>
              </div>
              {/* <button
                disabled={disabled}
                type="submit"
                className={cn(
                  styles.btn,
                  "!bg-primary disabled:!bg-opacity-40"
                )}
                onClick={() =>
                  navigate("/my-listing/preview/space/" + params.id)
                }
              >
                Preview
              </button> */}
            </div>
            <div className="grid justify-center gap-y-2 divide-y w-[71.04vw]">
              {stages.map((stage, index) => (
                <React.Fragment key={index}>
                  <div className="flex justify-between items-center py-2">
                    <div className="flex items-center justify-center gap-4">
                      <TickCircleIcon
                        stroke="#fff"
                        className={cn(
                          stage.valid
                            ? "text-white fill-primary"
                            : "text-[#A59797]"
                        )}
                      />

                      <div>
                        <p className="font-bold text-2xl">{stage.title}</p>
                        <p>{stage.description}</p>
                      </div>
                    </div>

                    <button
                      type="button"
                      onClick={() => handleStateUpdate(stage.title)}
                    >
                      {activeState[stage.title] ? (
                        <BlueSettingsIcon />
                      ) : stage.valid ? (
                        <DefaultSettingsIcon />
                      ) : (
                        <Button
                          children="Continue"
                          className={styles.btnContinue}
                        />
                      )}
                    </button>
                  </div>

                  {activeState[stage.title] && (
                    <div className="grid p-4 border-t gap-4">
                      {stage.display && <div className="">{stage.display}</div>}

                      {stage?.dropdown?.map((item, index) => (
                        <div className="grid gap-2" key={index}>
                          <p className="text-base font-medium text-darkBlue">
                            {item.label}
                          </p>
                          <p className="whitespace-pre-wrap">{item.value}</p>
                        </div>
                      ))}

                      <div className="flex flex-start">
                        <button
                          type="button"
                          className={cn(styles.btn, "!bg-primary")}
                          onClick={() => handlePageNavigation(stage.action)}
                        >
                          Edit
                        </button>
                      </div>
                    </div>
                  )}
                </React.Fragment>
              ))}
              {/* {progress === 100 && (<div className={cn(styles.mainFooterRow, "bg-[#F7F9FB]")}>
                <div className={styles.titleContainer}>
                  <h3 className={styles.title}>Co-hosts</h3>
                  <p className={styles.description}>You can add co-host to help you manage your account.</p>
                </div>
                <button
                  disabled={disabled}
                  type="submit"
                  className={cn(
                    styles.btnCoHost,
                    "!bg-primary disabled:!bg-opacity-40"
                  )}
                // onClick={() =>
                //   navigate("/my-listing/preview/space/" + params.id)
                // }
                >
                  <span>Add a co-host</span> <img src={plus} alt="" />
                </button>
              </div>
              )} */}
              <div className="flex justify-end pt-8">
                <button
                  disabled={progress !== 100 || isLoading}
                  type="button"
                  className={cn(
                    styles.btn,
                    "!bg-primary disabled:!bg-opacity-40",
                    "w-[150px]"
                  )}
                  onClick={() => handleRequest({ workspace: params.id! })}
                >
                  {isLoading ? "Requesting..." : "Publish"}
                </button>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default PreviewSpaceDetails;
