import CustomTable from "@/components/tableComponent/Table/Table";
import { ApprovalRequest } from "@/types/approval_request";
import { columns } from "./column";

function AllListing({ requests }: { requests: ApprovalRequest[] }) {
  return (
    <div className=" overflow-x-scroll w-full">
      <div className="min-w-[500px]">
        <CustomTable columns={columns} data={requests} />
      </div>
    </div>
  );
}

export default AllListing;
