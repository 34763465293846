import React, { Fragment, useState, useEffect } from "react";
import styles from "./index.module.scss";
import { CustomButton } from "@/components/customButton";
import { image, removeIcon } from "@/assets/img";
import useCheckIsMobileScreen from "@/hooks/useCheckIsMobileScreen";
import arrowBack from "../../../../../assets/svgs/arrow-small-left.svg";
import more from "@/assets/svgs/more.svg";
import Star from "@/assets/svgs/Vector.svg";
import Chekbadge from "@/assets/svgs/check-badge.svg";
import { Divider, Modal, Tag } from "antd";
import workspaceImage from "@/assets/img/Space3.webp";
import { Table, Pagination } from "antd";
import CustomTag from "@/components/customTag";
import TableNav from "../../../components/tableComponent/tableHeader/tableNav";
import { Spacer } from "../../../components/spacer";
import useBooking from "./useBooking";
import CustomTable from "../../../components/tableComponent/Table/Table";
import { useNavigate } from "react-router-dom";
import { getAllBookings } from "@/services/networkRequests/bookingService";
import CustomDropDown from "@/components/a/Modals/customDropDown/CustomDropDown";
import { Link } from "react-router-dom";
import Preview from "@/components/Preview/Preview";
import bookingPreview from "../../../assets/svgs/bookinPreview.svg";
import { default as dayjs } from "dayjs";
// import BookingCalendar from "@/components/BookingCalendar";

import BookingCalendar from "../../../components/BookingCalendar/index";
import { HOST_BOOKING, WORKSPACES } from "@/routes/routes";
import { BOOKING_DETAILS } from "@/routes/routes";
import map from "../../../assets/svgs/map-pin-black.svg";
import { useBookingHistoryQuery } from "@/data/booking";
import { useMe } from "@/data/user";
const Bookings: React.FC = () => {
  const navigate = useNavigate();
  const isMobile = useCheckIsMobileScreen();
  const {
    tabLists,
    currentTab,
    onTabClick,
    paymentStatus,
    onSelectPaymentStatus,
    onSearch,
    getColor,
    data,
    isLoading,
    bookings,
    getAllBookingForHost,
    customerBookingsData,
    bookTest,
    hostWorkSpaces,
  } = useBooking();

  const hostBookings = getAllBookingForHost(hostWorkSpaces, bookings);
  const { me: loggedInUser } = useMe();
  const [viewMore, setViewMore] = useState(true);
  const [showTable, setShowTable] = useState(false);
  const hostBookingId = hostBookings.map((item: any) => item.id);
  const detailsLink = `/host-profile/bookings/hostBooking_details`;
  const customerbookingData = customerBookingsData;

  const [visible, setVisible] = useState(false);
  const handleBookingSchedule = () => {
    setVisible(true);
  };

  const closeModal = () => {
    setVisible(false);
  };

  const items = [
    {
      key: 1,
      label: (
        <Link to={detailsLink} className={styles.dropDownText}>
          View Booking details
        </Link>
      ),
    },
    {
      key: 2,
      label: (
        <Link to="#" className={styles.dropDownText}>
          Cancle Booking
        </Link>
      ),
    },
    {
      key: 3,
      label: (
        <Link to="#" className={styles.dropDownText}>
          Delete Booking
        </Link>
      ),
    },
  ];

  const handleShowTable = () => {
    setViewMore(!viewMore);
    setShowTable(!showTable);
  };

  const handleViewMore = (id: string) => {
    // setViewMore(!viewMore);
    // setShowTable(!showTable);
    navigate(`booking_details/${id}`);
  };
  // 20223-08-20 13:20:10.633

  const columns = [
    {
      title: (
        <span style={{ color: "#131416", textTransform: "uppercase" }}>
          Listing
        </span>
      ),
      dataIndex: "companyInfo",
      key: "companyInfo",
      render: (companyInfo: { companyName: string; created_ts: any }) => (
        <div className={styles.workspaceView}>
          {/* <img src={image} alt="" /> */}
          <div className={`${styles.workspaceInfo}`}>
            <h1 className={`${styles.name}`}>{companyInfo.companyName}</h1>
            {/* <div>
              <img src={map} alt="" />
              <p>Lagos Nigeria</p>
            </div> */}
            {/* <p>Booked on :{companyInfo.start_date}</p> */}
          </div>
        </div>
      ),
    },
    {
      title: (
        <span style={{ color: "#131416", textTransform: "uppercase" }}>
          Start date
        </span>
      ),
      dataIndex: "start_date",
      key: "start_date",
      render: (start_date: string) => (
        <span className={`${styles.amount}`}>{`${dayjs(start_date).format(
          "MMMM D, YYYY h:mm A",
        )}`}</span>
      ),
    },
    {
      title: (
        <span style={{ color: "#131416", textTransform: "uppercase" }}>
          Activity
        </span>
      ),
      dataIndex: "activity",
      key: "activity",
      render: (activity: string) => (
        <span className={`${styles.amount}`}>{`${activity}`}</span>
      ),
    },
    {
      title: (
        <span style={{ color: "#131416", textTransform: "uppercase" }}>
          Amount
        </span>
      ),
      dataIndex: "amount",
      key: "amount",
      render: (amount: number) => (
        <span className={`${styles.amount}`}>{`${amount}`}</span>
      ),
    },
    {
      title: (
        <span style={{ color: "#131416", textTransform: "uppercase" }}>
          Status
        </span>
      ),
      dataIndex: "payment_status",
      key: "payment_status",
      render: (text: string) => (
        <CustomTag
          color={getColor(text)}
          text={text === "pending check" ? "Pending" : text}
        />
      ),
    },
    {
      title: (
        <span style={{ color: "#131416", textTransform: "uppercase" }}>
          More
        </span>
      ),
      dataIndex: "id",
      key: "id",
      render: (id: string, text: string, record: any) => {
        if (loggedInUser?.role_type === "Host") {
          return (
            <CustomDropDown
              dropDownProps={{
                items: [
                  {
                    key: 1,
                    label: (
                      <p
                        onClick={() =>
                          navigate(`${HOST_BOOKING}/hostBooking_details/${id}`)
                        }
                        className={styles.dropDownText}
                      >
                        View Booking details
                      </p>
                    ),
                  },
                  // {
                  //   key: 2,
                  //   label: (
                  //     <Link to="#" className={styles.dropDownText}>
                  //       Cancle Booking
                  //     </Link>
                  //   ),
                  // },
                  // {
                  //   key: 3,
                  //   label: (
                  //     <Link to="#" className={styles.dropDownText}>
                  //       Delete Booking
                  //     </Link>
                  //   ),
                  // },
                ],
              }}
              placement="bottomRight"
            >
              <img src={more} alt="more" style={{ cursor: "pointer" }} />
            </CustomDropDown>
          );
        } else if (record.status === "Cancelled") {
          return <p className={`${styles.disabled}`}>View More</p>;
        } else {
          return (
            <p
              onClick={() => handleViewMore(id)}
              className={`${styles.viewMore}`}
            >
              View More
            </p>
          );
        }
      },
    },
  ];

  const pageSize = 5;

  const rowClassName = (record: any) => {
    if (record.status === "Cancelled") {
      return `${styles.disabledRow}`;
    }
    return ""; // No special class for other rows
  };

  return (
    <div className={styles.page}>
      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "end",
            paddingBottom: "39px",
          }}
        >
          <CustomButton
            children={"Book a space"}
            onClick={() => {
              navigate(WORKSPACES);
            }}
          />
        </div>
        <div className={styles.heading}>
          <div>
            <h2 className={styles.title}>Booking History</h2>
          </div>
          {isMobile ? (
            <div className={styles.remove}>
              <div className={styles.icon}>
                <img src={removeIcon} alt="remove" />
                <p className={styles.subtitle}>Remove</p>
              </div>
            </div>
          ) : (
            <Fragment>
              {loggedInUser?.role_type === "Host" && (
                <div>
                  {/* <CustomButton
                      onClick={handleBookingSchedule }
                      children={"Booking Schedule"}
                      className={styles.btn}
                    /> */}
                  <Modal
                    title="Booking Schedule"
                    open={visible}
                    onCancel={closeModal}
                    footer={null}
                    className={styles.bookingCalendarModal}
                    width={"100%"}
                  >
                    <BookingCalendar />
                  </Modal>
                  <CustomButton
                    children={"Export CSV"}
                    className={styles.btn}
                  />
                </div>
              )}
            </Fragment>
          )}
        </div>
        <Divider orientation="left"></Divider>
        <div>
          <TableNav
            tabLists={tabLists}
            onTabChange={onTabClick}
            currentTab={currentTab}
            options={paymentStatus}
            onSearch={onSearch}
            selectPlaceHolder={"Date"}
            searchPlaceHolder={"Search Bookings"}
            onSelectPaymentStatus={onSelectPaymentStatus}
          />
          <Spacer height={32} />
          <div className={styles.tableCtrl}>
            <CustomDropDown dropDownProps={{ items }}></CustomDropDown>
            {isLoading ? (
              <Preview
                image={bookingPreview}
                bookingText="No bookings yet!"
                labelText="Your recent bookings will be displayed here."
              />
            ) : (
              <CustomTable
                columns={columns}
                // @ts-ignore
                data={
                  loggedInUser?.role_type === "Host"
                    ? hostBookings
                    : customerbookingData
                }
                rowClassName={rowClassName}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Bookings;
