import React from "react";
import styles from "./index.module.scss";
import { Select, Tag } from "antd";
import type { SelectProps } from 'antd';

interface Option {
   label: React.ReactNode;
   value: string;
 }

interface CustomSelectProps {
   key?: any;
   options?: Option[];
   onSelect?: (value: string) => void;
   mode?: string;
   error?: string | undefined | number | boolean;
   defaultValue?: string[] | null | undefined;
   label?: string;
   name?: string;
   value?: string[];
   secLabel?: string;
   size?: "large" | "middle" | "small";
   extraStyles?: React.CSSProperties;
   className?: string;
   placeHolder?: string;
   optionFilterProp?: string;
   showSearch?: boolean;
   disabled?: boolean;
   onSearch?: (value: string) => void;
   onChange?: (value: string) => void;
   onBlur?: () => void;
   // filterOption?: boolean;
   allowClear?: boolean;
   children?: React.ReactNode;
   onChangeTags?: (values: string[]) => void;
}

const tagRender = (props: any) => {
   const { label, closable, onClose } = props;
 
   return (
     <Tag
       color="blue"
       closable={closable}
       onClose={onClose}
       style={{
         borderRadius: "3px",
         backgroundColor: "#E6F7FF",
         border: "1px solid #91D5FF",
         color: "#1890FF",
       }}
     >
       {label}
     </Tag>
   );
 };
  
const CustomSelect: React.FC<CustomSelectProps> = ({
   key,
   options,
   mode,
   onSelect,
   value,
   label,
   secLabel,
   size,
   extraStyles,
   className,
   placeHolder,
   optionFilterProp,
   onChange,
   onSearch,
   showSearch = true,
   disabled = false,
   // filterOption = true,
   error,
   onBlur,
   defaultValue,
   allowClear = true,
   children,
   onChangeTags
}) => {
   const handleSelectChange = (selectedValues: string[]) => {
      if (onChangeTags) {
         onChangeTags(selectedValues);
      }

      if (onChange) {
         onChange(selectedValues.join(',')); 
      }
   };

   const handleFilter = (inputValue: string, option?: { label?: React.ReactNode; value?: string }): boolean => {
      return (option?.label?.toString().toLowerCase().includes(inputValue.toLowerCase())) || false;
   };

   return (
      <div className={styles.selectContainer}>
         {label && <label className={styles.label}>{label}</label>}
         {secLabel && <p className={styles.secondaryLabel}>{secLabel}</p>}
         <Select
            key={key}
            showSearch={showSearch}
            mode="tags"
            filterOption={handleFilter}
            virtual={false}
            defaultValue={defaultValue}
            value={value}
            allowClear={allowClear}
            size={size}
            bordered={false}
            style={extraStyles}
            className={className ? className : styles.select}
            placeholder={placeHolder}
            optionFilterProp={optionFilterProp}
            onChange={handleSelectChange}
            onSelect={onSelect}
            onBlur={onBlur}
            onSearch={onSearch}
            disabled={disabled}
            options={options}
            tagRender={tagRender}
            dropdownStyle={{ overflow: 'hidden' }}
         >
            {children}
         </Select>
         {error && typeof error === "string" && (
            <p className={styles.error}>{error}</p>
         )}
      </div>
   );
};

export default CustomSelect;
