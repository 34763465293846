import React from "react";
import { DatePicker } from "antd";
import dayjs from "dayjs";
import { Checkbox } from "antd";
import { CustomInputField } from "../customInput";
import TimePickerComponent from "@/components/customTimePicker";
import CustomerDatePicker from "@/components/confirmDatePicker/customerDatePicker";
import styles from "./index.module.scss";
import { DateObject } from "react-multi-date-picker";
import verify from "@/assets/img/verify_lock.png";

import { Formik, FormikHelpers } from "formik";
import * as Yup from "yup";
import Button from "@/components/c/Button";
import { CustomButton } from "../customButton";
import { Workspace } from "@/types/workspace";

export interface FormValues {
  num_of_people: number;
  re_currency?: boolean;
  start_date?: Date;
  end_date?: Date;
  dates: Date[];
  start_time?: string;
  end_time?: string;
}

const validationSchema = Yup.object().shape({
  num_of_people: Yup.number().required("Number of people is required"),
  re_currency: Yup.boolean().optional().default(false),
  start_date: Yup.date(),
  end_date: Yup.date(),
  dates: Yup.array().of(Yup.date()).default([]),
  start_time: Yup.string(),
  end_time: Yup.string(),
});

const { RangePicker } = DatePicker;

export type BookingFormProps = {
  handleSubmit: (values: FormValues) => void;
  isPreviewMode: boolean;
  workspace: Workspace;
};

const BookingForm: React.FC<BookingFormProps> = ({
  handleSubmit,
  isPreviewMode,
  workspace: { pricing, instant_booking, has_hourly_price },
}) => {
  const initialValues: FormValues = {
    num_of_people: 1,
    start_date: new Date(),
    end_date: new Date(),
    dates: [],
    start_time: "10:00",
    end_time: "16:00",
  };

  const onSubmit = (
    values: FormValues,
    { setSubmitting }: FormikHelpers<FormValues>
  ) => {
    setTimeout(() => {
      console.log(values);
      handleSubmit({ ...values });
      setSubmitting(false);
    }, 500);
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ isSubmitting, errors, handleSubmit, values, touched, ...formik }) => (
        <form onSubmit={handleSubmit}>
          <div className={styles.styledForm}>
            <CustomInputField
              label="How many people?"
              placeholder="1"
              name="num_of_people"
              value={values.num_of_people}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={touched.num_of_people && errors.num_of_people}
              type="number"
            />
            {has_hourly_price ? (
              <div>
                <Checkbox
                  checked={values.re_currency}
                  onChange={() => {
                    formik.setFieldValue("re_currency", !values.re_currency);
                  }}
                >
                  Book a recurring booking
                </Checkbox>
              </div>
            ) : null}
            <div className={styles.formRowDate}>
              <label>Date and Time</label>
              {!values.re_currency ? (
                <RangePicker
                  format={"YYYY/MM/DD"}
                  suffixIcon={null}
                  className={styles.paymentBtn}
                  name="date"
                  onChange={(value) => {
                    formik.setFieldValue("start_date", value?.[0]);
                    formik.setFieldValue("end_date", value?.[1]);
                  }}
                  value={[dayjs(values.start_date), dayjs(values.end_date)]}
                />
              ) : (
                <CustomerDatePicker
                  name="dates"
                  onChange={(dates: DateObject | DateObject[] | null) => {
                    formik.setFieldValue("dates", dates);
                    console.log({ dates });
                  }}
                  className={styles.paymentBtn}
                  value={values.dates}
                />
              )}
            </div>
            <div className={styles.formRow}>
              <TimePickerComponent
                placeholder="10:00"
                defaultOpenValue={dayjs("10:00", "HH:mm")}
                selectedTime={dayjs(values.start_time, "HH:mm")}
                onTimeChange={(instance) => {
                  const formattedTime = instance && instance.format("HH:mm");
                  formik.setFieldValue("start_time", formattedTime);
                }}
                name="startTime"
                className={styles.paymentBtn}
              />
              <TimePickerComponent
                placeholder="16:00"
                defaultOpenValue={dayjs("16:00", "HH:mm")}
                selectedTime={dayjs(values.end_time, "HH:mm")}
                onTimeChange={(instance) => {
                  const formattedTime = instance && instance.format("HH:mm");
                  formik.setFieldValue("end_time", formattedTime);
                }}
                name="endTime"
                className={styles.paymentBtn}
              />
            </div>
          </div>
          <div className={styles.formInfo}>
            <img src={verify} alt="" />
            <p>
              To protect your payment, never transfer money or communicate
              outside of the Co-spire website.
            </p>
          </div>
          <div className={"mt-4"}>
            <CustomButton
              extraStyles={{ width: "100%" }}
              disabled={isPreviewMode}
              htmlType="submit"
            >
              Book now
            </CustomButton>
          </div>
        </form>
      )}
    </Formik>
  );
};

export default BookingForm;
