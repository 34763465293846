import ModalWrapper from "@/components/subscriptions/ModalWrapper";
import { SuccessIcon, SpinIcon } from "@/components/SVGs";

interface Prop {
  isOpen: boolean;
  closeModal: () => void;
  action: string;
}
const AddonPopup: React.FC<Prop> = ({ isOpen, closeModal, action }) => {
  return (
    <ModalWrapper isOpen={isOpen}>
      <div className="grid place-items-center bg-white shadow-xl py-6 px-8 rounded-2xl">
        {action === "loading" ? (
          <div className="grid gap-8 place-items-center">
            <SpinIcon />
            <div className="grid gap-4 place-items-center">
              <h3 className="text-2xl font-medium"> Hold on</h3>
              <p>We’re processing your payment</p>
            </div>
          </div>
        ) : action === "success" ? (
          <div className="grid gap-8 place-items-center p-6">
            <SuccessIcon />
            <p>You are now subscribed!</p>
            <button
              onClick={closeModal}
              className="px-4 py-2 rounded-2xl bg-darkBlue text-white w-full"
            >
              Done
            </button>
          </div>
        ) : null}
      </div>
    </ModalWrapper>
  );
};

export default AddonPopup;
