import React, { useState } from "react";
import styles from "./index.module.scss";
import  cospire_workspace  from "@/assets/img/become_space_owner.png";
import { CustomInputField } from "../../components/customInput";
import { CustomButton } from "../../components/customButton";
import { Link, useNavigate } from "react-router-dom";
import CustomPasswordBox from "../../components/customPasswordBox";
import { Formik, FormikHelpers, FormikProps } from "formik";
import { createUser } from "../../redux/actions/authActions";
import { useAppDispatch } from "../../redux/hook";
import { REGISTER_HOST_SCHEMA } from "../../validationSchemas/schema";
import { handleError } from "../../services/networkRequests/error";
import { HOME_URL, LOGIN, VERIFY_EMAIL } from "../../routes/routes";
import CustomSelect from "@/components/customSelect";
import { TabTitle } from "@/utils/titleFunction";
import PhoneInput from "@/components/phoneNumberInput/CustomPhoneNoInput";
import { Checkbox } from "antd";
import { reset } from "@/redux/rootReducer";
import { sendUserVerifyEmail } from "../../services/networkRequests/userService";
import { RegisterSpaceOwnerInput } from "@/types/space-owner";

const initialValues: RegisterSpaceOwnerInput = {
  firstName: "",
  lastName: "",
  email: "",
  jobRole: "",
  password: "",
  confirmPassword: "",
  phone_number: "",
  agree: false,
};

const HostRegisterForm: React.FC = () => {
  TabTitle("co-spire | WorkSpace");

  const privacyLink =
    "https://drive.google.com/file/d/1BbY8lTb3EwGlsi98fBq4-jtYaxO83SZT/view?usp=sharing";
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [agree, setAgree] = useState(false);

  const handleAgreeChange = (e: any) => {
    setAgree(e.target.checked);
    console.log(agree);
  };
  const handleFormSubmit = async (values: RegisterSpaceOwnerInput) => {
    setIsLoading(true);
    // const firstName = values.fullName.split(" ")[0];
    // const lastName = values.fullName.split(" ")[1];
    const payload = {
      user_email: values.email,
      user_name_first: values.firstName,
      user_name_last: values.lastName,
      password: values.password,
      user_job_title: values.jobRole,
      phone_number: values.phone_number,
      role_type: "Host",
    };

    try {
      const response = await dispatch(createUser(payload));
      if (response?.meta?.requestStatus === "fulfilled") {
        sendEmail(payload.user_email);
        setIsLoading(false);
        navigate(LOGIN);
      }
    } catch (error) {
      setIsLoading(false);
      handleError(error);
    } finally {
      setIsLoading(false);
      reset();
    }
  };

  const position = [
    { label: "Founder/CEO", value: "Founder/CEO" },
    { label: "Managerial", value: "Managerial" },
    { label: "others", value: "others" },
  ];

  const sendEmail = async (email: string) => {
    if (email) {
      const payload = { userEmail: email, callbackUrl: HOME_URL + LOGIN };
      await sendUserVerifyEmail(payload);
      navigate(VERIFY_EMAIL);
    }
  };

  return (
    <>
      <div className={styles.page}>
        <div className={styles.section}>
         
          <Formik
            initialValues={initialValues}
            validationSchema={REGISTER_HOST_SCHEMA}
            onSubmit={(
              values: RegisterSpaceOwnerInput,
              { setSubmitting }: FormikHelpers<RegisterSpaceOwnerInput>
            ) => {
              handleFormSubmit(values);
              setTimeout(() => setSubmitting(false), 400);
            }}
          >
            {(formik: FormikProps<RegisterSpaceOwnerInput>) => {
              const disabled = Object.keys(formik.errors).length > 0;
              return (
                <form className={styles.form}>
                  <div className={styles.title}>
                    <h1 className={styles.heading}>List Your Space</h1>
                    <p className={styles.subHeading}>
                      CO-SPIRE users are searching for Spaces like yours
                    </p>
                  </div>

                  <div className={styles.inputContainer}>
                    <div className={styles.fullName}>
                      <CustomInputField
                        type={"text"}
                        label={"FirstName"}
                        placeholder="Your firstname"
                        name={"firstName"}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.firstName && formik.errors.firstName
                        }
                      />
                      <CustomInputField
                        type={"text"}
                        label={"LastName"}
                        placeholder="Your Lastame"
                        name={"lastName"}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.lastName && formik.errors.lastName
                        }
                      />
                    </div>

                    <CustomInputField
                      type={"email"}
                      label={"Email Address"}
                      placeholder="Enter your Email Address"
                      name={"email"}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.touched.email && formik.errors.email}
                    />

                    <PhoneInput
                      label="Phone Number"
                      placeholder="Enter Phone Number"
                      value={formik.values.phone_number}
                      onChange={(value) => {
                        formik.setFieldValue("phone_number", value);
                      }}
                      error={
                        formik.touched.phone_number &&
                        formik.errors.phone_number
                      }
                      onBlur={() => formik.setFieldTouched("phone_number")}
                    />

                    <CustomSelect
                      options={position}
                      placeHolder="Select your Position"
                      label={"Position at the Company"}
                      onChange={(e) => {
                        formik.setFieldValue("jobRole", e);
                      }}
                    />

                    <CustomPasswordBox
                      type={"password"}
                      name={"password"}
                      label="Password"
                      placeholder="Enter your password"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.touched.password && formik.errors.password}
                    />

                    <CustomPasswordBox
                      type={"password"}
                      name={"confirmPassword"}
                      label="Confirm Password"
                      placeholder="Re-Enter your password"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.confirmPassword &&
                        formik.errors.confirmPassword
                      }
                    />
                  </div>
                  <div className={styles.agreement}>
                    <Checkbox name="agree" onChange={handleAgreeChange}>
                      By submitting your details you agree to our{" "}
                      <Link to={privacyLink} style={{ color: "#0047AB" }}>
                        Terms of use
                      </Link>{" "}
                      and
                      <Link to={privacyLink} style={{ color: "#0047AB" }}>
                        {" "}
                        privacy policy
                      </Link>
                    </Checkbox>
                  </div>
                  <div className={styles.buttonContainer}>
                    <CustomButton
                      children={"Proceed"}
                      onClick={formik.handleSubmit}
                      htmlType="submit"
                      loading={formik.isSubmitting || isLoading}
                      extraStyles={{ width: "100%" }}
                      disabled={
                        !agree ||
                        disabled ||
                        formik.isSubmitting ||
                        !formik.dirty ||
                        isLoading
                      }
                    />
                  </div>
                </form>
              );
            }}
          </Formik>

          <div
            className={styles.container}
            style={{ backgroundImage: `url(${cospire_workspace})` }}
          />
        </div>
      </div>
    </>
  );
};

export default HostRegisterForm;
