import React, { ChangeEvent } from "react";
import Select from "react-select";

interface Option {
  name: string;
  value: string;
  disabled?: boolean;
}
interface SelectInputProps {
  label: string;
  options: Option[];
  value: string;
  onChange: (event: ChangeEvent<HTMLSelectElement>) => void;
  name?: string;
}
const SelectInput: React.FC<SelectInputProps> = ({
  label,
  options,
  value,
  onChange,
  name,
}) => {
  const formattedOptions = options.map((item) => ({
    value: item.value,
    label: item.name,
    isDisabled: item.disabled,
  }));

  return (
    <div className="page-input-field">
      <label>{label}</label>
      <Select
        options={formattedOptions}
        value={formattedOptions.find((option) => option.value === value)}
        onChange={(selectedOption) => onChange({
          target: { name, value: selectedOption?.value }
        } as ChangeEvent<HTMLSelectElement>)}
        name={name}
        isSearchable={false}
        styles={{
          option: (provided) => ({
            ...provided,
            padding: "8px 12px",
          }),
        }}
      />
    </div>
  );
};

export default SelectInput;
