import React, { Fragment, useMemo, useState } from "react";
import styles from "./index.module.scss";
import { CustomButton } from "@/components/customButton";
import { Breadcrumb, Divider } from "antd";
import { pin, removeIcon, featured_space, Revenue } from "@/assets/img";
import verified from "@/assets/img/verified_orange.png";
import checkBadge from "@/assets/svgs/check-badge-grey.svg";
import trash from "@/assets/svgs/Trash.svg";
import img1 from "@/assets/img/myListingBanner1.png";
import img2 from "@/assets/img/myListingBanner2.png";
import img3 from "@/assets/img/myListingBanner3.png";
import { StarFilled } from "@ant-design/icons";
import useCheckIsMobileScreen from "@/hooks/useCheckIsMobileScreen";
import useHostWorkspaces from "./useHostWorkspaces";
import { Link, useNavigate } from "react-router-dom";
import NoDataPage from "@/components/noDataPage/noDataPage";
import Splider from "@/components/splide/splide";
import Button from "@/components/c/Button";
import { Search } from "../../../components/Search/Search";
import { useMyWorkspacesQuery } from "@/data/workspace";
import { cn } from "@/lib/utils";
import placeholder from "../../../assets/placeholders/workspace.svg";
import { calculateAverage, minimumPrice } from "@/utils/helpers";
import { Workspace } from "@/types/workspace";
import { transformPricingArray } from "@/components/space-listing/steps/helper";

const ViewSpaces = () => {
  const isMobile = useCheckIsMobileScreen();
  const navigate = useNavigate();
  const { getAverageRating, getLowestPrice } = useHostWorkspaces();

  const { workspaces, loading: isLoading, error } = useMyWorkspacesQuery({});

  const links = [
    {
      title: <Link to={"/"}>Home</Link>,
    },
    {
      title: "My Listings",
      link: "",
    },
  ];

  const [searchValue, setSearchValue] = useState<string>("");

  const onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  const filteredWorkspaces = useMemo(() => {
    return workspaces.filter((space) =>
      space.title.toLowerCase().includes(searchValue.toLowerCase())
    );
  }, [searchValue, workspaces]);

  const renderStars = (rating: number) => {
    const stars = Array.from({ length: 1 }, (_, index) => {
      return index < rating ? (
        <StarFilled key={index} style={{ color: "#faad14" }} />
      ) : (
        <StarFilled key={index} style={{ color: "#d9d9d9" }} />
      );
    });

    return (
      <div className={styles.icon}>
        {stars}
        <p className={styles.subtitle}>
          <span>{rating > 0 ? `Average Rating: ${rating}` : "No rating"}</span>
        </p>
      </div>
    );
  };
  return (
    <div className={styles.page}>
      <div className={styles.header}>
        <Breadcrumb
          separator=">"
          items={links}
          className={styles.breadcrumbs}
        />
      </div>
      <div className={styles.listingTop}>
        <img src={img1} alt="advert1" />
        <img src={img2} alt="advert2" />
        <img src={img3} alt="advert3" />
      </div>
      <div className={styles.listingMobile}>
        <img src={Revenue} alt="advert" />
      </div>
      <div className={styles.searchFilter}>
        <Search
          onChange={onSearch}
          placeholder={"Search Listings"}
          className={styles.searchInput}
        />
      </div>
      <div className={styles.heading}>
        <h2 className={styles.title}>My Listings</h2>
        <div className={styles.listButton}>
          <Button
            type="nav"
            onClick={() => {
              navigate("/listing");
            }}
          >
            List Your Space +
          </Button>
        </div>
      </div>
      <div>
        {filteredWorkspaces?.length > 0 ? (
          filteredWorkspaces?.map((space: Workspace) => {
            console.log(
              "space",
              minimumPrice(transformPricingArray(space?.pricing))
            );

            return (
              <Fragment key={space.workspace_id}>
                <div className={styles.featured}>
                  <div className={styles.featuredItem}>
                    <div className={styles.left}>
                      <Splider
                        thumbnails={
                          space?.photos?.length > 0
                            ? space.photos
                            : [placeholder]
                        }
                        space={space.title}
                        className={styles.img}
                      />
                    </div>
                    <div className={styles.workspace}>
                      {isMobile ? (
                        <div className={cn("sm:hidden", styles.rightTop)}>
                          <div className={styles.content}>
                            <div className={styles.con}>
                              <h1 className={styles.title}>{space.title}</h1>
                              <h1
                                className={cn(styles.bestPrice, "text-primary")}
                              >
                                &#8358;{" "}
                                {minimumPrice(
                                  transformPricingArray(space?.pricing)
                                )}
                              </h1>
                              <div className={styles.icon}>
                                {space.verified_reviews?.data?.length > 0 ? (
                                  renderStars(
                                    calculateAverage(
                                      space.verified_reviews?.data?.map(
                                        (item: any) => item.review_rating
                                      )
                                    )
                                  )
                                ) : (
                                  <>{renderStars(0)}</>
                                )}
                              </div>
                            </div>
                            <div className={styles.con}>
                              {space?.verified_space ? (
                                <div className={styles.icon}>
                                  <img src={verified} alt="verified" />
                                  <p className={styles.subtitle}>Verified</p>
                                </div>
                              ) : (
                                <div className={styles.icon}>
                                  <img src={checkBadge} alt="unverified" />
                                  <p className={styles.subtitle}>
                                    <span>Not Verified</span>
                                  </p>
                                </div>
                              )}
                              {/* <p className={styles.subtitle}>
                                  {space?.verified_reviews?.data?.length} Verified
                                  Reviews
                                </p> */}
                            </div>
                            <div className={styles.con}>
                              <div className={styles.location}>
                                <div className={styles.icon}>
                                  <img src={pin} alt="pin" />
                                  <p className={styles.subtitle}>
                                    <span>
                                      {space.address?.city +
                                        ", " +
                                        space.address?.country}
                                    </span>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="hidden sm:block">
                          <div className={styles.rightTop}>
                            <div className={styles.content}>
                              <h1 className="text-xl font-semibold text-coSpireBlack">
                                {space?.title}
                              </h1>
                              <h1 className={styles.bestPrice}>
                                ₦{" "}
                                {minimumPrice(
                                  transformPricingArray(space?.pricing)
                                )}
                              </h1>
                              <div className={styles.icon}>
                                {space.verified_reviews?.data?.length > 0 ? (
                                  renderStars(
                                    calculateAverage(
                                      space.verified_reviews?.data?.map(
                                        (item: any) => item.review_rating
                                      )
                                    )
                                  )
                                ) : (
                                  <>{renderStars(0)}</>
                                )}
                              </div>
                              {space?.verified_space ? (
                                <div className={styles.icon}>
                                  <img src={verified} alt="verified" />
                                  <p className={styles.subtitle}>Verified</p>
                                </div>
                              ) : (
                                <div className={styles.icon}>
                                  <img src={checkBadge} alt="unverified" />
                                  <p className={styles.subtitle}>
                                    <span>Not Verified</span>
                                  </p>
                                </div>
                              )}
                              {/* <p className={styles.subtitle}>
                                  {space?.verified_reviews?.data?.length} Verified
                                  Reviews
                                </p> */}
                              <div className={styles.icon}>
                                <img src={pin} alt="pin" />
                                <p className={styles.subtitle}>
                                  <span>
                                    {space.address?.city +
                                      ", " +
                                      space.address?.country}
                                  </span>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className={styles.ctaCorner}>
                    <div className={styles.ctaBoxes}>
                      <div className={cn(styles.cta, "flex items-center")}>
                        {space.approved_space ? (
                          <button className={styles.btnBlue}>
                            View as a renter
                          </button>
                        ) : (
                          <Link
                            to={"/my-listings/preview/" + space.workspace_id}
                            className="text-lg font-medium text-primary flex-1"
                          >
                            Continue editing
                          </Link>
                        )}

                        <CustomButton
                          children={"Preview"}
                          className={styles.btnWhite}
                          onClick={() =>
                            navigate(
                              "/my-listings/preview/" + space.workspace_id
                            )
                          }
                        />
                      </div>
                      <div className={styles.recyclyeBin}>
                        <img src={trash} alt="bin" />
                        <p>Remove</p>
                      </div>
                    </div>
                  </div>
                </div>
                <Divider orientation="left"></Divider>
              </Fragment>
            );
          })
        ) : (
          <NoDataPage
            title={"No workspaces available"}
            subtitle="All your workspaces will be displayed here"
          />
        )}
      </div>
    </div>
  );
};

export default ViewSpaces;
