import React, { FC, ReactNode, Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import { Spin } from "antd";

import TopBarProgress from "react-topbar-progress-indicator";

import HostForm from "../pages/Host/HostForm";
import {
  ABOUT_US,
  ADD_SPACE_DETAILS,
  ADD_SPACE,
  ADD_YOUR_SPACE,
  ANY_ROUTE,
  BOOK_FOR_TEAMS,
  FAQ,
  HOST_PROFILE,
  VERIFY_EMAIL,
  CUSTOMER_PROFILE,
  LOGIN,
  RESET_PASSWORD,
  PREVIEW_HOST_WORKSPACE,
  HOST_HOME,
  BOOKING_DETAILS,
  PRIVACY_POLICY,
  TERMS_AND_CONDITIONS,
  LISTING,
  SPACE_DESCRIPTION,
  PREVIEW_DETAILS,
  REGISTER,
  MY_LISTING,
  SINGLE_WORKSPACE,
  WORKSPACES,
  EARNINGS_PAYMENT,
  SINGLE_BOOKING,
  SUBSCRIPTIONS,
  SUBSCRIPTIONS_PLAN,
  ADMIN_LISTINGS,
  NEW_LISTING,
  MANAGE_SUBSCRIPTION,
  ACCOUNT_SETTINGS,
  // SUBSCRIPTIONS,
} from "./routes";

import { ProtectedRoute } from "../utils/ProtectedRoutes";
import Login from "../components/a/Forms/LoginForm";
import PreviewWorkspace from "../pages/Host/Profile/components/PreviewWorkspace/previewWorkspace";
import Profile from "../pages/Dashboard/dashboard";
import PricingFAQ from "../pages/BookForTeams/pricingFAQ";
import HomeHost from "@/pages/Host/Profile/components/HomeHost";
import BookingDetails from "@/pages/Dashboard/Bookings/BookingDetails/BookingDetails";
import Bookings from "@/pages/Dashboard/Bookings";
import AccountSettings from "@/pages/Dashboard/Account";
import HostWorkSpaces from "../pages/Host/Profile/components/MyWorkspaces";
import VerifyHostWorkspace from "../pages/Host/Profile/components/VerifyWorkspace";
import TransactionsPayments from "../pages/Dashboard/Transactions";
import HomeCustomer from "@/pages/Dashboard/HomeCustomer/HomeCustomer";
import HostBookingDetails from "@/pages/Dashboard/Bookings/HostBookingDetails";
import ProfileSettings from "@/pages/Dashboard/Account/ProfileSettings";
import Verificaion from "@/pages/Dashboard/Account/Verification";
import Verified from "@/pages/Dashboard/Account/Verified";
import Listing from "@/pages/Listing";
import ViewSpaces from "@/pages/SpaceOwner/ViewSpaces";
import SpaceDescription from "@/pages/NewListingPage/SpaceDescription";
import SubscriptionsPage from "@/pages/SubscriptionsPage";
import AddWorkSpace from "@/pages/Host/AddWorkSpace";
import BookingPage from "@/pages/BookingPage";
import PreviewDetails from "@/pages/Host/PreviewDetails";
import RentersMessages from "@/pages/Messages/RentersMessages/Messages";
import HostMessages from "@/pages/Messages/HostMessages";
import Messages from "@/pages/Messages";
import WalletPage from "@/pages/SpaceOwner/WalletPage";
import Layout from "@/layout/layout";
import WorkspacePage from "@/pages/Workspace";
import RentersBookingHistory from "@/pages/RentersBookingHistory";
import { useAuth } from "@/components/auth/use-auth";
import SubscriptionDetails from "@/pages/SubscriptionDetails";
import { useMe } from "@/data/user";
import SuperAdmin from "@/pages/SuperAdmin";
import SpaceRules from "@/pages/SpaceRules/SpaceRules";
import RentalPSaceOwner from "@/pages/RentalSpaceOwner/RentalPSaceOwner";
import NewListingPage from "@/pages/NewListingPage";
import PreviewSpaceDetails from "@/pages/NewListingPage/PreviewDetailsPage";
import Amenities from "@/pages/SpaceRules/Amenities";
import UpdateListingPage from "@/pages/NewListingPage/UpdateListing";
import ManageSubscriptionPage from "@/pages/ManageSubscription";
import AccountSettingsPage from "@/pages/AccountSettings";

export default function HostRoutes() {
  //   const SettingsRoutes = lazy(() => import("./components/layouts/_settings"));

  return (
    <Routes>
      <Route path={HOST_HOME} element={<HomeHost />} />
      <Route path={`${ADD_SPACE_DETAILS}/:tab`} element={<HostForm />} />
      <Route
        path={`${PREVIEW_HOST_WORKSPACE}/:id`}
        element={<PreviewWorkspace />}
      />
      <Route path={`${HOST_PROFILE}/:tab`} element={<Profile />} />
      <Route path={`${HOST_PROFILE}`} element={<Profile />}>
        <Route index element={<HomeHost />} />
        <Route path={`host_dashboard`} element={<HomeHost />} />
        <Route path={`workspaces`} element={<HostWorkSpaces />} />
        <Route path={`verify_workspace`} element={<VerifyHostWorkspace />} />
        <Route path={`bookings`} element={<Bookings />} />
        <Route
          path={`bookings/hostBooking_details/:id`}
          element={<HostBookingDetails />}
        />
        <Route
          path={`bookings/booking_details/:id`}
          element={<BookingDetails />}
        />
        <Route
          path={`transactions_payments`}
          element={<TransactionsPayments />}
        />
        <Route path={`wallet`} element={<WalletPage />} />
        <Route path={`account_settings`} element={<AccountSettings />} />
        <Route
          path={`account_settings/profile_settings`}
          element={<ProfileSettings />}
        />
        <Route
          path={`account_settings/verification`}
          element={<Verificaion />}
        />
        <Route
          path={`account_settings/verification/verified`}
          element={<Verified />}
        />
      </Route>
      <Route path={PREVIEW_DETAILS} element={<PreviewDetails />} />
      <Route
        path={"/my-listings/preview/:id"}
        element={<PreviewSpaceDetails />}
      />
      <Route path={"/space-rules/:id"} element={<SpaceRules />} />
      <Route path={"/space-amenities/:id"} element={<Amenities />} />
      <Route
        path={"/my-listing/preview/space/:id"}
        element={<WorkspacePage />}
      />
      <Route path={EARNINGS_PAYMENT} element={<WalletPage />} />
      <Route path={SPACE_DESCRIPTION} element={<SpaceDescription />} />
      <Route path={SUBSCRIPTIONS} element={<SubscriptionsPage />} />
      <Route path={SUBSCRIPTIONS_PLAN} element={<SubscriptionDetails />} />
      <Route path={NEW_LISTING} element={<NewListingPage />} />
      <Route path={MANAGE_SUBSCRIPTION} element={<ManageSubscriptionPage />} />
      <Route path={ACCOUNT_SETTINGS} element={<AccountSettingsPage />} />

      {/* Lazy Modules */}
      <Route
        path={`/${ACCOUNT_SETTINGS}/*`}
        element={
          <SuspensedView>
            <Routes>
              <Route path={"/"} element={<AccountSettingsPage />} />
              <Route
                path={"/manage-subscription"}
                element={<ManageSubscriptionPage />}
              />
            </Routes>
          </SuspensedView>
        }
      />
    </Routes>
  );
}

const SuspensedView: FC<{ children?: ReactNode }> = ({ children }) => {
  // TODO: Grab primary color from tailwindConfig
  // const baseColor = "getCSSVariableValue("--kt-primary")";
  const baseColor = "#0047AB";
  TopBarProgress.config({
    barColors: {
      "0": baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  });
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>;
};
