import React, { useState } from "react";
import MultiDate, { Value } from "react-multi-date-picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import dayjs, { Dayjs } from "dayjs";
import { DateObject } from "react-multi-date-picker";
import styles from "./index.module.scss";

interface IMultiDatePicker {
  name?: string;
  label?: string;
  error?: string | undefined | number | boolean;
  onChange?: any;
  disabled?: any;
  value?: any;
  className?: string;
  footer?: React.ReactNode;
}

const initialDates: Dayjs[] = [
  dayjs(), // today
  dayjs().add(1, "day"),
  dayjs().add(2, "day"),
];

function CustomerDatePicker({
  onChange,
  label,
  error,
  disabled,
  value,
  className,
  footer,
}: IMultiDatePicker) {
  const [selectedDate, setSelectedDate] = useState<Dayjs[]>(initialDates);

  //@ts-ignore
  const convertedDate: Value = selectedDate.map((date) =>
    date.format("YYYY-MM-DD"),
  );

  const handleDateChange = (date: DateObject | DateObject[] | null) => {
    let dayjsDates: Dayjs[] = [];
    if (Array.isArray(date)) {
      dayjsDates = date.map((d) => dayjs(d.toString()));
    } else if (date) {
      dayjsDates = [dayjs(date.toString())];
    }
    setSelectedDate(dayjsDates);
    if (onChange) {
      onChange(dayjsDates);
    }
  };

  return (
    <div className={styles.container}>
      {label && <label className={styles.label}>{label}</label>}
      <MultiDate
        value={value}
        // @ts-ignore
        multiple
        sort
        onChange={onChange}
        placeholder="Pick recurrent dates"
        format="YYYY-MM-DD"
        plugins={[<DatePanel />]}
        disabled={disabled}
        className={className}
        style={{
          height: "auto",
          padding: "16px",
          display: "flex",
          width: "100%",
        }}
      />
      {error && <p className={styles.error}>{error}</p>}
    </div>
  );
}

export default CustomerDatePicker;
