import { BackIcon } from "./back-icon";
import { BankNotesIcon } from "./bank-note-icon";
import { CircumscribedCloseIcon } from "./circumscribed-close-icon";
import { FlashIcon } from "./flash-icon";
import { Star } from "./star";
import { TrashIcon } from "./trash-icon";
import { VerifiedIcon } from "./verified-icon";

export type iconTypes =
  | "backIcon"
  | "star"
  | "verifiedIcon"
  | "circumscribedCloseIcon"
  | "bankNotesIcon"
  | "flashIcon"
  | "deleteIcon";

interface IconInterface {
  name: iconTypes;
  svgProps?: React.SVGProps<SVGSVGElement>;
}

const CustomIcons = ({ name, svgProps }: IconInterface) => {
  const icons: Record<iconTypes, JSX.Element> = {
    backIcon: <BackIcon {...svgProps} />,
    star: <Star {...svgProps} />,
    verifiedIcon: <VerifiedIcon {...svgProps} />,
    circumscribedCloseIcon: <CircumscribedCloseIcon {...svgProps} />,
    bankNotesIcon: <BankNotesIcon {...svgProps} />,
    flashIcon: <FlashIcon {...svgProps} />,
    deleteIcon: <TrashIcon {...svgProps} />,
  };

  return icons[name];
};

export default CustomIcons;
