import React, { useEffect, useState } from "react";
import styles from "./index.module.scss";
import { useNavigate, useLocation } from "react-router-dom";
import { Divider } from "antd";
import Logo from "@/assets/svgs/logo-cospire-svg.svg";
import Google from "@/assets/svgs/google.svg";
import Frame from "@/assets/svgs/Frame.svg";
import Close from "@/assets/svgs/Close.svg";
import { Formik, FormikHelpers, FormikProps } from "formik";
import { LOGIN_FORM_SCHHEMA } from "../../../../validationSchemas/schema";
import { CustomButton } from "../../../customButton";
import { CustomInputField } from "../../../customInput";
import CustomPasswordBox from "../../../customPasswordBox";
import { login } from "../../../../services/networkRequests/authService";
import { HOME, RESET_PASSWORD, VERIFY_EMAIL } from "../../../../routes/routes";
import { reset } from "@/redux/rootReducer";
import { handleGoogleSignIn } from "../../../../firebase/functions";
import { useModalAction } from "@/components/modal-view/context";
import { LoginPayload } from "@/types/auth";
import { useMe } from "@/data/user";
import { useAuth } from "@/components/auth/use-auth";

const initialValues: LoginPayload = {
  email: "",
  password: "",
};

const LoginForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const { me: loggedInUser } = useMe();
  const { currentUser: user } = useAuth();
  const [mobileMenu, showMobileMenu] = React.useState<boolean>(false);

  const { closeModal, openModal } = useModalAction();

  const handleLogin = async (payload: LoginPayload) => {
    const formValues = { ...payload, returnSecureToken: true };
    setLoading(true);
    try {
      const response = await login(formValues);
      if (!response?.emailVerified) {
        navigate(VERIFY_EMAIL);
        closeModal();
      } else {
        closeModal();
        navigate(HOME);
      }
    } catch (error: any) {
      setLoading(false);
    } finally {
      setLoading(false);
      reset();
    }
  };

  useEffect(() => {
    if (loggedInUser) {
      setLoading(false);
      if (loggedInUser?.force_password) {
        navigate(RESET_PASSWORD);
        closeModal();
      }
      if (user?.emailVerified && !loggedInUser?.force_password) {
        const { from } = location.state || { from: HOME };
        navigate(from, { replace: true });
        closeModal();
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedInUser]);

  const showMenu = () => {
    showMobileMenu(!mobileMenu);
  };

  return (
    <div className={styles.main}>
      <div className={styles.close} onClick={closeModal}>
        <img src={Close} alt="close" />
      </div>

      <div className={styles.box}>
        <img src={Logo} alt="logo" />
        <h1>Welcome back!</h1>

        <Formik
          initialValues={initialValues}
          validationSchema={LOGIN_FORM_SCHHEMA}
          onSubmit={(
            values: LoginPayload,
            { setSubmitting }: FormikHelpers<LoginPayload>
          ) => {
            handleLogin(values);
            setTimeout(() => setSubmitting(false), 400);
          }}
        >
          {(formik: FormikProps<LoginPayload>) => {
            return (
              <form className={styles.form}>
                <div className={styles.inputContainer}>
                  <CustomInputField
                    type={"email"}
                    label={"Email Address"}
                    placeholder="Enter your Email Address"
                    name={"email"}
                    onChange={formik.handleChange}
                    error={formik.touched.email && formik.errors.email}
                  />

                  <CustomPasswordBox
                    type={"password"}
                    name={"password"}
                    label="Password"
                    placeholder="Enter your password"
                    onChange={formik.handleChange}
                    error={formik.touched.password && formik.errors.password}
                  />
                </div>
                {/* <>
                  {modalType === "register" && (
                    <Register
                      isOpen={isOpen}
                      closeModal={closeModal}
                      openModal={openModal}
                    />
                  )}

                  {modalType === "forget-password" && (
                    <ForgetPassword
                      isOpen={isOpen}
                      closeModal={closeModal}
                      openModal={openModal}
                    />
                  )}
                </> */}
                <div className={styles.forgetPass}>
                  <button
                    type="button"
                    title="Forgot password"
                    onClick={() => openModal("FORGOT_PASSWORD_VIEW")}
                  >
                    Forgot Password?
                  </button>
                </div>
                <div className={styles.button}>
                  <CustomButton
                    children={"Login"}
                    onClick={formik.handleSubmit}
                    htmlType="submit"
                    loading={formik.isSubmitting || loading}
                    extraStyles={{ width: "100%" }}
                  />
                </div>
                <Divider>or</Divider>
                <CustomButton
                  disabled={true}
                  children={"Login with Google"}
                  // onClick={ handleGoogleSignIn }
                  className={styles.google}
                  icon={<img src={Google} alt="google" />}
                />

                <div className={styles.register}>
                  Not registered yet?{" "}
                  <button
                    title="Join us"
                    type="button"
                    onClick={() => openModal("REGISTER")}
                  >
                    Join Us
                  </button>{" "}
                </div>
              </form>
            );
          }}
        </Formik>
      </div>
      <div className={styles.frame}>
        <img src={Frame} alt={"frame"} />
      </div>
    </div>
  );
};

export default LoginForm;
