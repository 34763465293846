import { UpdateSpaceForm } from "@/components/space-listing/update-space-form";
import { useMyWorkspaceQuery } from "@/data/workspace";
import { Workspace } from "@/types/workspace";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

export default function UpdateListingPage() {
  const params = useParams<{ id: string }>();

  const {
    workspace,
    isLoading: loading,
    error,
  } = useMyWorkspaceQuery(params.id!, {
    enabled: !!params.id,
  });

  if (error) {
    return <div>An error occurred</div>;
  }

  return (
    <div>
      <UpdateSpaceForm workspace={workspace!} />
    </div>
  );
}
