export const API_ENDPOINTS = {
  BASE: import.meta.env.VITE_BASE_URL || "",
  WORKSPACE: {
    ROOT: "workspaces",
    GET: "workspace?workspace_id=",
    ALL: "workspace/listAllWorkspace",
    ME: "workspace/me",
    MY: "workspace/unapproved/",
    MYLOCATIONS: "workspaces/my-listings-locations",
    ALLLOCATIONS: "workspaces/space-locations",
  },
  ACTIVITIES: {
    GET: "activities?activity_id=",
    BASE: "space-activities",
  },
  LOCATIONS: {
    GET: "locations?location_id=",
    BASE: "workspaces/space-locations",
  },
  CATEGORIES: "space-categories",
  ADD_WITHDRAWAL_BANK: "wallet/bank-account",
  USERS_ME: "users/me",
  RECOMMENDED: {
    GET: "workspace/recommend",
  },
  TRANSACTIONS: "transactions/me",
  WALLET: {
    ME: "wallet/me",
    BANK_ACCOUNT: "wallet/bank-account",
    PREFERRED_ACCOUNT: "wallet/preferred-account",
    WITHDRAW_FUNDS: "wallet/withdraw-funds",
    PAYMENT_METHOD: "wallet/payment-method",
  },
  BOOKINGS: {
    BASE: "bookings",
    INITIATE: "bookings/initiate",
    COMPLETE_BOOKING: "bookings/complete-booking",
    ME: "bookings/me",
  },
  SUBSCRIPTIONS: {
    BASE: "subscriptions",
    ME: "subscriptions/me",
    PAYMENT_METHODS: "subscriptions/payment-methods",
  },
  APPROVAL_REQUESTS: {
    BASE: "space-status",
  },
};
