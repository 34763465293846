import React from "react";

export const Star: React.FC<React.SVGAttributes<{}>> = (props) => {
  return (
    <svg
      width="22"
      height="21"
      viewBox="0 0 22 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.7692 16.9741L17.4246 20.9004L15.6585 13.5004L21.5385 8.52144L13.7954 7.86881L10.7692 0.900391L7.74308 7.86881L0 8.52144L5.86923 13.5004L4.11385 20.9004L10.7692 16.9741Z"
        fill="#FE9B0E"
      />
    </svg>
  );
};
