import axios from "axios";
import { getToken } from "../utils/token";
import { auth } from "@/firebase/utils";

export const axiosPrivate = axios.create({
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    Accept: "application/json",
  },
});

// Add request interceptor
axiosPrivate.interceptors.request.use(
  async (config) => {
    const token = getToken();
    if (token) {
      config!.headers!.Authorization = `Bearer ${JSON.parse(token)}`;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

// Change response data/error here
axiosPrivate.interceptors.response.use(
  (response) => response,
  (error) => {
    if (
      (error.response && error.response.status === 401) ||
      (error.response && error.response.status === 403) ||
      (error.response && error.response.data.message === "Unauthorized")
    ) {
      if (auth.currentUser) {
        auth.currentUser.getIdToken(true);
      } else {
        auth.signOut();
      }
    }
    return Promise.reject(error);
  }
);
