import { BookingPaginator, BookingQueryOptions } from "@/types/booking";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { API_ENDPOINTS } from "./client/api-endpoints";
import client from "./client";
import { mapPaginatorData } from "@/utils/data-mappers";
import {
  ApprovalRequestPaginator,
  ApprovalRequestQueryOptions,
} from "@/types/approval_request";

export const useApprovalRequestsQuery = (
  params: Partial<ApprovalRequestQueryOptions>,
  options = {}
) => {
  const { data, error, isLoading } = useQuery<ApprovalRequestPaginator, Error>(
    [API_ENDPOINTS.APPROVAL_REQUESTS.BASE, params],
    ({ queryKey, pageParam }) =>
      client.approval_requests.paginated(
        Object.assign({}, queryKey[1], pageParam)
      ),
    {
      keepPreviousData: true,
      ...options,
    }
  );

  return {
    requests: data?.data ?? [],
    paginatorInfo: mapPaginatorData(data),
    error,
    loading: isLoading,
  };
};

export const useApproveRejectSpaceMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(client.approval_requests.approve_reject, {
    // Always refetch after error or success:
    onSettled: () => {
      queryClient.invalidateQueries(API_ENDPOINTS.APPROVAL_REQUESTS.BASE);
    },
  });
};

export const useRequestSpaceApprovalMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(client.approval_requests.create, {
    // Always refetch after error or success:
    onSettled: () => {
      queryClient.invalidateQueries(API_ENDPOINTS.WORKSPACE.MY);
    },
  });
};
