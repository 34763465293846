import React from "react";

export const VerifiedIcon: React.FC<React.SVGAttributes<{}>> = (props) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.16907 2.06602C6.85588 1.27617 7.86943 0.775391 9 0.775391C10.1305 0.775391 11.144 1.27612 11.8308 2.0659C12.8751 1.99294 13.946 2.35552 14.7455 3.15503C15.545 3.95454 15.9076 5.02546 15.8347 6.06972C16.6243 6.75652 17.125 7.76996 17.125 8.90039C17.125 10.031 16.6242 11.0446 15.8342 11.7315C15.907 12.7755 15.5444 13.8462 14.7451 14.6456C13.9457 15.445 12.875 15.8076 11.8309 15.7348C11.1441 16.5246 10.1306 17.0254 9 17.0254C7.8695 17.0254 6.856 16.5247 6.1692 15.7349C5.12493 15.8079 4.054 15.4453 3.25448 14.6458C2.45496 13.8463 2.09238 12.7753 2.16534 11.7311C1.37565 11.0443 0.875 10.0308 0.875 8.90039C0.875 7.7699 1.37571 6.75641 2.16546 6.0696C2.09258 5.02543 2.45517 3.95462 3.25462 3.15517C4.05406 2.35572 5.12489 1.99314 6.16907 2.06602ZM12.0086 7.38866C12.2092 7.10778 12.1442 6.71744 11.8633 6.51681C11.5824 6.31618 11.192 6.38123 10.9914 6.66212L8.29525 10.4368L6.94194 9.08345C6.69786 8.83937 6.30214 8.83937 6.05806 9.08345C5.81398 9.32753 5.81398 9.72326 6.05806 9.96733L7.93306 11.8423C8.06297 11.9722 8.24346 12.0384 8.42655 12.0233C8.60964 12.0081 8.7768 11.9132 8.88358 11.7637L12.0086 7.38866Z"
        fill="#FFC000"
      />
    </svg>
  );
};
