import React, { Fragment, useState, useEffect } from "react";
import styles from "./index.module.scss";
import { CustomButton } from "@/components/customButton";
import { removeIcon } from "@/assets/img";
import useCheckIsMobileScreen from "@/hooks/useCheckIsMobileScreen";
import arrowBack from "../../../../../assets/svgs/arrow-small-left.svg";
import more from "@/assets/svgs/moreNew.svg";
import call from "@/assets/img/call.webp";
import mediaBlue from "@/assets/svgs/mediaBlue.svg";
import arrowRight from "@/assets/img/arrowRightNew.webp";
import add from "@/assets/svgs/add.svg";
import arrowLeft from "@/assets/svgs/arrowLeft.svg";
import microphone from "@/assets/svgs/microphone-2.svg";
import muteSwitch from "@/assets/svgs/muteSwitch.svg";
import muteBlue from "@/assets/svgs/muteBlue.svg";
import { Avatar, Divider, Drawer, Input, Modal, Tag, message } from "antd";
import workspaceImage from "@/assets/img/Space3.webp";
import { Table, Pagination } from "antd";
import CustomTag from "@/components/customTag";
import TableNav from "../../components/tableComponent/tableHeader/tableNavMessages";
import { Spacer } from "../../components/spacer";
import useBooking from "./useMessages";
import CustomTable from "../../components/tableComponent/Table/TableMessages";
import { useNavigate } from "react-router-dom";
import { getAllBookings } from "@/services/networkRequests/bookingService";
import CustomDropDown from "@/components/a/Modals/customDropDown/CustomDropDown";
import { Link } from "react-router-dom";
import Preview from "@/components/Preview/PreviewMessages";
import MessagePreview from "../../assets/svgs/MessagePreview.svg";
import { default as dayjs } from "dayjs";
// import BookingCalendar from "@/components/BookingCalendar";

import BookingCalendar from "../../components/BookingCalendar/index";
import { HOST_BOOKING } from "@/routes/routes";
import { BOOKING_DETAILS } from "@/routes/routes";
import { Search } from "@/components/Search/Search";
import { CustomInputField } from "@/components/customInput";
import {
  SendOutlined,
  FileTextOutlined,
  SoundOutlined,
  StopOutlined,
} from "@ant-design/icons";
import { Dialog } from "@/components/b/Dialog";
import CustomModal from "@/components/a/Modals/CustomModal/CustomModal";
import { useMe } from "@/data/user";

const Messages: React.FC = () => {
  const navigate = useNavigate();
  const isMobile = useCheckIsMobileScreen();
  const {
    tabLists,
    currentTab,
    onTabClick,
    messageStatus,
    onSelectPaymentStatus,
    onSearch,
    getColor,
    data,
    isLoading,
    messages,
    getAllMessageForHost,
    customerMessagesData,
    messageTest,
    hostWorkSpaces,
  } = useBooking();

  const hostBookings =
    getAllMessageForHost(hostWorkSpaces, messages) || customerMessagesData;
  const { me: loggedInUser } = useMe();
  const [viewMore, setViewMore] = useState(true);
  const [showTable, setShowTable] = useState(false);
  const hostBookingId = hostBookings.map((item: any) => item.id);
  const detailsLink = `/host-profile/messages/hostBooking_details`;
  const customerbookingData = customerMessagesData;
  const [open, setOpen] = React.useState<boolean>(false);
  const [selectedRow, setSelectedRow] = useState<{
    name: string;
    avatar: string;
    chatMessages: any;
  } | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const closeContactModal = () => {
    setIsModalOpen(false);
  };

  const [visible, setVisible] = useState(false);
  const handleBookingSchedule = () => {
    setVisible(true);
  };

  const closeModal = () => {
    setVisible(false);
  };

  const items = [
    {
      key: 1,
      label: (
        <Link to={detailsLink} className={styles.dropDownText}>
          View Booking details
        </Link>
      ),
    },
    {
      key: 2,
      label: (
        <Link to="#" className={styles.dropDownText}>
          Cancle Booking
        </Link>
      ),
    },
    {
      key: 3,
      label: (
        <Link to="#" className={styles.dropDownText}>
          Delete Booking
        </Link>
      ),
    },
  ];

  const handleShowTable = () => {
    setViewMore(!viewMore);
    setShowTable(!showTable);
  };

  const handleViewMore = (id: string) => {
    // setViewMore(!viewMore);
    // setShowTable(!showTable);
    navigate(`booking_details/${id}`);
  };
  // 20223-08-20 13:20:10.633
  const resetSelectedRow = () => {
    setSelectedRow(null);
  };
  const handleRowClick = (workspaceInformation: {
    name: string;
    avatar: string;
    chatMessages: any;
  }) => {
    setSelectedRow(workspaceInformation);
  };

  const columns = [
    {
      // title: <span className={`${styles.header}`}>Customer Information</span>,
      dataIndex: "workspaceInformation",
      key: "workspaceInformation",
      render: (workspaceInformation: {
        name: string;
        message: string;
        avatar: string;
        createdAt: any;
        chatMessages: any;
      }) => (
        <div
          onClick={() => handleRowClick(workspaceInformation)}
          className={styles.messageRow}
        >
          <div className={styles.messagesBox}>
            <Avatar
              size={isMobile ? "large" : 51}
              src={workspaceInformation.avatar}
            />
            <div className={`${styles.workspaceInfo}`}>
              <h1 className={`${styles.name}`}>{workspaceInformation.name}</h1>
              <p>{workspaceInformation.message}</p>
              {/* <p>Booked on :{companyInfo.start_date}</p> */}
            </div>
          </div>
          <div className={styles.messageFigures}>
            <p>2:00 PM</p>
            <div className={styles.numberOfMessages}>
              <p>2</p>
            </div>
          </div>
        </div>
      ),
    },
  ];

  const pageSize = 5;

  const rowClassName = (record: any) => {
    if (record.status === "Cancelled") {
      return `${styles.disabledRow}`;
    }
    return ""; // No special class for other rows
  };

  return (
    <div className={styles.page}>
      <div
        className={selectedRow ? styles.splitViewMobileHeading : styles.heading}
      >
        <div>
          <h2 className={styles.title}>Messages</h2>
          {/* <p>Lorem Ipsum dolor</p> */}
        </div>
      </div>
      <Search
        onChange={onSearch}
        placeholder={"Search for messages"}
        className={
          selectedRow ? styles.splitViewMobileSearch : styles.searchInputMessage
        }
      />
      <div
        className={`${styles.chatRow} ${selectedRow ? styles.splitView : ""}`}
      >
        <div className={styles.chatBox}>
          <div className={styles.container}>
            <div className={styles.tableWrapper}>
              <TableNav
                tabLists={tabLists}
                onTabChange={onTabClick}
                currentTab={currentTab}
                options={messageStatus}
                onSearch={onSearch}
                selectPlaceHolder={"Payment Status"}
                searchPlaceHolder={"Search for messages"}
                onSelectPaymentStatus={onSelectPaymentStatus}
              />
              <Spacer height={32} />
              <div className={styles.tableCtrl}>
                <CustomDropDown dropDownProps={{ items }}></CustomDropDown>
                {!messageTest ? (
                  <Preview
                    image={MessagePreview}
                    bookingText="You have no messages yet"
                    labelText="Your inbox is empty. Send a message  to a renter to get started."
                  />
                ) : (
                  <CustomTable
                    columns={columns}
                    // @ts-ignore
                    data={
                      loggedInUser?.role_type === "Host"
                        ? hostBookings
                        : customerbookingData
                    }
                    rowClassName={rowClassName}
                    // onRow={(record) => ({
                    //   onClick: () => handleRowClick(record),
                    // })}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        {selectedRow && (
          <div className={styles.chatScreen}>
            <div className={styles.chatHeader}>
              <div style={{ cursor: "pointer" }}>
                <img
                  src={arrowLeft}
                  onClick={resetSelectedRow}
                  className={styles.backArrow}
                  alt="back"
                />
                <Avatar
                  onClick={showModal}
                  size={"large"}
                  src={selectedRow.avatar}
                />
                <h2 onClick={showModal}>{selectedRow.name}</h2>
              </div>
              <CustomModal
                openModal={isModalOpen}
                closeModal={closeContactModal}
                centered
                width={334}
                footer={false}
              >
                <div className={styles.contactInfo}>
                  <div className={styles.customerHero}>
                    <div className={styles.customer}>
                      <div>
                        <img src={selectedRow.avatar} alt="" />
                      </div>
                      <h2>{selectedRow.name}</h2>
                      <div className={styles.call}>
                        <img src={call} alt="" />
                        <p>09034544763</p>
                      </div>
                    </div>
                    <div className={styles.customerMore}>
                      <div className={styles.option}>
                        <div>
                          <img src={mediaBlue} alt="" />
                          <p>Media</p>
                        </div>
                        <img src={arrowRight} alt="media" />
                      </div>
                      <div className={styles.option}>
                        <div>
                          <img src={muteBlue} alt="" />
                          <p>Mute</p>
                        </div>
                        <img src={muteSwitch} alt="switch" />
                      </div>
                    </div>
                  </div>
                </div>
              </CustomModal>
              <CustomDropDown
                dropDownProps={{
                  items: [
                    {
                      key: 1,
                      label: <p>Clear Chat</p>,
                      icon: <FileTextOutlined style={{ marginRight: 8 }} />,
                    },
                    {
                      key: 2,
                      label: <p>Mute Chat</p>,
                      icon: <SoundOutlined style={{ marginRight: 8 }} />,
                    },
                    {
                      key: 3,
                      label: <p>Block</p>,
                      icon: <StopOutlined style={{ marginRight: 8 }} />,
                    },
                  ],
                }}
                placement="bottomRight"
              >
                <img src={more} alt="more" style={{ cursor: "pointer" }} />
              </CustomDropDown>
            </div>
            <Divider className={styles.divider} />
            <div className={styles.day}>
              <CustomTag
                color={{ color: "#232323", backgroundColor: "#F6F6F6" }}
                text="Today"
              />
            </div>
            {selectedRow.chatMessages.map(
              (
                message: { type: string; text: string; time: string },
                index: number,
              ) => (
                <div
                  className={`${styles.chatMessages} ${
                    message.type === "sender" ? styles.senderMessage : ""
                  }`}
                >
                  {message.type === "recipient" && (
                    <Avatar size={"default"} src={selectedRow.avatar} />
                  )}
                  <div key={index} className={styles[message.type]}>
                    <p>{message.text}</p>
                    <span>{message.time}</span>
                  </div>
                </div>
              ),
            )}
            <div className={styles.chatInput}>
              <img src={add} alt="add" />
              <Input
                type="text"
                suffix={<SendOutlined />}
                className={styles.inputMessage}
              />
              {/* <img src={microphone} alt="Mic" /> */}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Messages;
